import React from 'react';
import Divider from '../General/DIvider';
import { RECIPE_URLS, replaceWildcards } from '../../../util/routes/urls';
import { Link } from 'react-router-dom';
import { CLOUDFRONT_URL } from '../../../util/constants/global';
import { useLocation } from 'react-router';
import { pinkRecipeList } from './recipe';
const Rftc = ({active, setActive}) => {

    React.useEffect(() => {
        const $ = window.$;
        $('#pink-recepti').velocity("fadeOut", {duration: 200});
        $('#ed').velocity("fadeOut", {duration: 200});
        $('#stomacko-tim').velocity("fadeOut", {duration: 200});

        if (active === 1) {
            $('#pink-recepti').velocity("fadeIn", {duration: 250, delay: 200});
        }
        if (active === 2) {
            $('#ed').velocity("fadeIn", {duration: 250, delay: 200});
        }
        if (active === 3) {
            $('#stomacko-tim').velocity("fadeIn", {duration: 250, delay: 200});
        }

        // if (active === 1) {
        //     $('#pink-recepti').velocity({translateX: '1200px'}, {duration: 250, delay: 50});
        // }
        // if (active === 2) {
        //     $('#ed').velocity({translateX: '1200px'}, {duration: 250, delay: 50});
        // }
        // if (active === 3) {
        //     $('#stomacko-tim').velocity({translateX: '1200px'}, {duration: 250, delay: 50});
        // }
    }, [active]);

    return (
        <>
            <div
                className="justify-content-center think-pink"
                style={{ paddingTop: '15px' }}
            >
                <div className="col-md-11 col-lg-9 col-xl-7">
                    <div className="section-title text-center " style={{marginBottom: '0px'}}>
                        {/*<div id="basic-fridge-header">*/}

                        {/*    <h2 className="sec-title1 text-theme"*/}

                        {/*    >*/}
                        {/*        Stomacko*/}
                        {/*    /!*    <span className="" style={{*!/*/}
                        {/*    /!*    color: 'deeppink'*!/*/}
                        {/*    /!*}}>Pink Recepti</span>*!/*/}
                        {/*    </h2>*/}
                        {/*</div>*/}

                        <>
                            <div className="food-menu-wrapper food-menu-style1 list-style-none"
                                 style={{
                                     position: 'sticky',
                                     top: '0px',
                                     backgroundColor: "white",
                                     zIndex: '3',
                                     paddingBottom: '5px',
                                     paddingTop: '10px',
                                     // boxShadow: '0px 10px 6px -6px rgba(0,0,0,0.25)'
                                 }}
                            >
                                <ul className="nav nav-tabs d-block border-0" role="tablist">
                                    <li>
                                        <a onClick={() => setActive(1)} className="custom-toggle mask-style3 active" id="pink-recepti-tab" data-toggle="tab"
                                           href="#pink-recepti" role="tab" aria-controls="ingredients"
                                           aria-selected="false">
                                            {/*<i className="fa flaticon-serving-dish" style={{width: '40px'}} />*/}
                                            <span>
                                                Pink recepti
                                            </span>
                                        </a></li>
                                    <li>
                                        <a onClick={() => setActive(2)} className="custom-toggle mask-style3 " id="ed-tab" data-toggle="tab"
                                           href="#ed" role="tab" aria-controls="recipes" aria-selected="true">
                                            {/*<i className="fa fa-ribbon" style={{width: '40px'}} />*/}
                                            <span>
                                            ED Srbija & RFTC
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => setActive(3)} className="custom-toggle mask-style3" id="stomacko-tim-tab" data-toggle="tab"
                                           href="#stomacko-tim" role="tab" aria-controls="ingredients"
                                           aria-selected="false">
                                            {/*<i className="fa fa-flag-checkered" style={{width: '40px'}} />*/}
                                            <span>
                                            Stomačko tim
                                            </span>
                                        </a></li>

                                </ul>

                                <Divider style={{marginBottom: '5px'}} />

                                <div>
                                    Projekat proglašen jednim od <b>3 najznačajnija sprovedena 2023. godine</b> od strane{' '}
                                    <a href="https://www.europadonna.org/" target="_blank"  className="think-pink-text">Europa Donna European Breast Cancer Coalition</a>.
                                    <br />
                                    Projekat je prezentovan u oktobru 2023. na 16. Panevropskoj konferenciji u Zagrebu.
                                </div>

                                <div className="tab-content think-pink-content" id="pinkRecipeContent" style={{
                                    fontFamily: 'source-serif-pro',
                                }}>
                                    <div className="tab-pane active link-inherit" id="pink-recepti" aria-labelledby="pink-recepti-tab">
                                        <div className="row ">
                                            <div className="col-xl-12 col-md-12">
                                                <PinKRecepti />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane " id="ed" aria-labelledby="ed-tab">
                                        <div className="row ">
                                            <div className="col-xl-12 col-md-12">
                                                <ED />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane " id="stomacko-tim" aria-labelledby="stomacko-tim-tab">
                                        <div className="row ">
                                            <div className="col-xl-12 col-md-12">
                                                <StomackoTim />
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    );
};

const PinKRecepti = () => {
    const location = useLocation();
    const locationState = { prevPath: location.pathname };

    const isMobile = window.innerWidth < 991;

    return (
        <>
            <h2 style={{
                color: 'deeppink',
                margin: '20px 0',
            }}>
                Pink recepti
            </h2>
            <div className="row flex-column-reverse flex-md-row">
                <div
                    className="col-md-6"
                    style={{
                    textAlign: 'justify'
                }}>
                    <p>
                        Iako ste možda pri otvaranju ove stranice očekivali pregršt fotografija jela na kojima domira roze boja, sigurni smo da će vam biti još draže što ste se ovde našli kada čitajući dalje tekst budete saznali o čemu se tačno radi.
                    </p>
                    <p>
                    <span className="color-green">Stomačko</span> i <a className="think-pink-text" target="_blank" href="https://www.europadonna.org.rs/sr/">Evropa dona Srbija</a> - srpski forum protiv raka dojke,
                        udružili su se u osmišljavanju Pink recepata, a neposredni povod je organizovanje pete po redu trke i
                        šetnje za izlečenje žena obolelih od raka dojke - <a className="think-pink-text" target="_blank" href="https://www.raceforthecure.eu/sr/Races/Race/Id/13" >Race for the cure</a>.
                    </p>
                    <p>
                    Pred vama je 15 recepata za slana jela, ali i slatke poslastice i napitke, za koje vas molimo da shvatite na nivou preporuke za način pripreme i konzumiranje određenih namirnica kao prevenciju bolesti, ali i tokom i nakon lečenja.
                    </p>
                    <p>
                        Recepti su osmišljeni uz smernice date u <a className="think-pink-text" target="_blank" href="https://www.ncrc.ac.rs/preporuke-za-pacijente-u-toku-i-nakon-onkoloskog-lecenja/">brošuri</a> koju možete naći na sajtu Instituta za onkologiju i radiologiju Srbije, kao i u okviru njihove zvanične aplikacije <a className="think-pink-text" target="_blank" href="https://onko.rs/">ONKO</a>.
                    </p>
                    <p>
                    Potrudili smo se da preporučimo jela sa dobrim balansom nutritivnih vrednosti, a takođe u duhu <span className="color-green">Stomačka</span>, da jela ne budu zahtevna za pripremu, za stomak (i novčanik) i naravno, da spoj ukusa bude jedinstven.
                    </p>
                    <p>
                    Ipak, još jednom napominjemo da je svaki organizam priča za sebe, te je neophodno da se oko svoje ishrane usled opšteg zdravstvenog stanja uvek konsultujete sa svojim lekarom i/ili nutricionistom.
                    </p>
                    {/*<p>*/}
                    {/*Za ovogodišnju trku/šetnju - RFTC, koja će se održati u subotu, 16. septembra u Beogradu na prostoru Ade Ciganlije, pozivamo vas da se registrujete što pre, a možete se pridružiti i našem Stomačko timu ovde! Ukoliko bude bilo kakvih problema prilikom registracije, možete da se obratiti timu organizatora RFTC putem mejla rftcserbia@gmail.com, ili porukom na instagram profilu raceforthecure_serbia. Važno je da napomeno da Stomačko ni u kom slučaju ne dobija novac od ove akcije i da je naše učešće u potpunosti humanitarnog karaktera.*/}
                    {/*</p>*/}
                    {/*Rak <p>dojke je najčešće maligno oboljenje kod žena, a prema zvaničnim statistikama, Srbija se, na veliku žalost, ne može pohvaliti smanjenjem broja obolelih, ali i zauvek izgubljenih dragocenih života mnogih žena.*/}
                    {/*Svojom registracijom i učešćem na RFTC obezbeđujete Pink poklon pažnje ženama koje se trenutno leče od raka dojke, u više zdravstvenih centara u Srbiji. Važno je da znaju da nisu zaboravljene i da smo uz njih.*/}
                    {/*</p>*/}
                    {/*<p>*/}
                    {/*Za kraj, prosledite Pink recepte onima koje volite, šetajte, trčite, uživajte u lepoj i zdravoj hrani i ne zaboravite da se pregladate, jer prevencija ima veliku moć kada je zdravlje u pitanju.*/}
                    {/*</p>*/}
                    {/*Vaš Stomačko tim koji predvode*/}
                    {/*Sara & Denis*/}
                </div>

                    <div className="col-md-6">
                        <section className="vs-food-box-wrapper food-box-layout3 position-relative link-inherit py-20 py-lg-20 row">
                        {pinkRecipeList.map((recipe, index) => {
                            const link = replaceWildcards(RECIPE_URLS.RECIPE, [
                                recipe.slug,
                            ]);

                            return (
                                <div className="col-4 col-md-6 col-lg-3 mb-30 radius-10" key={recipe._id}>
                                    <div className="vs-food-box h-100 mb-0 radius-10">
                                        <div className="food-image image-scale-hover radius-10" style={{ aspectRatio: '3 / 2'}}>
                                            <Link
                                                to={{ pathname: link, state: locationState }}>
                                                <img
                                                    src={
                                                        CLOUDFRONT_URL + 'small/' + recipe._id + ' Small.jpeg'
                                                    }
                                                    alt='Food'
                                                    className="w-100"
                                                />
                                            </Link>
                                        </div>
                                        {/*<div className="food-content" style={{*/}
                                        {/*    padding: '8px !important'*/}
                                        {/*}}>*/}
                                        {/*    /!*<span className="food-price">{recipe.cost.toFixed(2)}</span>*!/*/}
                                        {/*    <h3 className="food-title h4 mb-0" style={{*/}
                                        {/*        fontSize: '21px',*/}
                                        {/*    }}>*/}
                                        {/*        <Link*/}
                                        {/*            to={link}>*/}
                                        {/*            {recipe.name}*/}
                                        {/*        </Link>*/}
                                        {/*    </h3>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            );
                        })}
                        </section>
                    </div>

            </div>
        </>
    )
}

const ED = () => {
    return (
        <>
            <h2 style={{
                color: 'deeppink',
                margin: '20px 0',
            }}>
                Evropa Dona & Race For The Cure
            </h2>

            <div className="row">
                <div
                    className="col-md-6 mb-2"
                >
                    <img
                        style={{
                            borderTopLeftRadius: '12px',
                            borderTopRightRadius: '12px',
                        }}
                        src={`${CLOUDFRONT_URL}assets/pink/pink.png`} alt="think pink logo" />
                    <div style={{
                        marginTop: '16px',
                        borderBottomLeftRadius: '12px',
                        borderBottomRightRadius: '12px',
                    }}>
                    <iframe
                        style={{
                            borderBottomLeftRadius: '12px',
                            borderBottomRightRadius: '12px',
                        }}
                        width="100%" height="380" src="https://www.youtube.com/embed/dgrqM29TkLE?si=InZEzR0jiCvi87P7"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                    </div>
                </div>

                <div
                    className="col-md-6"
                    style={{
                        textAlign: 'justify',
                }}>
                    <p>
                    Malo o organizaciji Evropa dona Srbija - srpskom forumu protiv raka dojke
                    </p>
                    <p>
                    <a className="think-pink-text" target="_blank" href="https://www.europadonna.org.rs/sr/">Evropa Dona Srbija</a> je osnovana decembra 2016. godine. Ciljevi osnivanja organizacije su da se poboljšaju uslovi edukacije žena o zdravlju dojki, značaju ranog otkrivanja i sprečavanja pojave raka dojke, zalaganju za poboljšanje kvaliteta lečenja i rehabilitacije obolelih kao i zagovaranje jednakih prava i mogućnosti lečenja za sve obolele u institucijama države i zdravstvenom sistemu.
                    </p>
                    <p>
                    Race for the cure - za tebe, za mene, za sve nas!
                    </p>
                    <p>
                    Evropa dona Srbija, 2019. godine  dobija licencu od međunarodne organizacije Think Pink Europe da u Srbiji organizuje trku i šetnju za zdravlje žena obolelih od raka dojke pod nazivom <a className="think-pink-text" target="_blank" href="https://www.raceforthecure.eu/sr/Races/Race/Id/13" >Race for the cure</a>.
                     </p>
                    <p>
                        Ove godine je peta po redu trka (i šetnja) u Beogradu, zakazana za <b>subotu, 16. septembra na prostoru Ade Ciganlije</b>. Pojedinačne i timske regitracije su moguće putem <a className="think-pink-text" target="_blank" href="https://www.raceforthecure.eu/sr/Races/Race/Id/13">linka</a>. Ukoliko bude bilo kakvih problema prilikom registracije, možete se obratiti timu organizatora RFTC putem mejla <a className="think-pink-text" target="_blank" href="mailto:sara@stomacko.com">rftcserbia@gmail.com</a>, ili porukom na instagram profilu <a  className="think-pink-text" target="_blank" href="https://www.instagram.com/raceforthecure_serbia/">raceforthecure_serbia</a>.
                    </p>
                    <p>
                    Trka i šetnja za izlečenje (Race for the cure)  je najveći evropski sportski događaj posvećen zdravlju žena. Ona pomaže organizacijama i bolnicama koje se bave karcinomom dojke da sakupe novčana sredstva i podignu svest javnosti o karcinomu dojke. U Evropi se svake godine dijagnostikuje 500 000 novih slučajeva i 125 000 smrtnih slučajeva karcinoma dojke, tako da su ovi fondovi od vitalnog značaja. Sada više nego ikada.
                    </p>
                </div>
                <div style={{
                    margin: '0 16px'
                }}>
                    <h3 style={{
                        textAlign: 'center',
                        color: 'deeppink',
                    }}>
                        Pink poklon pažnje
                    </h3>
                    <div className="row ">
                        <div
                            className="col-md-6 mb-3"
                            style={{
                                textAlign: 'justify',
                            }}>
                            <img
                                style={{
                                    borderRadius: '12px',
                                }}
                                src={`${CLOUDFRONT_URL}assets/pink/race-map.jpg`}
                                alt='mapa trke'
                            />
                        </div>

                        <div
                            className="col-md-6"
                            style={{
                                textAlign: 'justify',
                            }}>
                            <p>
                                Svojom registracijom i učešćem na RFTC obezbeđujete Pink poklon pažnje ženama koje se trenutno leče od raka dojke, u više zdravstvenih centara u Srbiji. Važno je da znaju da nisu zaboravljene i da smo uz njih.
                            </p>
                            <p>
                                Za ovogodišnju trku/šetnju - RFTC, koja će se održati u <b>subotu, 16. septembra u Beogradu na prostoru Ada Ciganlije</b>, pozivamo vas da se <a className="think-pink-text" target="_blank" href="https://www.raceforthecure.eu/sr/Races/Race/Id/13">registrujete</a> što pre, a možete se pridružiti i našem <a className="think-pink-text" target="_blank" href="https://www.raceforthecure.eu/sr/Teams/Team/Id/491">Stomačko timu</a>. Važno je da napomeno da Stomačko ni u kom slučaju ne dobija novac od ove akcije i da je naše učešće u potpunosti humanitarnog karaktera.
                            </p>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

const StomackoTim = () => {
    return (
        <>
            <h2 style={{
                color: 'deeppink',
                margin: '20px 0',
            }}>
                Stomačko tim - Ada 2023
            </h2>
            <div className="row flex-column-reverse flex-md-row">
                <div
                    className="col-md-6"
                    style={{
                    textAlign: 'justify',
                }}>
                    <p>
                        Pridruži se <span className="color-green">Stomačko</span> timu!
                    </p>
                    <p>
                        Mnogo volimo da pravimo i jedemo lepu hranu svakog dana, ali isto tako znamo koliko je zbog zdravlja važno uključiti i fizičku aktivnost u svoju rutinu, a sada za to imamo jedan vrlo poseban povod za koji smo formirali <a className="think-pink-text" target="_blank" href="https://www.raceforthecure.eu/sr/Teams/Team/Id/491">Stomačko tim</a> i pozivamo te da nam se pridružiš!
                    </p>
                    <p>
                        U <b>subotu, 16. septembra u Beogradu, na prostoru Ade Ciganlije</b>, održaće se peta po redu trka i šetnja za izlečenje žena obolelih od raka dojke, pod nazivom <a className="think-pink-text" target="_blank" href="https://www.raceforthecure.eu/sr">Race for the cure</a>, u organizaciji <a className="think-pink-text" target="_blank" href="https://www.europadonna.org.rs/sr/">Evropa dona Srbije</a>.
                    </p>
                    <p>
                        U pitanju je najveći međunarodni sportski događaj u Evropi posvećen zdravlju žena koji pomaže organizacijama i zdravstvenim ustanovama koje se bave karcinomom dojke da sakupe novčana sredstva i podignu svest javnosti o ovoj bolesti.
                    </p>
                    <p>
                        Rak dojke je najčešće maligno oboljenje kod žena, a prema zvaničnim statistikama, Srbija se, na veliku žalost, ne može pohvaliti smanjenjem broja obolelih, ali i zauvek izgubljenih dragocenih života mnogih žena.
                    </p>
                    <p>
                        Zato te pozivamo da se pridružiš <a className="think-pink-text" target="_blank" href="https://www.raceforthecure.eu/sr/Teams/Team/Id/491">Stomačko timu</a>, jer je lakše i lepše kada jedni drugima pružamo podršku i bodrimo se! Ostavljamo link ka našem timu za registraciju i/ili sponzorisanje ovde: <a className="think-pink-text" target="_blank" href="https://www.raceforthecure.eu/sr/Teams/Team/Id/491">LINK</a>.
                        Možeš zatražiti kod tima od našeg Stomačko kapitena, a ostavljamo ga i u nastavku, ukoliko će ti tako biti lakše. Kod Stomačko tima: <span className="color-green">09F4C717</span>. Ukoliko bude bilo kakvih problema prilikom registracije, možeš da se obratiš timu organizatora RFTC putem mejla <a className="think-pink-text" target="_blank" href="mailto:sara@stomacko.com">rftcserbia@gmail.com</a>, ili porukom na instagram profilu <a className="think-pink-text" target="_blank" href="https://www.instagram.com/raceforthecure_serbia/">raceforthecure_serbia</a>.
                    </p>
                    <p>
                        Važno je da napomeno da Stomačko ni u kom slučaju ne dobija novac od ove akcije i da je naše učešće u potpunosti humanitarnog karaktera.
                    </p>
                    <p>
                        Svojom registracijom i/ili sponzorisanjem trke RFTC obezbeđujete Pink poklon pažnje ženama koje se trenutno leče od raka dojke, u više zdravstvenih centara u Srbiji. Važno je da znaju da nisu zaboravljene i da smo uz njih, zato hajde da trčimo i šetamo zajedno!
                    </p>
                    <p>
                        I još nešto! <span className="color-green">Stomačko</span> i <a className="think-pink-text" target="_blank" href="https://www.europadonna.org.rs/sr/">Evropa dona Srbija</a> - srpski forum protiv raka dojke, udružili su se u osmišljavanju Pink recepata, kao preporuke za način pripreme i konzumiranje određenih namirnica za prevenciju bolesti, ali i tokom i nakon lečenja.
                    </p>
                    <p>
                        Zato, kada se registruješ, pošalji Pink recepte onima koje voliš, šetaj, trči, uživaj u lepoj i zdravoj hrani i ne zaboravi da se pregledaš, jer prevencija ima veliku moć kada je zdravlje u pitanju.
                    </p>
                    <p>
                        Vidimo se na Adi!
                    </p>
                    <p>
                        Tvoj Stomačko tim koji predvode
                        Sara & Denis
                    </p>
                </div>

                <div className="col-md-6 mb-3">
                    <img
                        style={{
                            width: '100%',
                            borderRadius: '12px'
                        }}
                        src={`${CLOUDFRONT_URL}assets/pink/race-stomacko.jpeg`}
                        alt="Trka za lek"
                    />
                </div>
            </div>
        </>
    )
}

export default Rftc;