import { createSelector } from "@reduxjs/toolkit";

// Ingredient section
const selectIngredientList = createSelector(
    (state) => state.fridge,
    (fridge) => fridge.ingredientList.data
);

const selectIngredientListInProgress = createSelector(
    (state) => state.fridge,
    (fridge) => fridge.ingredientList.inProgress
);

const selectIngredientObject = createSelector(
    (state) => state.fridge,
    (fridge) => fridge.ingredientList.object
);

const selectIngredientSelectedNumber = createSelector(
    (state) => state.fridge,
    (fridge) => fridge.ingredientList.selectedNumber
);

// Recipe section
const selectRecipeList = createSelector(
    (state) => state.fridge,
    (fridge) => fridge.recipeList.data
);

const selectRecipeListInProgress = createSelector(
    (state) => state.fridge,
    (fridge) => fridge.recipeList.inProgress
);

const selectRecipeSelectedNumber = createSelector(
    (state) => state.fridge,
    (fridge) => fridge.recipeList.selectedNumber
);

const selectRecipeMissingOneOrTwo = createSelector(
    (state) => state.fridge,
    (fridge) => fridge.recipeList.missingOneOrTwo
);

export const fridgeSelector = {
    selectIngredientList,
    selectIngredientListInProgress,
    selectIngredientObject,
    selectIngredientSelectedNumber,
    selectRecipeList,
    selectRecipeListInProgress,
    selectRecipeSelectedNumber,
    selectRecipeMissingOneOrTwo,
};
