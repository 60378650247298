import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from 'main/Api';
import Loader from 'react-loader-spinner';

import Menu from 'components/pages/Menu/Menu';
import Header from 'components/pages/General/Header';
import ScrollToTop from 'components/pages/Menu/ScrollToTop';
import Footer from 'components/pages/Menu/Footer';
import SingleRecipe from 'components/pages/Recipe/Recipe';

import { CALCULATOR_URLS, MAIN_URLS } from 'util/routes/urls';
import { SERVER_URLS } from 'util/routes/serverUrls';
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { selectRecipeList } from "../store/recipes/selector";
import { Helmet } from 'react-helmet';

const Recipe = () => {
	const [recipe, setRecipe] = useState(null);
	const [linkBack, setLinkBack] = useState(null);

	const recipeList = useSelector(selectRecipeList);

	const params = useParams();
	const location = useLocation();

	useEffect(() => {
		if (location.state && location.state.prevPath) {
			setLinkBack(location.state.prevPath);
		} else {
			setLinkBack(MAIN_URLS.RECIPE_LIST);
		}
	}, [location]);

	useEffect(() => {
		if (recipeList) {
			const recipe = recipeList.find((recipe) => recipe.slug === params.id);
			if (recipe) {
				setRecipe(recipe);
				setTimeout(() => {
					window.scrollTo(0, 277);
				}, 0);
				return;
			}
		}

		if (window.recipe?.[params.id]) {
			setRecipe(window.recipe[params.id]);
			return;
		}

		// TODO Move to store
		Api.post(SERVER_URLS.GET_RECIPE_BY_SLUG, { slug: params.id }).then(
			(response) => {
				if (response.data?._id) {
					setRecipe(response.data);
				} else {
					setRecipe(response.data[0]);
				}
			}
		);

	}, []);

	useEffect(() => {
		window.$('#recipe-container').velocity({opacity: 1}, {duration: 700, delay: 100});
	}, [recipe]);

	const header = recipe ? recipe.name : 'Recept';

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Stomačko recepti</title>
				<meta property='og:title' content={`Stomačko recepti`} />
			</Helmet>

			<Menu />
			<Header
				current={header}
				listOfPrevious={[
					{
						text: 'Rezultati',
						link: CALCULATOR_URLS.CALCULATOR_DAYS_RESULTS,
					},
				]}
			/>
			{recipe && <SingleRecipe recipe={recipe} backLink={linkBack} portions={params.number} />}
			{!recipe && (
				<div style={{ padding: '50px', textAlign: 'center', minHeight: '600px' }}>
					<Loader type={'TailSpin'} />
				</div>
			)}
			<ScrollToTop />
			<Footer />
		</>
	);
};

export default Recipe;
