import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import {
	MAIN_URLS,
	CALCULATOR_URLS,
	RECIPE_URLS,
	REGISTER_URLS,
	DISCLAIMER_URLS,
	BLOG_URLS, WEEKLY_URLS, GLOBAL_URLS,
} from 'util/routes/urls';

import Home from 'pages/Home';
import Home2 from 'pages/Home2';
import Calculator from 'pages/Calculator';
import RecipeList from "pages/RecipeList";
import Recipe from 'pages/Recipe';
import FridgeFeature from "pages/Fridge";
import { CalculatorSaved } from "pages/CalculatorSaved";
import CalculatorResults from 'pages/CalculatorResults';
import WeeklyPage from '../pages/Weekly';
import RaceForTheCure from '../pages/RFTC';

require('velocity-animate');
require('velocity-animate/velocity.ui');

const Register = lazy(() => import('pages/Register'));
const Login = lazy(() => import('pages/Login'));
const PasswordChange = lazy(() => import('pages/PasswordChange'));
const ResetPass = lazy(() => import('pages/ResetPassword'));
const ResetPassCode = lazy(() => import('pages/ResetPasswordCode'));

const CalculatorDaysOff = lazy(() => import('pages/CalculatorDaysOff'));
const CalculatorDetails = lazy(() => import('pages/CalculatorDetails'));

const Cookies = lazy(() => import("pages/Disclaimers/Cookies"));
const PrivacyPolicy = lazy(() => import("pages/Disclaimers/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("pages/Disclaimers/TermsAndConditions"));
const ContactForm = lazy(() => import("pages/Contact"));
const NewsletterForm = lazy(() => import("pages/Newsletter"));

const BlogListPage = lazy(() => import("pages/Blog/BlogList"));
const BlogPage = lazy(() => import("pages/Blog/Blog"));
const BlogTestPage = lazy(() => import("pages/Blog/Test"));

const Router = () => {
	return (
		<Suspense fallback={null}>
			<Switch>
				<Route exact path={MAIN_URLS.HOME} component={Home} />
				<Route exact path={MAIN_URLS.HOME2} component={Home2} />

				<Route
					exact
					path={CALCULATOR_URLS.CALCULATOR}
					component={Calculator}
				/>
				<Route
					exact
					path={CALCULATOR_URLS.CALCULATOR_DAYS_OFF}
					component={CalculatorDaysOff}
				/>
				<Route
					exact
					path={CALCULATOR_URLS.CALCULATOR_DAYS_DETAILS}
					component={CalculatorDetails}
				/>
				<Route
					exact
					path={CALCULATOR_URLS.CALCULATOR_RESULTS}
					component={CalculatorResults}
				/>
				<Route
					exact
					path={CALCULATOR_URLS.CALCULATOR_SAVED}
					component={CalculatorSaved}
				/>

				<Route
					exact
					path={MAIN_URLS.RECIPE_LIST}
					component={RecipeList}
				/>
				<Route
					exact
					path={MAIN_URLS.RECIPE_LIST_TAG}
					component={RecipeList}
				/>

				<Route
					exact
					path={MAIN_URLS.FRIDGE}
					component={FridgeFeature}
				/>

				<Route exact path={RECIPE_URLS.RECIPE} component={Recipe} />

                />
                <Route
                    exact
                    path={CALCULATOR_URLS.CALCULATOR_DAYS_DETAILS}
                    component={CalculatorDetails}
                />
                <Route
                    exact
                    path={CALCULATOR_URLS.CALCULATOR_RESULTS}
                    component={CalculatorResults}
                />
                <Route
                    exact
                    path={CALCULATOR_URLS.CALCULATOR_SAVED}
                    component={CalculatorSaved}
                />

                <Route
                    exact
                    path={MAIN_URLS.RECIPE_LIST}
                    component={RecipeList}
                />
                <Route exact path={RECIPE_URLS.RECIPE} component={Recipe} />

				<Route
					exact
					path={REGISTER_URLS.CONTACT_FORM}
					component={ContactForm}
				/>
				<Route
					exact
					path={REGISTER_URLS.NEWSLETTER}
					component={NewsletterForm}
				/>

                <Route
                    exact
                    path={REGISTER_URLS.REGISTER}
                    component={Register}
                />
                <Route
                    exact
                    path={REGISTER_URLS.LOGIN}
                    component={Login}
                />
                <Route
					exact
					path={REGISTER_URLS.CHANGE_PASSWORD}
					component={PasswordChange}
				/>
                <Route
                    exact
                    path={REGISTER_URLS.FORGOT_PASSWORD}
                    component={ResetPass}
                />
                <Route
                    exact
                    path={REGISTER_URLS.FORGOT_PASSWORD_CODE}
                    component={ResetPassCode}
                />

				<Route
					exact
					path={BLOG_URLS.BLOG_LIST}
					component={BlogListPage}
				/>

				<Route
					exact
					path={BLOG_URLS.BLOG}
					component={BlogPage}
				/>

				<Route
					exact
					path={'/blog-test'}
					component={BlogTestPage}
				/>

				<Route
					exact
					path={WEEKLY_URLS.RECIPE}
					component={WeeklyPage}
				/>

				<Route
					exact
					path={GLOBAL_URLS.RACE_FOR_THE_CURE}
					component={RaceForTheCure}
				/>

                {/*<Route exact path={'/404'} component={Mock} />*/}

                <Route exact path={DISCLAIMER_URLS.PRIVACY_POLICY} component={PrivacyPolicy} />
                <Route exact path={DISCLAIMER_URLS.TERMS_AND_CONDTIONS} component={TermsAndConditions} />
                <Route exact path={DISCLAIMER_URLS.COOKIES} component={Cookies} />

                <Redirect to={MAIN_URLS.HOME} />
            </Switch>
        </Suspense>
    );
};

const WrappedRouter = withRouter(Router);

export default WrappedRouter;
