import {Link} from "react-router-dom";
import React from "react";

export const GenericFormMessageScreen = ({message, linkTo, linkText}) => {
    return (
        <div className="signup-wrapper ">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 ">
                        <form
                            className="signup-form px-0 py-40 pt-40"
                        >
                            <div className="py-20 text-semibold">
                                {message}
                            </div>

                            {linkTo && <div className="form-group mb-0 text-center">
                                <Link to={linkTo}>
                                    <button
                                        style={{
                                            borderRadius: '12px'
                                        }}
                                        className="vs-btn w-100 mb-0 mask-style1"
                                        type="submit"
                                    >
                                        {linkText}
                                    </button>
                                </Link>
                            </div>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
