import React, { useState } from 'react';

const TextInput = ({ name, placeholder, set, icon, value, id, type = 'text' }) => {
    const [internalId, setID] = useState();
    if (id && !internalId) {
        setID(id);
    }
    const onChange = (input) => {
        set(input.target.value);
    };

    if (value) {
        return (
            <>
                <input
                    id={internalId}
                    type={type}
                    name={name}
                    className="form-control radius-10"
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                />
                {icon && <i className={"fal " + icon} />}
            </>
        );
    }

    return (
        <>
            <input
                id={id}
                type={type}
                name={name}
                className="form-control radius-10"
                placeholder={placeholder}
                onChange={onChange}
            />
            {icon && <i className={"fal " + icon} />}
        </>
    );
};

export default TextInput;
