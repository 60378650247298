import React from 'react';
import { CLOUDFRONT_URL } from '../../../util/constants/global';


const Top50Reward = () => {
    return (
        <section className="vs-about-wrapper vs-about-layout5 pb-40 pb-xl-100">
            <div className="container">
                <div className="row flex-row">

                    <div className="col-lg-12"

                    >
                        <div
                            className="text-center pt-40 pb-40 pt-xl-100 pb-xl-40 radius-10"

                            style={{
                                backgroundImage: `url('${CLOUDFRONT_URL}assets/award/award-Large.avif')`,
                                backgroundSize:     'cover',
                                backgroundRepeat:   'no-repeat',
                                backgroundPosition: 'center center',
                                boxShadow: '0px 1px 7px 0px rgba(0, 0, 0, 0.1)',
                                transition: 'background 0.5s ease',
                                zIndex: 1,
                                fontWeight: 600,
                            }}
                        >
                            <p className="h4 text-font3 text-theme"
                               style={{
                                   color: '#009432',
                                   marginBottom: '-10px',
                               }}>PC Press - Top 50</p>
                            <h2 className="sec-title1 about-title mb-10 mb-md-20 mt-10" >
                               Nagrada publike
                            </h2>
                            <div className="about-text mb-xl-15"
                                 style={{
                                     textColor: 'black',
                                     display: 'flex',
                                     flexDirection: 'column',
                                     alignItems: 'center'
                                 }}>

                                <p className="px-lg-150 px-20" style={{}}>
                                    Veliko nam je zadovoljstvo da je sajt stomacko.com nominovan za
                                    nagradu publike <a href="https://pcpress.rs/" target="_blank"  style={{color: '#009432'}}>PC Press </a>
                                    magazina u okviru izbora
                                    <a href="https://top50.rs/" target="_blank" style={{color: '#009432'}}> Top50 </a>
                                    – najbolje online stvari 2023.
                                </p>

                                <p className="px-lg-150 px-20" style={{}}>
                                    Hvala sto ste uz Stomačko, jer krčkamo za vas još svašta novo uključujući i mobilnu aplikaciju!
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const Top50RewardV1 = () => {
    return (
        <section className="vs-about-wrapper vs-about-layout4 py-lg-150 pt-60 pb-30">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="about-content text-center">
                            <p className="h3 text-theme text-font3 mb-10">PC Press Top50</p>
                            <h2 className="sec-title1 mb-1">Nagrada Publike</h2>
                            <p className="about-text">
                                Glasaj ovde da Stomačko pobedi!
                            </p>
                            <p className="about-text">Veliko nam je zadovoljstvo da podelimo vest kako je sajt stomacko.com{' '}
                                nominovan za nagradu publike PC Press magazina u okviru izbora Top50 – najbolje online stvari 2023.
                                <br />
                                Stomačko je zajedno sa još devet onlajn nastupa dobio najviše nominacija i sada se nalazi {' '}
                                na listi deset najboljih za koje je moguće glasati putem ovog LINKA
                            </p>
                            <div
                                className="pt-lg-15 pb-30 pb-lg-40 slick-initialized slick-slider"
                                data-slidetoshow="1" data-variablewidth="true" data-loop="false">
                                <div className="slick-list draggable">
                                    <div className="slick-track"
                                         // style="opacity: 1; width: 30000px; transform: translate3d(0px, 0px, 0px);"
                                    >
                                        <div >
                                            <img
                                                style={{
                                                    border: '1px solid #009432',
                                                    borderRadius: '10px'
                                                }}
                                                src="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/medium/658c8f70874d9b3678d90e06%20Medium.jpeg"
                                                alt="About Image"
                                            />
                                        </div>

                                    </div>
                                </div>


                            </div>
                            <p className="about-text">
                                Konkurencija je jaka, a glasanje je počelo juče i trajaće nedelju dana,
                                do 17. januara u 16:45h. Ovom prilikom vas pozivam da podržite Stomačka svojim glasom i podelite dalje link.
                            </p>
                            <p className="about-text">
                                Unapred veliko hvala i ostanite uz Stomačko, jer krčkamo za vas još svašta novo uključujući i mobilnu aplikaciju!
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Top50Reward;