import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { MAIN_URLS } from 'util/routes/urls';

import { translate } from 'util/helpers/translation';
import { getRandomInt } from 'util/helpers/number';

import hero_translate from 'translation/hero';
import { CLOUDFRONT_URL } from '../../../util/constants/global';

// TODO Optimize
const Slide1 = CLOUDFRONT_URL + 'assets/hero/Slide1.avif';
// const Slide9 = CLOUDFRONT_URL + 'assets/hero/Slide9.jpg';
// const Slide11 = CLOUDFRONT_URL + 'assets/hero/Slide11.jpg';
const Slide19 = CLOUDFRONT_URL + 'assets/hero/Slide19.avif';
const Slide21 = CLOUDFRONT_URL + 'assets/hero/Slide21.avif';

const Hero = () => {
    const t = translate(hero_translate, window.lang); // TODO Helper function

    useEffect(() => {
        const $ = window.$;
        $('.vs-hero-carousel').each(function () {
            const vsHslide = $(this),
                container = vsHslide.data('container'),
                responsiveunder = vsHslide.data('responsiveunder'),
                height = vsHslide.data('height'),
                loop = vsHslide.data('loop'),
                navbuttons = vsHslide.data('navbuttons'),
                navstartstop = vsHslide.data('navstartstop'),
                hoverbottomnav = vsHslide.data('hoverbottomnav'),
                hoverprevnext = vsHslide.data('hoverprevnext'),
                navprevnext = vsHslide.data('navprevnext'),
                pauseonhover = vsHslide.data('pauseonhover'),
                autoStart = vsHslide.data('autostart'),
                thumbnailnavigation = vsHslide.data('thumbnailnavigation'),
                slidertype = vsHslide.data('slidertype'),
                maxratio = vsHslide.data('maxratio'),
                showcircletimer = vsHslide.data('showcircletimer');

            // Custom Style Set
            vsHslide.on('sliderWillLoad', function () {
                // Define Variable
                const respLayer = $(this).find('.ls-responsive'),
                    lsDesktop = 'ls-desktop',
                    lsMobile = 'ls-mobile',
                    lsTablet = 'ls-tablet',
                    windowWid = $(window).width();

                let style;

                // Check window and look for style
                if (windowWid <= 767) {
                    style = lsMobile;
                } else if (windowWid <= 1024) {
                    style = lsTablet;
                } else {
                    style = lsDesktop;
                }

                // Check if this layer has style for this device
                respLayer.each(function () {
                    const layer = $(this),
                        respStyle = layer.data(style)
                            ? layer.data(style)
                            : layer.data('ls-desktop'),
                        mainStyle =
                            layer.attr('style') !== undefined
                                ? layer.attr('style')
                                : ' ';
                    layer.attr('style', mainStyle + respStyle);
                });
            });

            vsHslide.layerSlider({
                maxRatio: maxratio ? maxratio : 1,
                type: slidertype ? slidertype : 'responsive',
                pauseOnHover: !!pauseonhover,
                navPrevNext: !!navprevnext,
                hoverPrevNext: !!hoverprevnext,
                hoverBottomNav: !!hoverbottomnav,
                navStartStop: !!navstartstop,
                navButtons: !!navbuttons,
                loop: !loop,
                autostart: !!autoStart,
                height: height ? height : 1080,
                responsiveUnder: responsiveunder ? responsiveunder : 1220,
                layersContainer: container ? container : 1220,
                showCircleTimer: !!showcircletimer,
                skinsPath: 'layerslider/skins/',
                thumbnailNavigation: !thumbnailnavigation,
            });
        });
    }, []);

    return (
        <section
            className="vs-hero-wrapper vs-hero-layout4"
            style={
                {
                    minHeight: window.outerWidth * 0.447,
                    background: 'black'
                }
            }
        >
            <div
                className="vs-hero-carousel navprevnext-white"
                data-height="550px"
                data-navprevnext="true"
            >
                <HeroImage img={Slide1} t={t} num={1} />
                <HeroImage img={Slide21} t={t} num={7} />
                {/*<HeroImage img={Slide11} t={t} num={11} />*/}
                <HeroImage img={Slide19} t={t} num={5} />
                {/*<HeroImage img={Slide9} t={t} num={9} />*/}
            </div>
        </section>
    );
};

const HeroImage = ({ img, t, num }) => {

    const transformForm = getRandomInt(1, 66);

    return (
        <div
            className="vs-hero-slide ls-slide"
            data-ls={`duration: 7000; transition2d: ${transformForm};`}
        >
            <img
                key={num}
                src={img}
                alt="Slide"
                className="ls-bg"
                data-ls="bgsize: cover;"
            />

            <span
                className="hero-subtitle text-white text-font3 h1 ls-l"
                style={{
                    left: '50%',
                    top: '140px',
                    fontSize: '62px',
                    whiteSpace: 'normal',
                    width: '860px',
                    textAlign: 'center',
                }}
                data-ls="offsetxin: -200; durationin: 1000; delayin: 600; easingin:easeOutQuint; offsetxout: -50; durationout: 1000;"
            >
                {t('welcome')}
            </span>
            <h1
                className="hero-title text-white ls-l"
                style={{
                    left: '50%',
                    top: '245px',
                    width: '850px',
                    fontSize: '130px',
                    whiteSpace: 'normal',
                    textAlign: 'center',
                    lineHeight: '90px',
                }}
                data-ls="offsetxin: 100; durationin: 1000; delayin: 700; easingin:easeOutQuint; offsetxout: 50; durationout: 1000;"
            >
                {t('name')}
            </h1>
            <p
                className="ls-l text-white"
                style={{
                    left: '50%',
                    top: '368px',
                    width: '900px',
                    fontSize: '18px',
                    whiteSpace: 'normal',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                }}
                data-ls="delayin: 800;  showinfo:1; durationin:1000; scalexin:0.8; scaleyin:0.8;  scalexout:1.1; scaleyout: 1.1;"
            >
                {t('introText')}
            </p>
            <span
                className="shape1 ls-l"
                style={{
                    left: '300px',
                    top: '379px',
                    width: '80px',
                    height: '2px',
                    backgroundColor: '#ffffff',
                }}
                data-ls="offsetxin: -100; durationin: 1000; delayin: 1500; easingin:easeOutQuint; offsetxout: -50; durationout: 1000;"
            />
            <span
                className="shape2 ls-l"
                style={{
                    left: '840px',
                    top: '379px',
                    width: '80px',
                    height: '2px',
                    backgroundColor: '#ffffff',
                }}
                data-ls="offsetxin: 100; durationin: 1000; delayin: 1500; easingin:easeOutQuint; offsetxout: 50; durationout: 1000;"
            />
            <div
                className="hero-btn ls-l"
                style={{
                    left: '50%',
                    top: '425px',
                    width: '180px',
                    height: '60px',
                    overflow: 'hidden',
                    // borderRadius: '50px',
                    lineHeight: '60px',
                    fontSize: '18px',
                }}
                data-ls="delayin: 1100; showinfo:1; durationin:2000; easingin:easeOutExpo; scalexin:0.9; scaleyin:0.9; scalexout:1.1; scaleyout: 1.1; fadein:true; fadeout: true; transformoriginin:50% 143.8% 0;"
            >
                <Link
                    className="vs-btn mask-style1"
                    to={MAIN_URLS.CALCULATOR}
                    style={{borderRadius: '12px'}}
                >
                    {t('explore')}
                </Link>
            </div>
        </div>
    );
};

export default Hero;
