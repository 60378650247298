import React, { useEffect, useState } from 'react';

import BasicFormHeader from '../BasicFormHeader';
import BasicFormMain from './BasicFormMain';
import { CLOUDFRONT_URL } from '../../../../util/constants/global';
import WaitingResults from '../Results/WaitingResults';

const BasicForm = ({ form, actions, errorMessage, calculationInProgress }) => {
    const [showPanAnimation, setShowPanAnimation] = useState(false);

    const Texture = CLOUDFRONT_URL + 'assets/main/calculator-texture2.jpg';

    useEffect(() => {
        const $ = window.$;

        $('#basic-form-header').velocity("fadeIn", { duration: 1200 });

        $('#za-koliko-osoba-kuvate').velocity({translateX: "-800px", opacity: 1}, {delay: 100, easing: "ease-out"});
        $('#za-koji-period-kuvate').velocity({translateX: "-800px", opacity: 1}, {delay: 300, easing: "ease-out"});
        $('#do-koliko-novca-trosite').velocity({translateX: "-800px", opacity: 1}, {delay: 400, easing: "ease-out"});
        $('#kategorije-jela').velocity({translateX: "-800px", opacity: 1}, {delay: 500, easing: "ease-out"});
        $('#stomacko-magija-dugme').velocity({translateX: "-800px", opacity: 1}, {delay: 600, easing: "ease-out"});
    }, []);

    useEffect(() => {
        const $ = window.$;
        // When calculation started start end animation
        if (calculationInProgress) {
            setShowPanAnimation(true);

            $('#za-koliko-osoba-kuvate').velocity("fadeOut");
            $('#za-koji-period-kuvate').velocity("fadeOut");
            $('#do-koliko-novca-trosite').velocity("fadeOut");
            $('#kategorije-jela').velocity("fadeOut");
            $('#stomacko-magija-dugme').velocity("fadeOut", {
                complete: () => {
                    $('#waiting-results').velocity("fadeIn");
                }
            });
            //
            // $('#basic-form-header-content').velocity("fadeOut", {delay: 300});

            $('#main-calculation-panel').velocity({translateX: '3000px'}, {
                delay: 1500,
                complete: () => {
                    actions.endAnimationFinished();
                }
            });

            // setTimeout(() => {
            //     actions.endAnimationFinished();
            // }, 2000);
        }
    }, [calculationInProgress]);

    useEffect(() => {
        if (errorMessage) {
            const $ = window.$;
            $('#main-calculation-panel').velocity("stop").velocity({translateX: '0px'});

            $('#waiting-results').velocity("fadeOut");

            $('#za-koliko-osoba-kuvate').velocity("stop").velocity("fadeIn");
            $('#za-koji-period-kuvate').velocity("stop").velocity("fadeIn");
            $('#do-koliko-novca-trosite').velocity("stop").velocity("fadeIn");
            $('#stomacko-magija-dugme').velocity("stop").velocity("fadeIn");
            $('#kategorije-jela').velocity("stop").velocity("fadeIn");


        }
    }, [errorMessage]);

    return (
        <section className="vs-contact-wrapper vs-contact-layout1 py-60">
            <div className="container">
                <div
                    id="main-calculation-panel"
                    style={{
                        backgroundColor: '#eee',
                        backgroundImage: `url(${Texture})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        position: 'relative',
                        minHeight: '716px',
                    }}
                    className="inner-wrapper px-lg-100 px-sm-40 px-20 calculator-panel"
                >
                    <BasicFormHeader />
                    <BasicFormMain
                        form={form}
                        actions={actions}
                        errorMessage={errorMessage}
                    />

                    <div id="waiting-results" style={{display: 'none', marginTop: '-120px'}}>
                        {showPanAnimation && <WaitingResults />}
                    </div>
                    <p className="form-messages mt-20 mb-0 text-center" />
                </div>
            </div>
        </section>
    );
};

export default BasicForm;
