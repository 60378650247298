const sr = {
    welcome: 'Dobro došli na',
    name: 'Stomačko',
    introText: 'Najbolji proizvod za pun stomak i novčanik',
    explore: 'Isprobajte sada!',
};

const en = {
    welcome: 'Welcome to',
    name: 'Tummy',
    introText: 'Best way to eat good while saving money',
    explore: 'Try it now!',
};

const about_translate = {sr, en};

export default about_translate;