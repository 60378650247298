import Api from 'main/Api';
import { SERVER_URLS } from 'util/routes/serverUrls';

const getIngredientListApi = () => {
    return Api.get(SERVER_URLS.GET_FRIDGE_INGREDIENT_LIST);
};

const getRecipeListApi = () => {
    return Api.get(SERVER_URLS.GET_FRIDGE_RECIPE_LIST);
};

export const fridgeApi = {
    getIngredientListApi,
    getRecipeListApi,
};
