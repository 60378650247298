import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import RecipeList from '../Calculator/Results/RecipeList';
import IngredientListWrapper from '../Calculator/Results/IngredientList';
import Divider from '../General/DIvider';
import { monthNamesSr } from '../../../util/helpers/date';

const Weekly = ({weekly, hasWeekly, inProgress}) => {
    const [active, setActive] = useState(1);

    useEffect(() => {
        if (weekly) {
            window.$('#weekly-container').velocity("fadeIn", {duration: 750});
            window.$('#recipes-tab').click();
        }
    }, [weekly]);

    if (inProgress) {
        return (
            <div className="container text-center mt-5 mb-5"
                 style={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     width: '100vw'
                 }}
            >
                <Loader type={'TailSpin'} />
            </div>
        )
    }

    if (!hasWeekly) {
        return (
            <section className="vs-blog-wrapper blog-details-layout1 pt-40 pb-lg-120 pb-30" id="blog-container" style={{opacity: 0}}>
                <div className="container blog-container-style">
                    Danas nema preporucenih recepata!
                </div>
            </section>
        );
    }

    if (!weekly) {
        return (
            <div className="container text-center mt-5 mb-5"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100vw'
                }}
            >
                <Loader type={'TailSpin'} />
            </div>
        )
    }

    let totalCost = 0;
    const recipes = weekly?.weeklyData?.recipes ? Object.entries(weekly?.weeklyData?.recipes).map((value) => {
        const recipe = value[1];
        const date = value[0];
        totalCost += recipe.cost;
        return {
            ...recipe,
            numberOfPeople: 1,
            prepForDays: 1,
            date: date,
            ingredients: [],
        }
    }) : [];

    const momentInstance = window.moment(weekly?.startDate);
    const startDate = window.moment(weekly?.startDate).format('DD');
    const endDate = window.moment(weekly?.startDate).add(7, 'day').format('DD');

    return (
        <section className="vs-food-box-wrapper food-box-layout1 position-relative link-inherit py-50 py-lg-50" id="weekly-container" >


            <div className="container blog-container-style">
                <div className="container">
                    <div className="col-12 text-center">
                        <span className="text-font3 text-theme h3 d-block" style={{
                            color: '#009432'
                        }}>
                            {startDate} - {endDate} {monthNamesSr[momentInstance.format('M') - 1]}
                        </span>
                        <h2 className="about-title h1 ">
                            Nedeljni meni
                        </h2>
                        <p style={{textAlign: 'left'}}>
                            {weekly.description}
                        </p>
                    </div>

                    <div className="food-menu-wrapper food-menu-style1 list-style-none text-center"
                         style={{
                             position: 'sticky',
                             top: '0px',
                             backgroundColor: "white",
                             zIndex: '3',
                             paddingBottom: '5px',
                             paddingTop: '10px',
                             // boxShadow: '0px 10px 6px -6px rgba(0,0,0,0.25)'
                         }}
                    >

                    <ul className="nav nav-tabs d-block border-0" role="tablist">
                        <li><a onClick={() => setActive(1)} className="custom-toggle mask-style3 active" id="recipes-tab" data-toggle="tab"
                               href="#recipes" role="tab" aria-controls="recipes" aria-selected="true">Recepti</a>
                        </li>
                        <li><a onClick={() => setActive(2)} className="custom-toggle mask-style3" id="ingredients-tab" data-toggle="tab"
                               href="#ingredients" role="tab" aria-controls="ingredients"
                               aria-selected="false">Sastojci</a></li>
                    </ul>

                    <Divider style={{marginBottom: '5px'}} />

                    </div>

                    <div className="text-center food-title text-lg">
                        Ukupna cena:
                        <div className=" mb-3 text-theme text-bold">
                            {totalCost.toFixed(2)} RSD
                        </div>
                    </div>

                <div className="tab-content result-page-container" id="foodTabContent">
                    <div className="tab-pane active link-inherit" id="recipes" aria-labelledby="recipes-tab">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-md-12">
                                <RecipeList
                                    recipes={recipes}
                                    isShowingDays={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane " id="ingredients" aria-labelledby="ingredients-tab">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-md-12">
                                <IngredientListWrapper
                                    ingredients={weekly?.weeklyData?.ingredients}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
    );
};

export default Weekly;