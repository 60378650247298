import React from 'react';

const PickerInput = ({ choicesArray, active, onChange }) => {
    const onPick = (value) => () => {
        onChange(value);
    };

    return (
        <>
            {choicesArray.map((choice, index) => {
                const additionalClass =
                    choice === active ? 'active color-white' : '';
                return (
                    <div
                        style={{
                            borderRadius: index === 9
                                ? '0 10px 10px 0' : index === 0
                                    ? '10px 0 0 10px' : 0,
                        }}
                        key={index}
                        onClick={onPick(choice)}
                        className={
                            'icon-btn cursor-pointer text-hover-effect  ' +
                            additionalClass
                        }
                        tabIndex="0"
                    >
                        <span

                        >{choice}</span>
                    </div>
                );
            })}
        </>
    );
};

export default PickerInput;
