import React, { useEffect } from 'react';
import {Link} from "react-router-dom";
import {MAIN_URLS} from "../../../util/routes/urls";

// import { useRive } from '@rive-app/react-canvas';
import { CLOUDFRONT_URL } from '../../../util/constants/global';

// const FridgeFile = CLOUDFRONT_URL + "assets/rive/fridge.riv";
// const BulbFile = CLOUDFRONT_URL + "assets/rive/stomacko_bulb.riv";

let timeout, timeout2;
const AboutV2 = () => {
    useEffect(() => {
        const $ = window.$;
        if ($) {
            $('.counter').counterUp({
                delay: 100,
                time: 500,
            });
        }
    }, []);

    return (
        <section
            className="vs-about-wrapper vs-about-layout7 pt-lg-150 pt-60 pb-lg-150 pb-60"
            id="about"
        >
            <div className="container">
                <div
                    className="row"
                >
                    <div className=" col-lg-6">
                        <div className="about-image pr-lg-85 position-relative mb-30 mb-lg-0 d-none d-sm-block">
                            <img
                                src={CLOUDFRONT_URL + "assets/public/about.jpeg"}
                                alt="About Us"
                                className="w-100 home-image-bottom radius-10"
                            />
                            <div className="small-home-image radius-10">
                                <img src={CLOUDFRONT_URL + "assets/public/how-to-use2.jpeg"} alt="Rezultat" className="home-image radius-10" />
                            </div>
                            <div className="small-exp-box">
                                <img
                                    src={CLOUDFRONT_URL + "assets/public/exp-box-shape.png"}
                                    alt="Shadow"
                                    className="shadow-shape"
                                />
                                <span className="counter text-theme">224</span>
                                <p className="exp-text">Recepata</p>
                            </div>
                        </div>
                    </div>

                    <IconSection />
                </div>
            </div>
        </section>
    );
};

const IconSection = () => {
    const isSpecial = window.location.search === '?v=2';

    return (
        <div className={`col-lg-6 align-self-center ${isSpecial ? 'justify-content-center' : ''}`}>
            <span className={`text-font3 text-theme h3 d-block mb-3 ${isSpecial ? 'mt-4 mt-md-0' : ''}`} style={{
                color: '#009432'
            }}>
                Šta još nudimo?
            </span>
            <h2 className="sec-title1 mb-lg-35">
                Stomačko kalkulator
            </h2>
            <p className="mb-lg-45">
                Potpuno besplatno rešenje koje vam pomaže da smislite šta ćete za ručak i pritom uštedite!

            </p>
            <div className="row">
                <div className="col-lg-11">
                    <div
                        className="media mb-lg-35 mb-20 text-hover-parent  align-items-center"
                    >
                        <div className="media-icon pr-15 pr-lg-20">
                            <img src={`${CLOUDFRONT_URL}assets/public/lightbulb-green.png`} alt={"lightbulb"} />
                        </div>
                        <div className="media-body">
                            <h3 className="mb-10 h4 text-hover-child">
                                Ideje za kuvanje
                            </h3>
                            <p className="mb-0">
                                Imate problem da smislite šta ćete da spremite za ručak? Mi vam nudimo rešenje! Automatski biramo neki od više desetina recepata koje posedujemo.
                            </p>
                        </div>
                    </div>
                    <div
                        className="media mb-lg-35 mb-20 text-hover-parent align-items-center"
                    >
                        <div className="media-icon pr-15">
                            <img src={`${CLOUDFRONT_URL}assets/public/fridge-green.png`} alt={"fridge"} />
                        </div>
                        <div className="media-body">
                            <h3 className="mb-10 h4 text-hover-child">
                                Želite da uštedite
                            </h3>
                            <p className="mb-0">
                                Ukoliko niste sigurni koliko vam novca odlazi na namirnice, ili jednostavno želite da ograničite trošenje, mi vam u tome pomažemo! Stomačko kalkulator nudi opciju da ograničite budžet za određeni period u kojem pripremate ručkove.
                            </p>
                        </div>
                    </div>
                    <div className="vs-btn-group mt-30 text-center">
                        <Link
                            className="vs-btn mask-style1"
                            to={MAIN_URLS.CALCULATOR}
                            style={{borderRadius: '12px'}}
                        >
                            Isprobajte Kalkulator
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

// const RiveSection = () => {
//     const isSpecial = window.location.search === '?v=2';
//
//     const { rive: lightbulbRive, RiveComponent: Lightbulb } = useRive({
//         src: BulbFile,
//         animations: ['Timeline 1'],
//         autoplay: false,
//     });
//
//     const { rive: fridgeRive, RiveComponent: Fridge } = useRive({
//         src: FridgeFile,
//         animations: ['open'],
//         autoplay: false,
//     });
//
//
//     return (
//         <div className={`col-lg-6 align-self-center ${isSpecial ? 'justify-content-center' : ''}`}>
//             <span className={`text-font3 text-theme h3 d-block mb-3 ${isSpecial ? 'mt-4 mt-md-0' : ''}`} style={{
//                 color: '#009432'
//             }}>
//                 Šta još nudimo?
//             </span>
//             <h2 className="sec-title1 mb-lg-35">
//                 Stomačko kalkulator
//             </h2>
//             <p className="mb-lg-45">
//                 Potpuno besplatno rešenje koje vam pomaže da smislite šta ćete za ručak i pritom uštedite!
//
//             </p>
//             <div className="row">
//                 <div className="col-lg-11">
//                     <div
//                         onMouseEnter={() => {
//                             lightbulbRive && lightbulbRive.play(['Timeline 1']);
//                             timeout = setTimeout(() => {
//                                 lightbulbRive.play(['Timeline 2']);
//                             }, 650);
//                         }}
//                         onMouseLeave={() => {
//                             lightbulbRive && lightbulbRive.reset();
//                             clearTimeout(timeout);
//                         }}
//                         className="media mb-lg-35 mb-20 text-hover-parent"
//                     >
//                         <div className="media-icon pr-15 pr-lg-20">
//                             <span>
//                                 <div style={{width: '100px', height: '160px', marginTop: '-40px'}}>
//                                     <Lightbulb />
//                                 </div>
//
//                             </span>
//                         </div>
//                         <div className="media-body">
//                             <h3 className="mb-10 h4 text-hover-child">
//                                 Ideje za kuvanje
//                             </h3>
//                             <p className="mb-0">
//                                 Imate problem da smislite šta ćete da spremite za ručak? Mi vam nudimo rešenje! Automatski biramo neki od više desetina recepata koje posedujemo.
//                             </p>
//                         </div>
//                     </div>
//                     <div
//                         onMouseEnter={() => {
//                             fridgeRive && fridgeRive.play(['open']);
//                             timeout = setTimeout(() => {
//                                 fridgeRive.play(['light']);
//                             }, 650);
//                             timeout2 = setTimeout(() => {
//                                 fridgeRive.play(['shake']);
//                             }, 1850);
//                         }}
//                         onMouseLeave={() => {
//                             fridgeRive && fridgeRive.reset();
//                             clearTimeout(timeout);
//                             clearTimeout(timeout2);
//                         }}
//                         className="media mb-lg-35 mb-20 text-hover-parent"
//                     >
//                         <div className="media-icon pr-15 pr-lg-20">
//                             <span>
//                                 <div style={{width: '100px', height: '120px', marginTop: '20px'}}>
//                                     <Fridge />
//                                 </div>
//                             </span>
//                         </div>
//                         <div className="media-body">
//                             <h3 className="mb-10 h4 text-hover-child">
//                                 Želite da uštedite
//                             </h3>
//                             <p className="mb-0">
//                                 Ukoliko niste sigurni koliko vam novca odlazi na namirnice, ili jednostavno želite da ograničite trošenje, mi vam u tome pomažemo! Stomačko kalkulator nudi opciju da ograničite budžet za određeni period u kojem pripremate ručkove.
//                             </p>
//                         </div>
//                     </div>
//                     <div className="vs-btn-group mt-30 text-center">
//                         <Link
//                             className="vs-btn mask-style1"
//                             to={MAIN_URLS.CALCULATOR}
//                             style={{borderRadius: '12px'}}
//                         >
//                             Isprobajte Kalkulator
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

export default AboutV2;
