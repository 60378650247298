import { configureStore } from '@reduxjs/toolkit';

import { calculatorForm } from './callculator/form';
import { calculatorResult } from './callculator/result';
import { userReducer } from './user/user';
import {recipeListReducer} from "./recipes/recipeList";
import { FridgeReducer } from './fridge/fridge.reducer';
import { HomeReducer } from './home/home.reducer';
import { blogListReducer } from './blog/blog';
import { weekListReducer } from './weekly/weekly';

export const store = configureStore({
    reducer: {
        calculatorForm: calculatorForm.reducer,
        calculatorResult: calculatorResult.reducer,
        user: userReducer.reducer,
        recipeList: recipeListReducer.reducer,
        fridge: FridgeReducer.reducer,
        home: HomeReducer.reducer,
        blog: blogListReducer.reducer,
        weekly: weekListReducer.reducer,
    },
});
