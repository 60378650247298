import React, { useCallback } from 'react';
import { RECIPE_URLS, replaceWildcards } from '../../../util/routes/urls';
import { Link } from 'react-router-dom';
import { CLOUDFRONT_URL } from '../../../util/constants/global';
import { useDispatch, useSelector } from 'react-redux';
import { fridgeSelector } from '../../../store/fridge/fridge.slector';
import { fridgeActions } from '../../../store/fridge/fridge.reducer';

const FridgeRecipeList = ({recipeList}) => {
    const dispatch = useDispatch();

    const ingredientIdMap = useSelector(fridgeSelector.selectIngredientObject);
    const isMobile = window.innerWidth < 1200;

    const selectedRecipeNumber = useSelector(fridgeSelector.selectRecipeSelectedNumber);
    const numberOfMissingOneOrTwo = useSelector(fridgeSelector.selectRecipeMissingOneOrTwo);

    const onIngredientClick = useCallback((ingredient) => {
        dispatch(fridgeActions.toggleIngredientSelection(ingredient));
    }, []);

    return (
        <div>
            {recipeList && selectedRecipeNumber > 0 &&
                <div className="row">
                    <div className="col-lg-12 mb-3 text-center">
                        <span>Za sledeća jela imate sve sastojke i mozete ih spremiti:</span>
                    </div>
                        {recipeList.map((recipe) => {
                        if (!recipe.selected) {
                            return null;
                        }

                        const link = replaceWildcards(RECIPE_URLS.RECIPE, [
                            recipe.slug,
                        ]);
                        return (
                            <Recipe
                                key={recipe.id}
                                recipe={recipe}
                                link={link}
                                isMobile={isMobile}
                            >
                                <p className="food-text mb-0 text-xs">
                                    {recipe.ingredients.map((ingredient) => ingredientIdMap[ingredient.id].name).join(', ')}
                                </p>
                            </Recipe>
                        );
                    })}
                </div>
                }
            {selectedRecipeNumber === 0 && (
                <div className="col-lg-12 mb-3  text-center">
                    Ne postoji jelo sa izabranim sastojcima u našoj kuhinji. Molimo vas da proširite listu izabranih sastojaka.
                    <div className="mb-4 d-flex justify-content-center mt-2">
                        <img
                            style={{
                                maxWidth: '400px'
                            }}
                            src={CLOUDFRONT_URL + "assets/public/no-filter.jpeg"}
                            alt={'Nema jela za zadati filter'}
                        />
                    </div>
                    <br />

                </div>
            )}
            {numberOfMissingOneOrTwo > 0 &&
                <div className="col-lg-12 mb-3 text-center">
                <span>Ispod se nalaze jela za koja <b>nedostaju jedan ili dva sastojka</b>. Klikom na znak +, dodaćete sastojke koji nedostaju u postojeću listu za pretraživanje.</span>
                </div>
            }
            <br />
            <div  className="row">
            {recipeList &&
                recipeList.map((recipe) => {
                    if (!recipe.oneMissing && !recipe.twoMissing) {
                        return null;
                    }

                    const link = replaceWildcards(RECIPE_URLS.RECIPE, [
                        recipe.slug,
                    ]);
                    return (
                        <Recipe
                            key={recipe.id}
                            recipe={recipe}
                            link={link}
                            isMobile={isMobile}
                            isMissing={true}
                        >
                            <div className="food-text mb-0 text-xs">
                                {recipe.ingredients.map((ingredient) => ingredientIdMap[ingredient.id].name).join(', ')}
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div>
                                        Nedostaju: <span style={{color: 'red'}}>
                                        {
                                            recipe.ingredients
                                                .filter((ingredient) => !ingredient.selected)
                                                .map((ingredient) => ingredientIdMap[ingredient.id].name)
                                                .join(', ')
                                        }
                                        </span>
                                    </div>
                                    <div
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Dodaj u listu selektovanih sastojaka"
                                        className="fridge-recipe-add-ingredients"
                                        onClick={() => {
                                            recipe.ingredients
                                                .filter((ingredient) => !ingredient.selected)
                                                .forEach((ingredient) => onIngredientClick(ingredient));
                                        }}
                                    >
                                        +
                                    </div>
                                </div>
                            </div>
                        </Recipe>
                    );
                })}
            </div>
        </div>
    );
};

export default FridgeRecipeList;


const Recipe = ({recipe, link, isMobile, isMissing, children}) => {
    return (
        <div className="col-md-6 col-lg-4 mb-30 radius-10">
            <div className="vs-food-box h-100 mb-0 radius-10">
                <div className="food-image image-scale-hover radius-10" style={{ aspectRatio: '3 / 2'}}>
                    <Link to={{ pathname: link}}>
                        <img
                            style={{opacity: isMissing ? 0.6 : 1}}
                            src={
                                isMobile
                                    ? CLOUDFRONT_URL + 'small/' + recipe.id + ' Small.jpeg'
                                    : CLOUDFRONT_URL + 'medium/' + recipe.id + ' Medium.jpeg'
                            }
                            alt='Food'
                            className="w-100"
                        />
                    </Link>
                </div>
                <div className="food-content">
                    <h3 className="food-title h4 mb-0">
                        <Link to={link}>
                            {recipe.name}
                        </Link>
                    </h3>

                    {children}

                </div>
            </div>
        </div>
    );
};