import Api from 'main/Api';
import { SERVER_URLS } from 'util/routes/serverUrls';

const getToday = () => {
	return Api.get(SERVER_URLS.GET_WEEKLY_DAY);
};

const getWeekly = () => {
	return Api.get(SERVER_URLS.GET_WEEKLY_WEEK);
};


export const weeklyApi = {
	getToday,
	getWeekly,
};
