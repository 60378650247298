import React from 'react';

const PickerInputDifficulty = ({ choicesArray, active, onChange }) => {
    const onPick = (value) => () => {
        onChange(value);
    };

    return (
        <>
            {choicesArray.map((choice, index) => {
                const additionalClass = active.has(choice.value)
                    ? `meal-prepare-lvl${choice.value}-active`
                    : `meal-prepare-lvl${choice.value}`;

                return (
                    <div
                        key={index}
                        onClick={onPick(choice.value)}
                        className={
                            'cursor-pointer icon-btn-custom ' + additionalClass
                        }
                        tabIndex="0"
                    >
                        <span>
                            {choice.label}
                        </span>
                    </div>
                );
            })}
        </>
    );
};

export default PickerInputDifficulty;
