import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const GOOGLE_ANALYTICS_ID = "G-VFQZPN35ES";
const initializeGa = (setHasInit, path) => {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
    setHasInit(true);
    ReactGA.send({ hitType: "pageview", page: path });
}
function GoogleAnalytics() {
    const location = useLocation();

    const [hasInit, setHasInit] = useState(null);

    useEffect(() => {
        const path = location ? location?.pathname + location?.search : window.location;

        if (hasInit) {
            // Send pageview with a custom path
            ReactGA.send({ hitType: "pageview", page: path });
        } else {
            initializeGa(setHasInit, path);
        }

    }, [location]);

    return null;
}
export default GoogleAnalytics;