import Api from 'main/Api';
import { SERVER_URLS } from 'util/routes/serverUrls';

const getDailyRecipeRecommendation = () => {
    return Api.get(SERVER_URLS.GET_DALY_RECOMMENDATION_URL);
};

export const homeApi = {
    getDailyRecipeRecommendation,
};
