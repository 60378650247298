import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
    setNumberOfPeople,
    setMoney,
    setDateRange,
    setMealOnOff, categoryChange,
} from 'store/callculator/form';

import { CALCULATOR_URLS } from 'util/routes/urls';
import {calculateRecipes, resetCompleted} from 'store/callculator/result';

import Menu from 'components/pages/Menu/Menu';
import ScrollToTop from 'components/pages/Menu/ScrollToTop';
import Header from 'components/pages/General/Header';
import BasicForm from 'components/pages/Calculator/Main/BasicForm';
import WaitingResults from 'components/pages/Calculator/Results/WaitingResults';
import Footer from 'components/pages/Menu/Footer';
import { Helmet } from 'react-helmet';

class Calculator extends Component {
    constructor() {
        super();

        this.state = {
            errorMessage: null,
            showWaitingResults: false,
        };

        this.actions = {
            onMoneyChange: this.onMoneyChange,
            onNumberOfPeopleChange: this.onNumberOfPeopleChange,
            onDateRangeChange: this.onDateRangeChange,
            onDayOffOn: this.onDayOffOn,
            onMealOnOff: this.onMealOnOff,
            calculateResults: this.onCalculateResults,
            endAnimationFinished: this.endAnimationFinished,
            onCategoryChange: this.onCategoryChange,
        };
    }

    componentDidMount() {
        this.setState({showWaitingResults: false});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.calculatorForm !== prevProps.calculatorForm) {
            this.setErrorMessage(null);
        }

        if (this.props.result !== prevProps.result) {
            if (this.props.result.errorMessage) {
                this.setErrorMessage(this.props.result.errorMessage);
            }
        }
    }

    // Dispatchers
    setMoney = (money) => {
        this.props.dispatchSetMoney(money);
    };

    setNumberOfPeople = (money) => {
        this.props.dispatchSetNumberOfPeople(money);
    };

    setDateRange = (range) => {
        this.props.dispatchSetDaterange(range);
    };

    setMealsOnOff = (mealIndex) => {
        this.props.dispatchMealOnOff(mealIndex);
    };

    onCategoryChange = (value) => {
        this.props.dispatchCategoryChange(value);
    }

    onCalculateResults = () => {
        const dates = this.props.calculatorForm.datesFormObject
            .filter((date) => date.active)
            .map((date) => {
                return {
                    prep: date.prepOnTheDay,
                    numberOfPeople: date['Ručak'].numberOfPeople,
                    date: date.date,
                };
            });

        // Get how many portions there are
        const portionNumber = dates.reduce((number, date) => {
            if (date.numberOfPeople) {
                return number + date.numberOfPeople;
            }
            return number;
        }, 0);

        // Set for how many days is cooking
        let days = 0;
        let totalPeople = 0;
        for (let i = dates.length - 1; i >= 0; i--) {
            if (dates[i].prep) {
                dates[i].prepForDays = days + 1;
                dates[i].totalPeople = totalPeople + dates[i].numberOfPeople;
                totalPeople = 0;
                days = 0;
            } else {
                days++;
                totalPeople += dates[i].numberOfPeople;
            }
        }

        const category = this.props.calculatorForm.category.meso
        || this.props.calculatorForm.category.posno
        // || this.props.calculatorForm.category.prolece
        // || this.props.calculatorForm.category.leto
        || this.props.calculatorForm.category.pink
        || this.props.calculatorForm.category.riba
            ? this.props.calculatorForm.category : undefined;

        const params = {
            money: this.props.calculatorForm.money,
            dates,
            portionNumber,
            category: category,
        };

        this.props.dispatchCalculate(params);
    };

    // On change functions
    onMoneyChange = (money) => {
        this.setMoney(money);
    };

    onNumberOfPeopleChange = (number) => {
        this.setNumberOfPeople(number);
    };

    onDateRangeChange = (range) => {
        this.setDateRange(range);
    };

    onMealOnOff = (mealIndex) => {
        // Prevent turning off all meals
        const meals = [...this.props.calculatorForm.meals];
        if (meals[mealIndex].active) {
            const inactiveNumberOfMeals =
                this.props.calculatorForm.meals.filter((meal) => !meal.active);
            if (meals.length - 1 === inactiveNumberOfMeals.length) {
                this.setErrorMessage('Ne možete isključiti sve obroke');
                return;
            }
        }

        this.setMealsOnOff(mealIndex);
    };

    endAnimationFinished = () => {
        this.setState({showWaitingResults: true});
    }

    setErrorMessage = (errorMessage = null) => {
        this.setState({ errorMessage });
    };

    render() {
        const { inProgress, completed } = this.props.result;
        const { showWaitingResults } = this.state;

        if (completed && showWaitingResults) {
            // this.props.dispatchResetComplete();
            return <Redirect to={CALCULATOR_URLS.CALCULATOR_RESULTS} />;
        }

        return (
            <>
                <CalculatorHelmet />

                <Menu />
                <Header current={'calculator'} />
                {!showWaitingResults && (
                    <BasicForm
                        form={this.props.calculatorForm}
                        actions={this.actions}
                        errorMessage={this.state.errorMessage}
                        calculationInProgress={inProgress}
                    />
                )}
                <ScrollToTop />
                <Footer />
            </>
        );
    }
}

const CalculatorHelmet = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Stomačko kalkulator</title>
            <meta property='title' content="Stomačko kalkulator" />
            <meta property='description' content="Najbolji proizvod za pun stomak i novčanik" />
            <meta property="og:site_name" content = "Stomačko" />

            <meta property='og:title' content="Stomačko kalkulator" />
            <meta property="og:type" content='website'/>
            <meta property='og:description' content="Najbolji proizvod za pun stomak i novčanik" />

            <meta property="og:image" content="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/assets/public/og-image.png" />
            <meta property="og:image:width" content="378" />
            <meta property="og:image:height" content="156" />
            <meta property='og:url' content={`https://stomacko.com/kalkulator`} />

            <meta property='twitter:title' content="Stomačko kalkulator" />
            <meta property='twitter:image' content="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/assets/public/og-image.png" />
            <meta property='twitter:url' content={`https://stomacko.com/kalkulator`} />

            <meta name='keywords' content="stomacko kalkulator, usteda, sta jesti, jeftino, budzet, kuvanje" />
        </Helmet>
    );
}

const mapStateToProps = (state) => ({
    calculatorForm: state.calculatorForm,
    result: state.calculatorResult,
});

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchSetMoney: (money) => dispatch(setMoney(money)),
        dispatchSetNumberOfPeople: (number) =>
            dispatch(setNumberOfPeople(number)),
        dispatchSetDaterange: (range) => dispatch(setDateRange(range)),
        dispatchMealOnOff: (meals) => dispatch(setMealOnOff(meals)),
        dispatchCalculate: (params) => dispatch(calculateRecipes(params)),
        dispatchResetComplete: () => dispatch(resetCompleted()),
        dispatchCategoryChange: (value) => dispatch(categoryChange(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
