import React, { useEffect, useState } from 'react';

const CollapsableYoutubeVideo = ({video, mobileLink}) => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(window.innerWidth < 992 || window.$('body').width() < 992)
    }, [window.innerWidth, video]);

    return (
        <React.Fragment key={video}>
            <span
                className="cursor pointer text-md text-theme text-semibold cursor-pointer"
                data-toggle="collapse"
                data-target="#pomoc"
                aria-expanded="false"
                aria-controls="collapseExample"
                style={{
                    '&:hover': {
                        textStyle: 'bold'
                    }
                }}
            >
                    Video uputstvo
            </span>

            <div
                className="collapse"
                id="pomoc"
            >
                <div
                    className="col-lg-12 d-flex justify-content-center align-self-center"
                    id="video-help"
                >
                    <iframe
                        width={isMobile ? "305px" : "611px"}
                        height={isMobile ? "165px" : "335px"}
                        src={video}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>

                {isMobile &&
                    <div className="mt-2">
                        Pogledajte specijalizovano <a className="color-green" target="_blank" href={mobileLink}>uputstvo za mobilni</a>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

export default CollapsableYoutubeVideo;