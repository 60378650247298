import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getRecipeListApi } from './api';

const initialState = {
	recipeList: [],
	inProgress: false,
	hasMoreRecipes: false,
	hasFilterActive: false,
	filters: {days: undefined, difficulty: undefined, tags: undefined},
	offset: 0,
};

const getRecipeListAction = createAsyncThunk('recipe-list/get', async ({ categoryId, limit, offset, days, difficulty, tags, ingredients }) => {
	let category = categoryId ? parseInt(categoryId) : -1;

	const ignoreNotPublished = process.env.REACT_APP_ENV === 'local' ? true : undefined;

	const res = await getRecipeListApi(category, limit, offset, days, difficulty, tags, ingredients, ignoreNotPublished);
	return { data: res.data, offset };
});

export const recipeListReducer = createSlice({
	name: 'RECIPE_LIST',
	initialState,
	reducers: {
		changeOffsetAction: (state, action) => {
		 	state.offset = action.payload ?? 0;
		}
	},
	extraReducers: {
		[getRecipeListAction.pending]: (state, {meta}) => {
			state.hasFilterActive = !!(meta.arg.days || meta.arg.difficulty || meta.arg.tags || meta.arg.ingredients);
			state.filters = meta.arg;
			state.inProgress = true;
		},
		[getRecipeListAction.fulfilled]: (state, args) => {
			const {payload, meta} = args;

			if (payload.offset === 0) {
				state.recipeList = [...payload.data];
			} else {
				state.recipeList = [...state.recipeList, ...payload.data];
			}
			state.hasMoreRecipes = payload.data.length !== 0;
			state.inProgress = false;
			state.categoryId = meta.arg.categoryId;
		},
		[getRecipeListAction.rejected]: (state) => {
			state.inProgress = false;
		},
	},
});

const { changeOffsetAction } = recipeListReducer.actions;

// Action creators are generated for each case reducer function
export { getRecipeListAction, changeOffsetAction };

export default recipeListReducer.reducer;
