import React from 'react';

import {Link} from "react-router-dom";
import { DISCLAIMER_URLS, MAIN_URLS } from '../../../util/routes/urls';
import { CLOUDFRONT_URL } from '../../../util/constants/global';
import NewsletterFooter from './NewsletterFooter';

const Logo = CLOUDFRONT_URL + 'assets/logo/logov2.png';

// const FooterImg1 = CLOUDFRONT_URL + 'assets/footer/footer1.jpeg';
const FooterImg2 = CLOUDFRONT_URL + 'assets/footer/footer2.avif';
// const FooterImg3 = CLOUDFRONT_URL + 'assets/footer/footer3.jpeg';
// const FooterImg4 = CLOUDFRONT_URL + 'assets/footer/footer4.jpeg';


const Footer = ({footerImage}) => {
    const backgroundImage = footerImage ?? FooterImg2;

    return (
        <footer className="footer-wrapper footer-layout1 bg-dark dark-footer">
            <div
                className="footer-widget-wrapper footer-widget-layout1 background-image pt-40 pb-10 pb-lg-70"
                style={{backgroundImage: 'url(' + backgroundImage + ')'}}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-lg-3 col-xl-3">
                            <div className="widget pt-0">
                                <div className="vs-widget-about">
                                    <img
                                        style={{maxWidth: '200px', marginBottom: '10px', position: 'relative', top: '-10px'}}
                                        src={Logo}
                                        alt="Logo"
                                    />
                                    <p className="contact-info mb-15">
                                        <i className="fal fa-map-marker-alt" />
                                        11090 Beograd, Srbija
                                    </p>
                                    <p className="contact-info mb-15">
                                        <i className="fal fa-envelope" />
                                        <a href="mailto:sara@stomacko.com">
                                            sara@stomacko.com
                                        </a>
                                    </p>
                                    {/*<p className="contact-info mb-15">*/}
                                    {/*    <i className="fab fa-instagram" />*/}
                                    {/*    <a href="https://www.instagram.com/stomacko_/">*/}
                                    {/*        Stomačko Instagram*/}
                                    {/*    </a>*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-5 col-xl-4 mt-4">
                            <div className="widget widget_nav_menu   ">
                                <h3 className="widget_title">&nbsp;</h3>
                                <div className="menu-all-pages-container">
                                    <div className="row">
                                        <div className="col-sm-6 col-xl-6">
                                            <ul className="menu">
                                                <li>
                                                    <Link to={DISCLAIMER_URLS.TERMS_AND_CONDTIONS}>
                                                        Uslovi korišćenja
                                                    </Link>
                                                    <Link to={DISCLAIMER_URLS.PRIVACY_POLICY}>
                                                        Pravila privatnosti
                                                    </Link>
                                                    {/*<Link to={DISCLAIMER_URLS.COOKIES}>*/}
                                                    {/*    Politika o kolačićima*/}
                                                    {/*</Link>*/}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6 col-xl-6">
                                            <ul className="menu">

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NewsletterFooter />
                    </div>
                </div>
            </div>
            <div className="footer-copyright top-light-border text-center bg-dark py-3 link-inherit">
                <div className="container">
                    <p className="text-white mb-0">
                        Copyright <i className="fal fa-copyright" /> {new Date().getFullYear()}{' '}
                        <Link to={MAIN_URLS.HOME}>Stomačko</Link>. All rights
                        reserved by{' '}
                        <a href="#">Stomačko doo</a>
                        .
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
