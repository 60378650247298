import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../store/user/selector";
import { getSavedRecipesAction } from "../../../../store/callculator/result";
import { CALCULATOR_URLS, MAIN_URLS, REGISTER_URLS } from "../../../../util/routes/urls";
import { Redirect } from "react-router";
import { GenericFormMessageScreen } from "../../../general/messages/GenericFormMessageScreen";
import WaitingResults from "../Results/WaitingResults";
import Divider from "../../General/DIvider";
import RecipeList from "../Results/RecipeList";
import IngredientListWrapper from "../Results/IngredientList";

export const CalculatorSaved = () => {
	const dispatch = useDispatch();

	const [redirect, setRedirect] = useState(null);
	// const [active, setActive] = useState(1);
	const [showNoResults, setShowNoResults] = useState(false);
	const [showNoLogin, setShowNoLogin] = useState(false);

	const result = useSelector((state) => state.calculatorResult.savedResults);
	const user = useSelector(selectUser);

	useEffect(() => {
		if (!result && user) {
			dispatch(getSavedRecipesAction());
		}
		if (!result && !user) {
			setRedirect(MAIN_URLS.HOME);
		}
		if (result && result.code && result.code === 'NO_RESULTS') {

			setShowNoResults(true);
		}
		if (result && result.code && result.code === 'NO_LOGIN') {

			setShowNoLogin(true);
		}
	}, [dispatch, result, user]);

	if (redirect) {
		return <Redirect to={redirect} />;
	}

	if (showNoResults) {
		return (
			<GenericFormMessageScreen
				message={'Nema aktivnih rezultata. Idite na kalkulator i sačuvajte rezultate koji vam se svidjaju'}
				linkTo={CALCULATOR_URLS.CALCULATOR}
				linkText={'Kalkulator'}
			/>
		);
	}

	if (showNoLogin) {
		return (
			<GenericFormMessageScreen
				message={'Niste ulogovani. Nakon što se ulogujete vratite se na ovu stranu.'}
				linkTo={REGISTER_URLS.LOGIN}
				linkText={'Login'}
			/>
		);
	}


	if (!result || !result.recipes) {
		return <WaitingResults />;
	}


	return (
		<section className="vs-food-box-wrapper food-box-layout1 position-relative link-inherit py-50 py-lg-50">
			<div className="container">
				<div className="food-menu-wrapper food-menu-style1 list-style-none text-center">
					<ul className="nav nav-tabs d-block border-0" role="tablist">
						<li><a  className="custom-toggle mask-style3 active" id="recipes-tab" data-toggle="tab"
							href="#recipes" role="tab" aria-controls="recipes" aria-selected="true">Recepti</a>
						</li>
						<li><a className="custom-toggle mask-style3" id="ingredients-tab" data-toggle="tab"
							href="#ingredients" role="tab" aria-controls="ingredients"
							aria-selected="false">Sastojci</a></li>
					</ul>
					<Divider />
				</div>

				<div className="tab-content result-page-container" id="foodTabContent">
					<div className="tab-pane active link-inherit" id="recipes" aria-labelledby="recipes-tab">
						<div className="row justify-content-center">
							<div className="col-xl-8 col-md-12">
								<RecipeList recipes={result.recipes} />
							</div>
						</div>
					</div>
					<div className="tab-pane " id="ingredients" aria-labelledby="ingredients-tab">
						<div className="row justify-content-center">
							<div className="col-xl-8 col-md-12">
								<IngredientListWrapper ingredients={result.ingredients} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CalculatorSaved;
