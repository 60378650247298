import React from 'react';

import Menu from 'components/pages/Menu/Menu';
import Header from 'components/pages/General/Header';
import ScrollToTop from 'components/pages/Menu/ScrollToTop';
import Footer from 'components/pages/Menu/Footer';
import CalculatorSavedPage from 'components/pages/Calculator/Saved/CalculatorSaved';

export const CalculatorSaved = () => {
	const inProgress = false;

	return (
		<>
			<Menu />
			<Header
				current={'Sačuvani Rezultati'}
				nomb={!inProgress}
			/>
			<CalculatorSavedPage />
			<ScrollToTop />
			<Footer />
		</>
	);
};
