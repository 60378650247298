import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logoutApi } from './api';

const initialState = {
    email: '',
    loggedIn: false,
    isGuest: false,
};

const logoutUser = createAsyncThunk('users/logout', async () => {
    const res = await logoutApi();
    return res.data;
});

export const userReducer = createSlice({
    name: 'USER:USER',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.email = action.payload.email;
            state.loggedIn = true;
            state.isGuest = false;
        },
        setGuestUser: (state) => {
            state.email = '';
            state.loggedIn = false;
            state.isGuest = true;
        },
        removeUser: (state) => {
            state.email = '';
            state.loggedIn = false;
            state.isGuest = true;
        },
    },
    extraReducers: {
        [logoutUser.fulfilled]: (state) => {
            state.email = '';
            state.loggedIn = false;
            state.isGuest = true;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setUser, removeUser, setGuestUser } = userReducer.actions;
export { logoutUser };

export default userReducer.reducer;
