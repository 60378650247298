import React, { useCallback, useEffect, useState } from 'react';
import { MAIN_URLS, replaceWildcards } from '../../../util/routes/urls';
import { Helmet } from 'react-helmet';
import { CLOUDFRONT_URL } from '../../../util/constants/global';
import { useDispatch } from 'react-redux';
import { changeOffsetAction, getRecipeListAction } from '../../../store/recipes/recipeList';
import { Redirect, useParams } from 'react-router-dom';
import ReactGA from "react-ga4";
import { logApi } from '../../../store/log/log.api';
// import { Redirect } from 'react-router';

export const difToName = [
    'Veoma lako za spremanje',
    'Lako za spremanje',
    'Srednje teško za spremanje',
    'Zahtevno za spremanje',
    // 'Veoma zahtevno za spremanje',
];

export const recipeDiff = [
    {
        'label': difToName[0],
        value: 1,
    },
    {
        'label': difToName[1],
        value: 2,
    },
    {
        'label': difToName[2],
        value: 3,
    },
    {
        'label': difToName[3],
        value: 4,
    },
    // {
    //     'label': difToName[4],
    //     value: 5,
    // },
];

export const pluralSr = (name, units) => {
    if (name === 'jaja') {
        if (units === 1) {
            return 'jaje';
        } else {
            return name;
        }
    }

    if (name === 'dan') {
        if (units === 1) {
            return 'dan';
        } else {
            return 'dana';
        }
    }

    return name;
};

export const calculateWidths = (recipe) => {
    return {
        cost: recipe.cost / 30,
        time: recipe.prepTime > 120 ? 100 : recipe.prepTime,
        cookTime: recipe.cookTime > 100 ? 100 :  recipe.cookTime,
        difficulty: recipe.difficulty === 1 ? 40 : recipe.difficulty * 20,
        days: recipe.daysEatable > 5 ? 100 : recipe.daysEatable * 20,
    };
}

const Recipe = ({ recipe, portions = 1 }) => {
    const dispatch = useDispatch();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [redirect, setRedirect] = useState(false);
    // const calculatorResults = useSelector((state) => state.calculatorResult);
    // const hasResults = calculatorResults.recipes.length > 0 || calculatorResults.result;
    const params = useParams();

    const hasPremijerCooperation = recipe?.partners === 'premijer';

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [recipe?.id]);

    useEffect(() => {
        if (hasPremijerCooperation) {
            // Send a custom event
            ReactGA.event({
                category: logApi.LOG_CATEGORY,
                action: logApi.LOG_ACTIONS.recipe,
                label: recipe?.name, // optional
                value: 99, // optional, must be a number
                nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
            });

            logApi.log(logApi.LOG_CATEGORY, logApi.LOG_ACTIONS.recipe, recipe?.name);
        }
    }, [hasPremijerCooperation])

    const goBack = () => {
        window.history.back();
        // setRedirect(backLink);
    };

    const onTagClick = (tag, type='tag') => {
        dispatch(changeOffsetAction(0));
        window.scrollTo({
            top: 100,
            left: 0,
            behavior: "smooth",
        });

        // if (tag === 'premier-čokolada') {
        //     setRedirect(replaceWildcards(MAIN_URLS.RECIPE_LIST_TAG, [tag]));
        //     return;
        // }

        if (type === 'tag') {
            dispatch(
                getRecipeListAction(
                    {
                        categoryId: -1,
                        limit: 12,
                        offset: 0,
                        days: undefined,
                        difficulty: undefined,
                        tags: [tag],
                    }
                )
            );
        } else {
            dispatch(
                getRecipeListAction(
                    {
                        categoryId: -1,
                        limit: 12,
                        offset: 0,
                        days: undefined,
                        difficulty: undefined,
                        ingredients: [tag],
                    }
                )
            );
        }

        setRedirect(replaceWildcards(MAIN_URLS.RECIPE_LIST, ['sve']));
    };

    const widths = calculateWidths(recipe);

    const isMobile = window.innerWidth < 991;

    const hasPinkTagCb = useCallback(() => {
        let hasTag = false;
        if (recipe?.tags) {
            hasTag = recipe?.tags.find((tag) => tag === 'pink')
        }
        return hasTag;
    }, [recipe?.tags]);
    const hasPinkTag = hasPinkTagCb();

    let numberOfPortions = recipe.portions ?? portions;
    let priceForPortions = recipe.cost;
    if (recipe?.category.indexOf(1) !== -1) {
        priceForPortions = (priceForPortions * portions);
    }

    // Special case for pink recipes
    // TODO handle this better
    if (params?.number &&  recipe.portions) {
        priceForPortions = (( recipe.cost / recipe.portions) * portions);
        numberOfPortions = portions;
    }

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <section className="food-details-wrapper food-details-layout1 mb-40">
            <RecipeHelmet recipe={recipe} />

            <div className="container radius-10" id="recipe-container" style={{opacity: 0}}>
                <div className="row">
                    <div className="col-lg-7 pt-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="food-details-header position-relative mb-25 mb-lg-30 radius-10">
                                    <div className="food-image image-scale-hover radius-10" style={{ aspectRatio: '3 / 2' }}>
                                        {!imageLoaded && (
                                            <div className="loading-skeleton" style={{width: '100%', height: '100%'}}>
                                            </div>
                                        )}
                                        <img
                                            onLoad={() => setImageLoaded(true)}
                                            src={
                                                isMobile ? CLOUDFRONT_URL + 'medium/' + recipe._id + ' Medium.jpeg' : CLOUDFRONT_URL + 'large/' + recipe._id + ' Large.jpeg'
                                            }
                                            style={{
                                                display: imageLoaded ? 'block' : 'none',
                                            }}
                                            alt='Food'
                                            className="w-100"
                                            async
                                        />
                                        {hasPinkTag && (
                                            <div
                                                className="recipe-ribbon"
                                                data-toggle="tooltip"
                                                title="Ovaj recept predstavlja deo saradnje sajta Stomačko i organizacije Evropa dona Srbija - srpskog foruma protiv raka dojke. Povod je peta trka i šetnja za izlečenje žena obolelih od raka dojke Race for the cure koja se organizuje u subotu, 16. septembra 2023, u Beogradu na Adi Ciganliji. Izvor recepta se nalazi na sajtu Stanford Medicine Health Care. Molimo vas da recepte shvatite na nivou preporuke za način pripreme i konzumiranje određenih namirnica kao prevenciju bolesti, ali i tokom i nakon lečenja.

                                                      *Neophodno je da se u vezi sa svojom ishranom posavetujete sa svojim lekarom i/ili nutricionistom."
                                            >
                                                <i
                                                    className="fa fa-ribbon"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <h2 className="">Priprema</h2>
                                <div>
                                {recipe.recipe
                                    .split('\n')
                                    .map((recipeP, index) => {
                                        return <p key={index}>{recipeP}</p>;
                                    })}

                                    {hasPinkTag && (
                                        <div
                                            style={{
                                                marginBottom: '16px'
                                            }}
                                        >
                                            <p>
                                            <i
                                                style={{
                                                    color: 'deeppink',
                                                    marginRight: '8px'
                                                }}
                                                className="fa fa-ribbon"
                                            />
                                            Ovaj recept predstavlja deo saradnje sajta Stomačko i organizacije Evropa dona Srbija - srpskog foruma protiv raka dojke. Povod je peta trka i šetnja za izlečenje žena obolelih od raka dojke Race for the cure koja se organizuje u subotu, 16. septembra 2023, u Beogradu na Adi Ciganliji. Izvor recepta se nalazi na sajtu Stanford Medicine Health Care. Molimo vas da recepte shvatite na nivou preporuke za način pripreme i konzumiranje određenih namirnica kao prevenciju bolesti, ali i tokom i nakon lečenja.
                                            </p>
                                            <b>Neophodno je da se u vezi sa svojom ishranom posavetujete sa svojim lekarom i/ili nutricionistom.</b>
                                        </div>
                                    )}
                                </div>

                                {/*{hasPremijerCooperation && (*/}
                                {/*    <div*/}
                                {/*        onClick={() => {*/}
                                {/*            // Send a custom event*/}
                                {/*            ReactGA.event({*/}
                                {/*                category: logApi.LOG_CATEGORY,*/}
                                {/*                action: logApi.LOG_ACTIONS.product,*/}
                                {/*                label: recipe?.partnerText, // optional*/}
                                {/*                value: 99, // optional, must be a number*/}
                                {/*                nonInteraction: true, // optional, true/false*/}
                                {/*                transport: "xhr", // optional, beacon/xhr/image*/}
                                {/*            });*/}

                                {/*            logApi.log(logApi.LOG_CATEGORY, logApi.LOG_ACTIONS.product, recipe?.partnerText);*/}

                                {/*            window.location = recipe?.partnerText*/}
                                {/*        }}*/}
                                {/*        style={{*/}
                                {/*            marginBottom: '20px',*/}
                                {/*            border: isMobile ? '2px solid black' : '4px solid black',*/}
                                {/*            paddingTop: '20px',*/}
                                {/*            paddingBottom: '20px',*/}
                                {/*            borderRadius: '6px',*/}
                                {/*            cursor: 'pointer',*/}
                                {/*            // background: 'url(https://scontent.fbeg4-1.fna.fbcdn.net/v/t39.30808-6/326332624_1280292235880028_6340647052746099236_n.jpg?_nc_cat=109&cb=99be929b-8d691acd&ccb=1-7&_nc_sid=efb6e6&_nc_ohc=dgAnUtokQtIAX_Nu1d4&_nc_ht=scontent.fbeg4-1.fna&oh=00_AfC_Keo4LpBsM_x4FDkPuWuSo4kf7fvCSUqrsnMJa54Gjw&oe=65D7703A)',*/}
                                {/*            // backgroundRepeat: 'no-repeat',*/}
                                {/*            // backgroundSize: 'cover'*/}
                                {/*        }}>*/}
                                {/*        /!*<div style={{*!/*/}
                                {/*        /!*    display: 'flex',*!/*/}
                                {/*        /!*    justifyContent: 'center'*!/*/}
                                {/*        /!*}}>*!/*/}
                                {/*        /!*    <div style={{*!/*/}
                                {/*        /!*            position: 'relative',*!/*/}
                                {/*        /!*            // top: isMobile ? '140px' : '240px',*!/*/}
                                {/*        /!*            // border: '2px solid #ddd',*!/*/}
                                {/*        /!*            background: 'white',*!/*/}
                                {/*        /!*            padding: '10px',*!/*/}
                                {/*        /!*            color: '#515151',*!/*/}
                                {/*        /!*            borderRadius: '3px',*!/*/}
                                {/*        /!*            // boxShadow: '3px 2px 1px #ddd',*!/*/}
                                {/*        /!*            fontSize: '21px',*!/*/}
                                {/*        /!*            fontFamily: 'Futura',*!/*/}
                                {/*        /!*            fontWeight: '600'*!/*/}
                                {/*        /!*        }}>*!/*/}
                                {/*        /!*        Klik da vidite koji Premier proizvod preporučujemo nakon ovog ručka!*!/*/}
                                {/*        /!*    </div>*!/*/}
                                {/*        /!*</div>*!/*/}
                                {/*        <div*/}
                                {/*            style={{*/}
                                {/*                position: 'relative',*/}
                                {/*                height: isMobile ? '200px' : '320px',*/}
                                {/*                display: 'flex',*/}
                                {/*                justifyContent: 'center'*/}
                                {/*            }}*/}
                                {/*        >*/}

                                {/*            <img*/}
                                {/*                alt={"premier cokolade"}*/}
                                {/*                        style={{*/}
                                {/*                           height: '100%'*/}
                                {/*                        }}*/}
                                {/*                        // src={"https://premiercokolada.rs/wp-content/uploads/2020/06/tamna-cokolada-sa-zasladjivacem-768x768.jpg"}*/}
                                {/*                        src={CLOUDFRONT_URL + 'collab/premier/test/' + recipe?.partnerLink + '.jpeg'}*/}
                                {/*                // src={"https://premiercokolada.rs/wp-content/uploads/2024/02/IMG-1652-600x600.jpg"}*/}
                                {/*                    />*/}
                                {/*            <div style={{*/}
                                {/*                position: 'absolute',*/}
                                {/*                display: 'flex',*/}
                                {/*                justifyContent: 'center'*/}
                                {/*            }}>*/}
                                {/*                <div style={{*/}
                                {/*                    position: 'relative',*/}
                                {/*                    textAlign: 'center',*/}
                                {/*                    top: isMobile ? '140px' : '270px',*/}
                                {/*                    border: '1px solid black',*/}
                                {/*                    padding: isMobile ? '5px' : '10px',*/}
                                {/*                    width: isMobile ? 'calc(100% - 20px)' : '100%',*/}
                                {/*                    borderRadius: '5px',*/}
                                {/*                    // boxShadow: '3px 2px 1px #ddd',*/}
                                {/*                    fontSize: '18px',*/}
                                {/*                    fontFamily: "Futura",*/}
                                {/*                    fontWeight: '600',*/}
                                {/*                    // background: 'black',*/}
                                {/*                    // background: 'linear-gradient(184deg,rgba(0,148,50,1) 7%, white 8%, white 90%, rgba(0,148,50,1) 91%)',*/}
                                {/*                    background: 'white',*/}
                                {/*                    color: 'black',*/}
                                {/*                }}>*/}
                                {/*                    Klik da vidite koju <span style={{*/}
                                {/*                    fontSize: '18px',*/}
                                {/*                    fontWeight: '700',*/}
                                {/*                    color: 'black'*/}
                                {/*                }}>Premier</span> postasticu preporučujemo nakon ovog ručka!*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}

                                {/*    </div>*/}
                                {/*)}*/}
                                <button
                                    id="recipe-back-button"
                                    type="submit"
                                    className="vs-btn style3 radius-10 text-center"
                                    onClick={goBack}
                                    style={isMobile ? {display: 'none'} : {}}
                                    aria-label="Nazad"
                                >
                                    Nazad
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 pt-3">
                        <div className="food-details-area">
                            <div className="food-details-content">
                                <div className="radius-10 box bg-smoke mb-30 px-xl-40 px-sm-30 px-15 py-xl-40 py-sm-30 py-15">
                                    <div className="d-flex justify-content-between">
                                        <h3 className="">
                                            Sastojci
                                            <span
                                                style={{
                                                    fontSize: '15px'
                                                }}
                                            >
                                            <span className="ml-2 color-green text-bold">{numberOfPortions}</span> porcija
                                        </span>
                                        </h3>

                                        <div className="mt-1">
                                            <div
                                                style={{
                                                    fontSize: '15px'
                                                }}
                                                className="d-block"
                                            >
                                                <span
                                                    style={{
                                                        background: '#009432',
                                                        color: 'white',
                                                        padding: '2px 12px',
                                                        borderRadius: '20px'
                                                    }}
                                                    className="mr-1 food-price d-inline-block"
                                                >
                                                    {(priceForPortions).toFixed(2)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="vs-list-style1">
                                        {recipe.ingredients.map(
                                            (ingredient, index) => {
                                                const dbIngredient =
                                                    recipe.dbIngredients.find(
                                                        (dbIngredient) =>
                                                            dbIngredient._id ===
                                                            ingredient._id
                                                    );
                                                if (
                                                    ingredient.unit !== 'units'
                                                ) {
                                                    return (
                                                        <li key={index}>
                                                            {pluralSr(
                                                                dbIngredient.name,
                                                                ingredient.amount * portions
                                                            )}{' '}
                                                            {ingredient.amount * portions}{' '}
                                                            {ingredient.unit}
                                                        </li>
                                                    );
                                                } else {
                                                    return (
                                                        <li key={index}>
                                                            {ingredient.amount * portions}{' '}
                                                            {pluralSr(
                                                                dbIngredient.name,
                                                                ingredient.amount * portions
                                                            )}{' '}
                                                        </li>
                                                    );
                                                }
                                            }
                                        )}
                                    </ul>
                                </div>
                            </div>

                            <blockquote className="vs-block-quote-style1 my-25 my-lg-25 radius-10">
                                {recipe.hint}
                                <span className="quote-author text-theme">
                                    {recipe?.author ?? 'Sara Bojanić'}
                                </span>
                            </blockquote>

                            <div className="mb-20"
                                style={{
                                    display: 'flex',
                                    gap: '5px',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {recipe?.tags && recipe.tags.map((tag) => {
                                    if (tag === 'premier-čokolada') {
                                        return null;
                                    }

                                    return (
                                        <span
                                            key={tag}
                                            onClick={() => {
                                                onTagClick(tag);
                                            }}
                                            className="recipe-tag radius-10 no-break d-inline-block"
                                        >
                                            {tag}
                                        </span>
                                    );
                                })}

                                {recipe?.ingredients.map((ingredient) => {
                                    const dbIngredient =
                                        recipe?.dbIngredients.find(
                                            (dbIngredient) =>
                                                dbIngredient._id ===
                                                ingredient._id
                                        );

                                    if (!dbIngredient) {
                                        return null;
                                    }

                                    return (
                                        <span
                                            key={dbIngredient._id}
                                            onClick={() => {
                                                onTagClick(dbIngredient._id, 'ingredient');
                                            }}
                                            className="recipe-tag radius-10 no-break d-inline-block"
                                        >
                                            {dbIngredient.name}
                                        </span>
                                    );
                                })}
                            </div>

                            <div className="vs-progress-wrapper vs-progress-layout1 ">
                                {/*<ProgressBar*/}
                                {/*    name="Cena"*/}
                                {/*    icon="fa-money-bill"*/}
                                {/*    width={widths.cost}*/}
                                {/*    amount={roundDecimals(recipe.cost) + ' RSD'}*/}
                                {/*/>*/}
                                <ProgressBar
                                    name="Težina pripreme"
                                    icon="fa-weight"
                                    width={widths.difficulty}
                                    amount={difToName[recipe.difficulty - 1]}
                                />
                                <ProgressBar
                                    name="Potrebno vreme za spremanje"
                                    icon="fa-clock"
                                    width={widths.time}
                                    amount={`Potrebno za pripremu: ${recipe.prepTime} min`}
                                />
                                {recipe.cookTime && recipe.cookTime !== 0 && (
                                    <ProgressBar
                                        name="Potrebno vreme za kuvanje"
                                        icon="fa-clock"
                                        width={widths.cookTime}
                                        amount={`Potrebno za kuvanje: ${recipe.cookTime} min`}
                                    />
                                )}
                                <ProgressBar
                                    name="Može da stoji dana"
                                    icon="fa-calendar"
                                    width={widths.days}
                                    amount={`Upotrebljivo: ${recipe.daysEatable} ${pluralSr('dan', recipe.daysEatable)}`}
                                    allThemeColor={true}
                                />
                            </div>
                        </div>
                        <button
                            id="recipe-back-two-button"
                            type="submit"
                            className="vs-btn style3 mt-2 radius-10"
                            onClick={goBack}
                            style={!isMobile ? {display: 'none'} : {}}
                            aria-label="Nazad"
                        >
                            Nazad
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export const ProgressBar = ({ width, name, amount, icon, allThemeColor = false }) => {
    let bgClass = width < 41 ? 'theme' : width < 67 ? 'yellow' : 'red';
    if (allThemeColor) {
        bgClass = 'theme';
    }
    return (
        <div className="vs-progress-bar mb-25 " style={{height: '20px'}}>
            <p className="progress-title mb-0 text-title" title={name}>
                <i className={'fa ' + icon + ' text-' + bgClass} />
            </p>
            <div className="bar-progress radius-10" style={{height: '8px'}}>
                <div
                    className={'progress-value radius-10 bg-' + bgClass + ' text-' + name}
                    data-value={width}
                    style={{ width: width + '%' }}
                >
                    <span className={'progress-text text-' + bgClass} style={{minWidth: '250px', left: 30}}>
                        <i className="counter">{amount}</i>
                    </span>
                </div>
            </div>
        </div>
    );
};

const RecipeHelmet = ({recipe}) => {
    if (!recipe || !recipe.name) {
        return null;
    }

    let keywords = recipe.name;
    if (recipe.keywords) {
        keywords += ', ' + recipe.keywords;
    }

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Stomačko - {recipe.name}</title>
            <meta property='title' content={`Stomačko - ${recipe.name}`} />
            <meta property='description' content={recipe.hint} />
            <meta property="og:site_name" content = "Stomačko" />

            <meta property='og:title' content={`Stomačko - ${recipe.name}`} />
            <meta property="og:type" content='website'/>
            <meta property='og:description' content={recipe.hint} />

            <meta property='og:image' content={CLOUDFRONT_URL + 'small/' + recipe._id + ' Small.jpeg'} />
            <meta property='og:image:type' content="image/jpeg" />
            <meta property='og:image:width' content="320" />
            <meta property='og:image:height' content="213" />
            <meta property='og:url' content={`https://stomacko.com/recept/${recipe.slug}`} />

            <meta property='twitter:title' content={`Stomačko - ${recipe.name}`} />
            <meta property='twitter:image' content={CLOUDFRONT_URL + 'small/' + recipe._id + ' Small.jpeg'} />
            <meta property='twitter:url' content={`https://stomacko.com/recept/${recipe.slug}`} />

            <meta name='keywords' content={keywords} />
        </Helmet>
    )
}

export default Recipe;
