import React from 'react';

const InputWrapper = (props) => {
    const bootstrapClass = props.bootstrapClass
        ? props.bootstrapClass
        : 'col-lg-3';
    return (
        <div
            style={{
                boxShadow: '1px 1px 1px 1px rgb(0 0 0 / 16%)',
                borderRadius: '10px',
                paddingRight: 0,
                paddingLeft: 0,
            }}
            className={'form-group ' + bootstrapClass}>{props.children}</div>
    );
};

export default InputWrapper;
