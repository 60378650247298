import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RECIPE_URLS, replaceWildcards, WEEKLY_URLS } from '../../../util/routes/urls';
import { useDispatch, useSelector } from 'react-redux';
import { weeklySelector } from '../../../store/weekly/selector';
import { getWeeklyDayAction } from '../../../store/weekly/weekly';
import { dayOfWeekLong, monthNamesSr } from '../../../util/helpers/date';
import { calculateWidths, difToName, pluralSr, ProgressBar } from '../Recipe/Recipe';
import { CLOUDFRONT_URL } from '../../../util/constants/global';

const WeeklyRecipesV2 = ({isNewHome = false}) => {
    const dispatch = useDispatch();

    const [recipe, setRecipe] = useState(undefined);

    const today = useSelector(weeklySelector.selectWeeklyToday);
    const hasToday = useSelector(weeklySelector.selectWeeklyHasToday);

    React.useEffect(() => {
        if (!today && hasToday !== false) {
            dispatch(getWeeklyDayAction());
        }
    }, []);

    useEffect(() => {
        if (today) {
            setRecipe(today?.day);
        }
    }, [today])

    if (!today || !recipe) {
        return null;
    }

    const momentInstance = window.moment(today?.startDate);
    const momentInstanceEnd = window.moment(today?.startDate).add(7, 'day');
    const isSameMonth = momentInstance && momentInstance.isSame(momentInstanceEnd, 'month');

    const startDate = window.moment(today?.startDate).format('DD');
    const endDate = window.moment(today?.startDate).add(7, 'day').format('DD');

    const widths = calculateWidths(today?.day);

    const isMobile = window.innerWidth < 991;

    return (
        <section
            className="vs-about-wrapper vs-about-layout2 position-relative" id="daily"
            style={isMobile ? {
                marginBottom: '65px',
                paddingTop: '40px',
            }: {
                paddingTop: '90px',
            }}
        >
            <div className="container-xl z-index-common">
                <div
                    style={isNewHome ? {
                        background: 'white',
                        borderRadius: '12px',
                        paddingBottom: '90px',
                        paddingTop: '30px',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        boxShadow: '2px 2px 5px 0px rgba(0,112,75,0.5)'
                    } : {}}
                >
                <div className="row gutters-50">
                    <div className="col-12 text-center">
                        {isSameMonth ? (
                        <span className="text-font3 text-theme h3 d-block" style={{
                            color: '#009432'
                        }}>
                            {startDate} - {endDate} { monthNamesSr[momentInstance.format('M') - 1]}
                        </span>
                        ) : (
                            <span className="text-font3 text-theme h3 d-block" style={{
                                color: '#009432'
                            }}>
                            {startDate} { monthNamesSr[momentInstance.format('M') - 1]} - {endDate} { monthNamesSr[momentInstanceEnd.format('M') - 1]}
                            </span>
                        )}
                        <h2 className="about-title h1 ">
                            Nedeljni meni
                        </h2>
                        <p style={{textAlign: 'left'}}>
                            {today.description}
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <div className="about-image-box2 position-relative">
                            <div className="row mb-4 mb-lg-0">
                                <div className="col-sm-6 col-lg-12 position-static">
                                    <div className="about-image1 radius-12 food-box-layout3">
                                        <img
                                            className="radius-12"
                                            src={`${CLOUDFRONT_URL}medium/${recipe?.id}%20Medium.jpeg`}
                                            alt={today?.day?.name}
                                        />
                                        <span className="food-price" style={{
                                            top: '30px'
                                        }}>
                                            {dayOfWeekLong[window.moment().weekday()]}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content ">
                            <h3 className="about-subtitle text-theme text-font3 mb-25">
                                <Link
                                    style={{
                                        color: '#009432',
                                    }}
                                    onClick={() => {
                                        window.scrollTo(0, 200);
                                    }}
                                    to={replaceWildcards(RECIPE_URLS.RECIPE, [today?.day?.slug])}
                                    >
                                    {today?.day?.name}
                                </Link>
                            </h3>
                            <p className="about-text mb-xl-35">
                                {today?.day?.desc ? today?.day?.desc.slice(0, 215) : ''}
                                <Link
                                    style={{
                                        color: '#009432',
                                        marginLeft: '12px'
                                    }}
                                    onClick={() => {
                                        window.scrollTo(0, 200);
                                    }}
                                    to={replaceWildcards(RECIPE_URLS.RECIPE, [today?.day?.slug])}
                                >
                                    Procitajte jos
                                </Link>
                            </p>

                            <div>

                                <div className="vs-progress-wrapper vs-progress-layout1 ">
                                    <ProgressBar
                                        name="Težina pripreme"
                                        icon="fa-weight"
                                        width={widths.difficulty}
                                        amount={difToName[recipe.difficulty - 1]}
                                    />
                                    <ProgressBar
                                        name="Potrebno vreme za spremanje"
                                        icon="fa-clock"
                                        width={widths.time}
                                        amount={`Potrebno za pripremu: ${recipe.prepTime} min`}
                                    />
                                    {recipe.cookTime && recipe.cookTime !== 0 && (
                                        <ProgressBar
                                            name="Potrebno vreme za kuvanje"
                                            icon="fa-clock"
                                            width={widths.cookTime}
                                            amount={`Potrebno za kuvanje: ${recipe.cookTime} min`}
                                        />
                                    )}
                                    <ProgressBar
                                        name="Može da stoji dana"
                                        icon="fa-calendar"
                                        width={widths.days}
                                        amount={`Upotrebljivo: ${recipe.daysEatable} ${pluralSr('dan', recipe.daysEatable)}`}
                                        allThemeColor={true}
                                    />

                                </div>

                                <div className="vs-btn-group mt-30 text-center" style={{
                                    position: 'relative',
                                    top: '40px'
                                }}>
                                    <Link
                                        onClick={() => {
                                            window.scrollTo(0, 200);
                                        }}
                                        className="vs-btn mask-style1"
                                        to={replaceWildcards(WEEKLY_URLS.RECIPE)}
                                        style={{borderRadius: '12px'}}
                                    >
                                        Svi nedeljni recepti
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
};

export default WeeklyRecipesV2;