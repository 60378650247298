import React, { useState } from 'react';
import { EMPTY_STRING } from '../Login/Login';
import { validateEmail } from '../../../util/helpers/validators';
import Api from '../../../main/Api';
import { SERVER_URLS } from '../../../util/routes/serverUrls';
import Loader from 'react-loader-spinner';

const NewsletterFooter = () => {
    const [form, setForm] = useState({ email: '' });
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [success, setSuccess] = useState(false);

    const onFormChange = (e) => {
        if (errorMessage !== EMPTY_STRING) {
            setErrorMessage(EMPTY_STRING);
        }
        if (emailInvalid) {
            setEmailInvalid(false);
        }

        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const applyForNewsletter = () => {
        if (!validateEmail(form.email)) {
            setEmailInvalid('Unestite ispravnu email adresu');
            return;
        }

        setInProgress(true);

        Api.post(SERVER_URLS.POST_NEWSLETTER, form)
            .then(() => {
                setSuccess(true);
            })
            .catch(() => {
                const message = 'Došlo je do greške, molimo vas pokušajte kasnije.';
                setErrorMessage(message);
                setInProgress(false);
            });
    };

    const isMobile = window.innerWidth < 1200;

    if (isMobile) {
        return null;
    }

    return (
        <div className="col-lg-4 col-xl-4 offset-xl-1">
            <div className="widget widget_newsletter">
                <h4 className="widget_title">Newsletter</h4>
                {!success ? (<div className="newsletter-form">
                    <span className="form-icon">
                        <i className="fal fa-envelope text-theme" />
                    </span>
                    <input
                        style={{width: '70%'}}
                        className="form-control mb-20 radius-10"
                        type="email"
                        name="email"
                        onChange={onFormChange}
                        placeholder="Tvoja email adresa"
                        required=""
                    />
                    {emailInvalid && (
                        <div className="text-red">
                            {emailInvalid}
                        </div>
                    )}
                    <button
                        onClick={applyForNewsletter}
                        style={{width: '70%'}}
                        type="submit"
                        className="vs-btn style1 radius-10"
                    >
                        {!inProgress && <span>Prijavi se</span>}
                        {inProgress && (
                            <Loader
                                type={'TailSpin'}
                                height={'20px'}
                                color={'green'}
                            />
                        )}
                    </button>
                </div>)
                    : (
                    <div style={{fontSize: '24px', color: "white"}} className="py-20 text-semibold">
                        Uspešno ste se prijavili!
                    </div>
                    )}
            </div>
        </div>
    );
};

export default NewsletterFooter;