// Taken from https://stackoverflow.com/a/46181
export function validateEmail(email) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function validatePassword(pw) {
	// return /[A-Z]/       .test(pw) &&
	//     /[a-z]/       .test(pw) &&
	// /[0-9]/       .test(pw) &&
	// /[^A-Za-z0-9]/.test(pw) &&
	return pw.length >= 6;
}

export function validateRepeatedPassword(password, repeatedPassword) {
	return password === repeatedPassword;
}

export function validateContactForm(text) {
	return text.length > 20;
}