import React, { useEffect, useState } from 'react';
import { MAIN_URLS } from '../../util/routes/urls';
import { Link } from 'react-router-dom';
import { CLOUDFRONT_URL } from '../../util/constants/global';

const Logo = CLOUDFRONT_URL + 'assets/logo/logov2.png';

const RegisterLayout = ({
    submitRegister,
    isRegister,
    form,
    image,
    leftColumn = "col-lg-7",
    rightColumn = "col-lg-5",
    rightColumnMinHeight: minHeight = '300px',
    children
}) => {

    return (
        <div className="signup-wrapper text-center">
            <div className="" style={{margin: 0}}>
                <div className="row justify-content-between" style={{margin: 0, height: '100vh'}}>
                    <div
                        className={leftColumn}
                        style={{
                            backgroundImage:    'url(' + image + ')',
                            backgroundSize:     'cover',
                            backgroundRepeat:   'no-repeat',
                            backgroundPosition: 'top center',
                            paddingLeft: '50px'
                        }}
                    >
                        {isRegister && <div
                            style={{
                                display: 'flex',
                                paddingTop: '60px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <div
                                className="text-font3"
                                style={{
                                    color: 'white',
                                    fontSize: '32px',
                                    marginBottom: '40px'
                                }}
                            >
                                Dobrodošli
                            </div>

                            <div
                                className="text-font3"
                                style={{
                                    color: 'white',
                                    fontSize: '40px',
                                    width: '500px',
                                    textAlign: 'left'
                                }}
                            >
                                &nbsp;{form.email}&nbsp;
                            </div>
                        </div>
                        }
                    </div>
                    <div className={rightColumn}>
                        <div style={{
                            marginLeft: '40px',
                            marginTop: '40px',
                        }}>
                            <Link to={MAIN_URLS.HOME}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <i
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        fontSize: '24px',
                                        color: '#009432',
                                        marginRight: '8px'
                                    }}
                                    className="far fa-arrow-left">
                                </i>
                                <span className="text-theme" style={{fontSize: '18px'}}>
                                    Početna
                                </span>
                            </Link>
                        </div>

                        <form
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                height: `calc(100vh - 70px)`,
                                minHeight: minHeight,
                            }}
                            className="signup-form px-40"
                            onSubmit={submitRegister}
                        >
                            <div>
                                <Link to={MAIN_URLS.HOME}>
                                    <img
                                        id="form-logo"
                                        src={Logo}
                                        alt="Logo"
                                        style={{ height: '120px', marginBottom: '60px' }}
                                    />
                                </Link>
                                {children}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterLayout;
