import React from 'react';

const WaitingResults = () => {
    return (
        <>
            <h1 className="calculation">Molimo vas sačekajte, rezultati se krčkaju...</h1>
            <div id="calc-cooking" className="mobile-left-100">
                <div className="calc-bubble" />
                <div className="calc-bubble" />
                <div className="calc-bubble" />
                <div className="calc-bubble" />
                <div className="calc-bubble" />
                <div id="calc-area">
                    <div id="calc-sides">
                        <div id="calc-pan" />
                        <div id="calc-handle" />
                    </div>
                    <div id="calc-pancake">
                        <div id="calc-pastry" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default WaitingResults;
