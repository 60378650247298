const LOGGED_IN = 'logged-in';

export const lsSaveIsLoggedIn = () => {
    window.localStorage.setItem(LOGGED_IN, 'true');
}

export const lsGetIsLoggedIn = () => {
    return window.localStorage.getItem(LOGGED_IN);
}

export const lsClearIsLoggedIn = () => {
    window.localStorage.removeItem(LOGGED_IN);
}