import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { weeklySelector } from '../store/weekly/selector';
import { getWeeklyWeekAction } from '../store/weekly/weekly';
import Menu from '../components/pages/Menu/Menu';
import Header from '../components/pages/General/Header';
import Weekly from '../components/pages/Weekly/Weekly';
import ScrollToTop from '../components/pages/Menu/ScrollToTop';
import Footer from '../components/pages/Menu/Footer';

const WeeklyPage = () => {
    const dispatch = useDispatch();

    const inProgress = useSelector(weeklySelector.selectWeeklyInProgress);
    const hasWeekly = useSelector(weeklySelector.selectWeeklyHasToday);
    const weekly = useSelector(weeklySelector.selectWeeklyWeek);

    React.useEffect(() => {
        if (!weekly && hasWeekly !== false) {
            dispatch(getWeeklyWeekAction());
        }
    }, []);

    return (
        <>
            <WeeklyHelmet />

            <Menu />
            <Header
                current="Nedeljni recepti"
            />
            <Weekly
                weekly={weekly}
                inProgress={inProgress}
                hasWeekly={hasWeekly}
            />
            <ScrollToTop />
            <Footer />
        </>
    );
};

const WeeklyHelmet = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta property="og:site_name" content = "Stomačko" />
            <meta property="og:type" content='website'/>

            <title>Stomačko - Nedeljni recepti</title>

            <meta property='title' content="Stomačko - Nedeljni recepti" />
            <meta property='twitter:title' content="Stomačko - Nedeljni recepti" />
            <meta property='og:title' content="Stomačko - Nedeljni recepti" />

            <meta property='description' content={`Stomačko nedeljni recepti`} />
            <meta property='og:description' content={`Stomačko nedeljni recepti`} />

            <meta property="og:image" content="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/assets/public/og-image.png" />
            <meta property='twitter:image' content="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/assets/public/og-image.png" />

            <meta property="og:image:width" content="378" />
            <meta property="og:image:height" content="156" />

            <meta property='og:url' content={`https://stomacko.com/nedeljni-recepti`} />
            <meta property='twitter:url' content={`https://stomacko.com/nedeljni-recepti`} />

            <meta name='keywords' content="Nedeljni recepti, nedeljne preporuke" />
        </Helmet>
    );

}


export default WeeklyPage;