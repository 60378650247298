import { createSlice } from '@reduxjs/toolkit';
import { getDaysArrayDMY, getDateDMY } from 'util/helpers/date';
import { createChosenParamsDefault } from 'util/helpers/details';
import { MEAL_ICONS, MEALS } from 'util/constants/meals';

const meals = MEALS.map((meal, index) => {
    return { value: meal, active: true, icon: MEAL_ICONS[index] };
});
const today = new Date();
const dates = {
    today: today.getTime(),
    nextWeek: new Date().setDate(today.getDate() + 6),
    nextTwoWeeks: new Date().setDate(today.getDate() + 13),
    next30Days: new Date().setDate(today.getDate() + 30),
};

const dateRange = getDaysArrayDMY(dates.today, dates.next30Days);
const datesFormObject = createChosenParamsDefault(dateRange, 2, MEALS);

const initialState = {
    money: 7000, // Part of the form and needed for calculation
    numberOfPeople: 2, // Part of the form and needed for calculation
    dateRangeStart: getDateDMY(dates.today), // Part of the form
    dateRangeEnd: getDateDMY(dates.nextWeek), // Part of the form
    meals, // Part of the form
    dates,
    datesFormObject: datesFormObject,
    category: {
        meso: false,
        riba: false,
        posno: false,
        // prolece: false,
        // leto: false,
        pink: false,
    }
};

export const calculatorForm = createSlice({
    name: 'CALCULATOR:FORM',
    initialState,
    reducers: {
        setMoney: (state, action) => {
            state.money = action.payload;
        },
        setNumberOfPeople: (state, action) => {
            state.numberOfPeople = action.payload;
            for (let i = 0; i < state.datesFormObject.length; i++) {
                MEALS.forEach((meal) => {
                    state.datesFormObject[i][meal].numberOfPeople =
                        action.payload;
                });
            }
        },
        setMealOnOff: (state, action) => {
            const newActiveStatus = !state.meals[action.payload].active;
            const mealName = state.meals[action.payload].value;
            // Set meal on off in dates
            for (let i = 0; i < state.datesFormObject.length; i++) {
                state.datesFormObject[i][mealName].prepMeal = newActiveStatus;
            }
            // Set meal on off in global
            state.meals[action.payload].active = newActiveStatus;
        },
        setDateRange: (state, action) => {
            // Set daterange needed for form
            state.dateRangeStart = action.payload.start;
            state.dateRangeEnd = action.payload.end;

            // Set activity on each individual date
            let inRange = false;
            for (let i = 0; i < state.datesFormObject.length; i++) {
                const date = state.datesFormObject[i].date;
                // Detected start of the range
                if (
                    date[0] === action.payload.start[0] &&
                    date[1] === action.payload.start[1]
                ) {
                    inRange = true;
                }
                // Set new activity
                state.datesFormObject[i].active = inRange;
                // Detect end of the range
                if (
                    date[0] === action.payload.end[0] &&
                    date[1] === action.payload.end[1]
                ) {
                    inRange = false;
                }
            }
        },
        categoryChange: (state, action) => {
            state.category[action.payload] = !state.category[action.payload];
        },
        // day, month
        onDayOnOff: (state, action) => {
            for (let i = 0; i < state.datesFormObject.length; i++) {
                const date = state.datesFormObject[i].date;
                if (
                    date[0] === action.payload.day &&
                    date[1] === action.payload.month
                ) {
                    // Set prep on the day param
                    state.datesFormObject[i].prepOnTheDay =
                        !state.datesFormObject[i].prepOnTheDay;
                }
            }
        },
        // day, month, value
        onNumberOfPeopleDayChange: (state, action) => {
            for (let i = 0; i < state.datesFormObject.length; i++) {
                const date = state.datesFormObject[i].date;
                if (
                    date[0] === action.payload.day &&
                    date[1] === action.payload.month
                ) {
                    MEALS.forEach((meal) => {
                        state.datesFormObject[i][meal].numberOfPeople =
                            action.payload.value;
                    });
                }
            }
        },
        // day, month, meal, value
        onNumberOfPeopleOnMeal: (state, action) => {
            for (let i = 0; i < state.datesFormObject.length; i++) {
                const date = state.datesFormObject[i].date;
                if (
                    date[0] === action.payload.day &&
                    date[1] === action.payload.month
                ) {
                    state.datesFormObject[i][
                        action.payload.meal
                    ].numberOfPeople = action.payload.value;
                }
            }
        },
        // day, meal
        onMealOnDayOnOff: (state, action) => {
            for (let i = 0; i < state.datesFormObject.length; i++) {
                const date = state.datesFormObject[i].date;
                if (
                    date[0] === action.payload.day &&
                    date[1] === action.payload.month
                ) {
                    state.datesFormObject[i][action.payload.meal].prepMeal =
                        action.payload.value;
                }
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setMoney,
    setNumberOfPeople,
    setDateRange,
    categoryChange,
    setMealOnOff,
    onDayOnOff,
    onNumberOfPeopleDayChange,
    onNumberOfPeopleOnMeal,
    onMealOnDayOnOff,
} = calculatorForm.actions;

export default calculatorForm.reducer;
