import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fridgeSelector } from '../store/fridge/fridge.slector';
import { fridgeActions } from '../store/fridge/fridge.reducer';
import { Helmet } from 'react-helmet';
import Menu from '../components/pages/Menu/Menu';
import Header from '../components/pages/General/Header';
import ScrollToTop from '../components/pages/Menu/ScrollToTop';
import Footer from '../components/pages/Menu/Footer';
import FridgeComponent from '../components/pages/Fridge/FridgeComponent';
import FridgeHeader from '../components/pages/Fridge/FridgeHeader';
import Loader from 'react-loader-spinner';

const Fridge = () => {
    const dispatch = useDispatch();

    const ingredientList = useSelector(fridgeSelector.selectIngredientList);
    const ingredientListInProgress = useSelector(fridgeSelector.selectIngredientListInProgress);

    const recipeList = useSelector(fridgeSelector.selectRecipeList);
    const recipeListInProgress = useSelector(fridgeSelector.selectRecipeListInProgress);

    useEffect(() => {
        if (ingredientList.length === 0 && !ingredientListInProgress) {
            dispatch(fridgeActions.getFridgeIngredientsListAction());
        }

        // Animations
        const $ = window.$;

        $('#basic-fridge-header').velocity("fadeIn", {
            duration: 500,
            complete: () => {
                $('#basic-fridge-text').velocity("fadeIn", {duration: 500});
                $('#fridge-main-content').velocity("fadeIn", {duration: 500});
            }
        });
    }, []);

    useEffect(() => {
        if (
            recipeList.length === 0
            && !recipeListInProgress
            && ingredientList.length > 0
        ) {
            dispatch(fridgeActions.getFridgeRecipeListAction());
        }
    }, [ingredientList]);

    return (
        <>
            <FridgeHelmet />

            <Menu />
            <Header current={'Frižider'} />

            <FridgeHeader />
            {ingredientListInProgress && (
                <div style={{ padding: '50px', textAlign: 'center' }}>
                    <Loader type={'TailSpin'} />
                </div>
            )}
            {ingredientList && (
                <FridgeComponent
                    ingredientList={ingredientList}
                    recipeList={recipeList}
                />
            )}

            <ScrollToTop />
            <Footer />
        </>
    );
};

const FridgeHelmet = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Stomačko frižider</title>
            <meta property='title' content="Stomačko frižider" />
            <meta property='description' content="Najbolji proizvod za pun stomak i novčanik" />
            <meta property="og:site_name" content = "Stomačko" />

            <meta property='og:title' content="Stomačko frižider" />
            <meta property="og:type" content='website'/>
            <meta property='og:description' content="Najbolji proizvod za pun stomak i novčanik" />

            <meta property="og:image" content="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/assets/public/og-image.png" />
            <meta property="og:image:width" content="378" />
            <meta property="og:image:height" content="156" />
            <meta property='og:url' content={`https://stomacko.com/frizider`} />

            <meta property='twitter:title' content="Stomačko frižider" />
            <meta property='twitter:image' content="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/assets/public/og-image.png" />
            <meta property='twitter:url' content={`https://stomacko.com/frizider`} />

            <meta name='keywords' content="stomacko frižider, sastojci, jela, brza priprema" />
        </Helmet>
    );
}

export default Fridge;