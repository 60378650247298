import React, { useEffect, useState } from 'react';
import RecipeList from "./RecipeList";
import IngredientListWrapper from "./IngredientList";
import Divider from "../../General/DIvider";
import { useDispatch, useSelector } from "react-redux";
import {
	calculateRecipes,
	createLastParamSelector,
	resetResults,
	saveRecipesAction,
} from "store/callculator/result";
import { Redirect } from "react-router";
import { CALCULATOR_URLS, REGISTER_URLS } from "util/routes/urls";
import WaitingResults from "./WaitingResults";
import { selectUser } from "store/user/selector";

const ShowResults = ({ recipes, ingredients, totalCost }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [redirect, setRedirect] = useState(null);
	const [active, setActive] = useState(1);

	const dispatch = useDispatch();

	const lastParams = useSelector(createLastParamSelector);
	const user = useSelector(selectUser);
	const resultInProgress = useSelector((state) => state.calculatorResult.resultInProgress);
	// const gotResults = useSelector((state) => state.calculatorResult.gotResults);
	const hasResults = useSelector((state) => state.calculatorResult.hasResults);

	useEffect(() => {
		setIsLoading(false);
		if (active === 2) {
			window.$('#recipes-tab').click();
		}
	}, [recipes]);

	// No need because sticky
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		if (scrollToBottom) {
	// 			const $ = window.$;
	// 			document.getElementById('save-results').scrollIntoView({
	// 				behavior: "smooth", // or "auto" or "instant"
	// 				block: "start" // or "end"
	// 			});
	// 		}
	// 	}, 222)
	// }, [scrollToBottom])

	// useEffect(() => {
	// 	// TODO Move to action
	// 	if (gotResults) {
	// 		dispatch(resetResults());
	// 		setRedirect(CALCULATOR_URLS.CALCULATOR_SAVED);
	// 	}
	// }, [gotResults]);

	useEffect(() => {
		if (hasResults) {
			window.$("#confirm-modal").modal('toggle');
		}
	}, [hasResults]);

	useEffect(() => {
		window.$('#foodTabContent').velocity("fadeIn", {duration: 750});
	}, [recipes]);

	const saveResultsWithConfirmation = () => {
		window.$("#confirm-modal").modal('toggle');
		dispatch(saveRecipesAction({ recipes, ingredients, confirmation: true }));
	};

	const onChangeParams = () => {
		if (!isLoading) {
			dispatch(resetResults());
			setRedirect(CALCULATOR_URLS.CALCULATOR);
		}
	};

	const onTryAgain = () => {
		if (!isLoading) {
			window.$('#foodTabContent').velocity("fadeOut", {
				duration: 750,
				complete: () => {
					setIsLoading(window.$('.result-page-container').height() + 'px');
					dispatch(calculateRecipes(lastParams));
				}
			});

		}
	};

	const onResultSave = () => {
		if (user && user.email) {
			dispatch(saveRecipesAction({ recipes, ingredients }));
		} else {
			window.$('#login-modal').modal('toggle');
		}
	};

	const onRouteLink = (route) => () => {
		window.$('#login-modal').modal('toggle');
		setRedirect(route);
	};

	if (redirect) {
		return <Redirect to={redirect} />;
	}

	const isMobile = window.innerWidth < 991;

	return (
		<section className="vs-food-box-wrapper food-box-layout1 position-relative link-inherit py-50 py-lg-50">
			<LoginModal onRouteLink={onRouteLink} />
			<ConfirmationModal saveResults={saveResultsWithConfirmation} />
			<div className="container">
				<div className="food-menu-wrapper food-menu-style1 list-style-none text-center"
					 style={{
						position: 'sticky',
						top: '0px',
						backgroundColor: "white",
						zIndex: '3',
						paddingBottom: '5px',
						paddingTop: '10px',
						// boxShadow: '0px 10px 6px -6px rgba(0,0,0,0.25)'
					}}
				>
					<ul className="nav nav-tabs d-block border-0" role="tablist">
						<li><a onClick={() => setActive(1)} className="custom-toggle mask-style3 active" id="recipes-tab" data-toggle="tab"
							href="#recipes" role="tab" aria-controls="recipes" aria-selected="true">Recepti</a>
						</li>
						<li><a onClick={() => setActive(2)} className="custom-toggle mask-style3" id="ingredients-tab" data-toggle="tab"
							href="#ingredients" role="tab" aria-controls="ingredients"
							aria-selected="false">Sastojci</a></li>
					</ul>

					<Divider style={{marginBottom: '5px'}} />

				</div>
				<div className="text-center food-title text-lg">
					Ukupna cena:
					<div className=" mb-3 text-theme text-bold">
						{totalCost.toFixed(2)} RSD
					</div>
				</div>
				{isLoading && <div style={{ height: isLoading }}>
					<WaitingResults />
				</div>}

					<div className="tab-content result-page-container" id="foodTabContent">
						<div className="tab-pane active link-inherit" id="recipes" aria-labelledby="recipes-tab">
							<div className="row justify-content-center">
								<div className="col-xl-8 col-md-12">
									<RecipeList recipes={recipes} />
								</div>
							</div>
						</div>
						<div className="tab-pane " id="ingredients" aria-labelledby="ingredients-tab">
							<div className="row justify-content-center">
								<div className="col-xl-8 col-md-12">
									<IngredientListWrapper ingredients={ingredients} />
								</div>
							</div>
						</div>
					</div>

				<div className="row justify-content-center mt-4"
					 style={isMobile ? {} : {
							position: 'sticky',
							bottom: '0px',
							backgroundColor: "white",
							zIndex: '3',
							paddingBottom: '20px',
							// boxShadow: '0px -10px 6px -6px rgba(0,0,0,0.25)',
						}}
				>
					<div className="col-xl-8 col-md-12">
						<div className={"d-flex justify-content-between flex-column flex-md-row"} style={{ marginTop: '20px'}}>
							<div className="d-flex flex-column flex-md-row mb-md-0">
								<button
									className="vs-btn btn-outline-dark mb-3 mb-lg-0 mb-sm-0"
									disabled={resultInProgress}
									style={{ padding: '12px 40px',
									borderRadius: '12px'}}
									onClick={onChangeParams}
								>
									Promeni parametre
								</button>
								<button
									className="vs-btn btn-outline-dark  mb-3 mb-lg-0 mb-sm-0"
									disabled={resultInProgress}
									style={{ padding: '12px 40px',
									borderRadius: '12px'}}
									onClick={onTryAgain}
								>
									Ponovi magiju
								</button>
							</div>
							<button
								id="save-results"
								className="vs-btn style3 custom-primary-button  mb-3 mb-lg-0 mb-sm-0"
								disabled={resultInProgress}
								style={{ padding: '12px 40px',
								borderRadius: '12px'}}
								onClick={onResultSave}
							>
								Sačuvaj rezultate
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

const LoginModal = ({onRouteLink}) => {
	return (
		<div className="modal fade" id="login-modal" tabIndex="-1" role="dialog"
			 aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Korisnik nije ulogovan</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						Da biste nastavili, potrebno je da se ulogujete, ili napravite nalog ukoliko ga nemate.
					</div>
					<div className="modal-footer" style={{ display: 'flex' }}>
						<button type="button" className="btn btn-primary" onClick={onRouteLink(REGISTER_URLS.REGISTER)}>
							Napravi nalog
						</button>

						<button type="button" className="btn btn-success" onClick={onRouteLink(REGISTER_URLS.LOGIN)}>
							Uloguj se
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const ConfirmationModal = ({saveResults}) => {
	return (
		<div className="modal fade" id="confirm-modal" tabIndex="-1" role="dialog"
			 aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Već postoje rezultati</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						Da li ste sigurni da želite da sačuvate?
						Već postoje sačuvani rezultati koji su i dalje aktivni. Čuvanjem novih rezultata stari postaju neaktivni.
					</div>
					<div className="modal-footer" style={{ display: 'flex' }}>
						<button type="button" className="btn btn-primary" onClick={() => window.$("#confirm-modal").modal('toggle')}>
							Odustani
						</button>

						<button type="button" className="btn btn-success" onClick={saveResults}>
							Sačuvaj
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShowResults;
