import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router.js';
import { store } from 'store/store';
import { Provider } from 'react-redux';

import ThemeWrapper, { THEME_LIST } from './wrappers/ThemeWrapper';
import UserWrapper from "./wrappers/UserWrapper";
import GoogleAnalytics from '../components/general/GoogleAnalytics';

const App = () => {
    window.lang = 'sr'; // TODO Kill

    const theme = THEME_LIST.green;

    return (
        <Provider store={store}>
            <BrowserRouter>
                <ThemeWrapper color={theme}>
                    <UserWrapper>
                        <GoogleAnalytics />
                        <Router />
                    </UserWrapper>
                </ThemeWrapper>
            </BrowserRouter>
        </Provider>
    );
};

export default App;
