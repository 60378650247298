
export const createChosenParamsDefault = (chosenDates, numberOfPeople, MEALS, active = 7) => {
    return chosenDates.map((date, index) => {
        const day = {
            date,
            prepOnTheDay: true,
            active: index < active,
        };
        MEALS.forEach((meal, index) => {
            day[meal] = {
                numberOfPeople,
                listOfMeals: [],
                prepMeal: true,
           };
        });

        return day;
    });
};

export const getColorStyle = (cookingStatus, activeStatus) => {
    return !cookingStatus ? {color: 'darkGray'} : activeStatus ? {color: '#009432'} : {color: 'black'};
};