export const pinkRecipeList = [{
    "_id": "64e4cba597bca6ca23ea15ab",
    "name": "Paprike punjene kinoom",
    "recipe": "Od sastojaka je potrebno: \n\n4 velike paprike babure, očišćene i prepolovljene uzduž, ¾ šolje kinoe, 1 šolja bujona od povrća (najbolje domaćeg, ali može i od otopljene kocke za supu), šolja bebi spanaća, 1 čen belog luka, ¼ šolje iseckanih neslanih pistaća, 3 kašike prokuvane vode, 400 g seckanog paradajza svežeg, ili iz konzerve, 6 kašika parmezana, 2-3 šolje svežeg bosiljka, 3 kašike maslinovog ulja, malo bibera, ¼ kašičice soli\n\nUputstvo za pripremu: \n\n1. Zagrejati rernu na 220 °C i peći paprike 10 minuta.\n2. Za to vreme kuvati kinou u bujonu/supi zajedno sa paradajzom na srednjoj temperaturi, dakle takođe oko 10 minuta. Ubaciti spanać, dobro pomešati i onda filovati paprike. \n3. Pesto pravimo tako što u blenderu umutimo maslinovo ulje, beli luk, parmezan, bosiljak, so i biber. Sipamo preko paprika i posipamo izmrvljenim pistaćima. Služimo i jedemo odmah.\n",
    "cost": 1025.3202911764706,
    "prepTime": 15,
    "hint": "Kinoa je sve popularnija namirnica, kao odlična alternativa za skrobaste namirnice od celog zrna, jer ne sadrži gluten, a bogata je proteinima i vlaknima. ",
    "slug": "paprike-punjene-kinoom"
},{
    "_id": "64e4cde70e1cff5a53e8398a",
    "name": "Pikantna pečena piletina",
    "recipe": "Od sastojaka je potrebno:\n\n ½ pileta, isečeno, skinuti kožicu, 3 čena belog luka, so i biber po ukusu, kašika suvog italijanskog začina, kašika Tabasko sosa, ili nekog drugog sosa po izboru, ¾ šolje nemasnog jogurta, kašika cimeta, mlevena ljuta paprika po ukusu\n\nUputstvo za upotrebu:\n\n1. U vatrostalnoj posudi za pečenje pomešati jogurt, cimet, ljutu papriku, ljudi sos i izrendani beli luk. Dodati so i biber po ukusu. Premazati dobro piletinu smesom, poklopiti i ostaviti u frižideru na sat vremena ili još bolje preko noći. \n2. Zagrejati rernu na 220 °C. Posuti piletinu italijanskim začinom i ubaciti u rernu koju odmah smanjujemo na 180 °C. Pečemo sat i po, služimo toplo, a može i hladno. \n",
    "cost": 630.4051245098038,
    "prepTime": 60,
    "hint": "Preporuka onkološkim pacijentima u ishrani, između ostalog, podrazumeva i pripremu mesa sa minimalno ulja. Zato delimo ovaj divan recept za sočnu piletinu, prethodno mariniranu i pečenu u rerni. ",
    "slug": "pikantna-pecena-piletina"
},{
    "_id": "64e4cefa0e1cff5a53e84685",
    "name": "Šejk od banane, cimeta i vanile",
    "recipe": "Od sastojaka je potrebno: \n\n1 srednja banana, pola kugle sladoleda od vanile, ¾ šolje mleka, pola kašičice cimeta\n\nUputstvo za pripremu: \n\nIzmiksati sve dobro u blenderu, sipati u čašu i polako uživati ispijajući napitak na slamčicu!\n",
    "cost": 75.12516666666667,
    "prepTime": 5,
    "hint": "Božanstven spoj ukusa koji će doneti trenutno olakšanje, telu i psihi. ",
    "slug": "sejk-od-banane-cimeta-i-vanile"
},{
    "_id": "64e4d1ba0e1cff5a53e84cf4",
    "name": "Smuti sa breskvom i kajsijom ",
    "recipe": "Od sastojaka je potrebno: pola šolje svežih breskvi, pola šolje kajsija, pola šolje svežih ili smrznutih malina, šolja nemasnog jogurta, kašika mlevenog lanenog semena, par kockica leda za serviranje\n\nUputstvo za pripremu: \n\nIzmiksati sve dobro u blenderu, sipati u čašu i uživati u rapsodiji ukusa!\n",
    "cost": 265.98875000000004,
    "prepTime": 5,
    "hint": "U raznovrsnu ishranu bogatu hranljivim materijama koje su neophodne telu kada se bori protiv bolesti, spadaju i namirnice poput svežeg voća i mlečnih proizvoda sa niskim procentom masti. Uživaćete u ovom brzom i osvežavajućem receptu, posebno tokom perioda sezonskog voća. ",
    "slug": "smuti-sa-breskvom-i-kajsijom"
},{
    "_id": "64e4d47e0e1cff5a53e860a5",
    "name": "Pečeni svinjski file u sosu od senfa i javorovog sirupa",
    "recipe": "Od sastojaka je potrebno: kilo i po svinjskog mesa od buta, kašika soli, kašika bibera, 3-4 kašike ulja, ¼ šolje jabukovog sirćeta, pola šolje javorovog sirupa, kašika dižon senfa, kašika Vorčester sosa, još pola kašike bibera za sos\n\n1. Zagrejati rernu na 200 °C. Pouljiti posudu za pečenje. Napraviti nekoliko rezova u mesu i lepo ga izmasirati solju i biberom.\n2. U dubokoj posudi za prženje na vrelom ulju pržiti meso toliko da sa svih strana dobije braon boju, a zatim ga prebaciti u pleh. Ostaviti sa strane.\n3. U manjoj posudi pomešati sastojke za sos: ¼ šolje jabukovog sirćeta, pola šolje javorovog sirupa, kašika dižon senfa, kašika Vorčester sosa, pola kašike bibera. Zatim staviti da proključa i kuvati 5 minuta. Pola sosa ostaviti sa strane, a drugom polovinom premazati meso. \n4. Peći oko 20 minuta\n5. Izvaditi i premazati ostatkom sosa i pre sečenja i služenja ostaviti tako desetak minuta.\n",
    "cost": 1241.540331372549,
    "prepTime": 30,
    "hint": "Tokom lečenja i terapija mogu se javiti neprijatne nuspojave poput suvoće i bolova u ustima. Iz tog razloga je preporuka da se hrana priprema tako da bude što mekša i rastresitija, i da se koriste sosovi, kako bi se jela lakše žvakala i gutala. ",
    "slug": "peceni-svinjski-file-u-sosu-od-senfa-i-javorovog-sirupa"
},{
    "_id": "64e5dd7c0e1cff5a53e87460",
    "name": "Ražnjići sa gamborima, piletinom i ananasom uz sos od pomorandže i meda",
    "recipe": "Za ražnjiće je potrebno: 2 šolje ananasa isečenog na kocke, 2 kašike soka od ananasa, 12-15 gambora, pola kašike belog luka u prahu, 4 pileća filea isečena na kocke srednje veličine, drveni štapići za ražnjiće koje smo pre pripreme potopili malo u vodu\n\nZa sos je potrebno: 2 kašike kukuruznog skroba u prahu, ¼ kašike soka od limuna, šolja soka od pomorandže, 2 kašike meda, prstohvat bibera\n\nUputstvo za pripremu: \n\n1. Nizati gambore, piletinu i ananas na štapiće, tako da ananas razdvaja piletinu od gambora. Ređati ih u plitku posudu pa poprskati sokom od ananasa. Zagrejati tiganj za grilovanje na srednju temperaturu. \n2. Staviti ražnjiće u tiganj i posuti sa belim lukom u prahu. \n3. Peći 2-3 minuta sa svih strana, dok gambori ne dobiju boju, a piletina ne bude pečena. Zatim ražnjiće vaditi iz tiganja i ređati na tanjir za služenje.\n4. U odvojenoj posudi pomešati dobro kukuruzni skroz sa limunovim sokom. U drugoj posudi pomešati sok od pomorandže, med i biber. \n5. Drugu smesu sa sokom od pomorandže treba staviti na ringlu da proključa, a zatim sipati i dobro umešati smesu sa kukuruznim skrobom. Ostaviti da se kuva još 2-3 minuta dok sos ne postane gust, a zatim skloniti sa šporeta. \n6. Služiti sa osmehom i uživati u svakom zalogaju. \n",
    "cost": 848.0268554621849,
    "prepTime": 15,
    "hint": "Rane i bolovi u ustima javljaju se kao posledica hemioterapije i zračenja. Savet i preporuka u vezi sa ishranom u tim slučajevima, između ostalog je i dodavanje sosova jelima. Iz tog razloga delimo jedan zabavan i zanimljiv recept koji će povoljno uticati na ublažavanje ovog problema.",
    "slug": "raznjici-sa-gamborima-piletinom-i-ananasom-uz-sos-od-pomorandze-i-meda"
},{
    "_id": "64e5e1ea0e1cff5a53e87aed",
    "name": "Nova tuna salata",
    "recipe": "Od sastojaka je potrebno: šolja seckanih crnih maslina, pola srednjeg crvenog luka sitno iseckanog, kašika maslinovog ulja, šolja i po proceđene tune, 3 šolje leblebija dobro proceđenih (mogu biti i iz konzerve), 3 šolje belog pasulja (takođe može biti iz konzerve), sok od jednog limuna, 2 veća paradajza očišćena od semenki i sitno iseckana, biber po ukusu.\n\nUputstvo za pripremu: \n\n1. Prvo dobro procediti pasulj, leblebije i tunu, smestiti sve u jednu veliku činiju, dobro izmešati, dodati paradajz, masline, crveni luk, maslinovo ulje i limunov sok i opet sve izmešati. \n2. Dodati biber po ukusu\n3. Služiti dragim ljudima i uživati u divnim ukusima zajedno sa njima!\n",
    "cost": 787.9877112984219,
    "prepTime": 15,
    "hint": "Bogate omega tri masnim kiselinama, ribe poput tune, skuše, pastrmke i lososa mogu biti blagotvorne u ishrani tokom lečenja od raka dojke. ",
    "slug": "nova-tuna-salata"
},{
    "_id": "64e5e2b00e1cff5a53e88180",
    "name": "Italijanska piletina s pirinčem ",
    "recipe": "Od sastojaka je potrebno: kašika maslinovog ulja, pola šolje iseckanih šampinjona, 1 čen izrendanog belog luka, 1 kašika sušenog bosiljka, 1 kašika origana, 400 g smrznute mešavine povrća ili 3-4 šolje sveže iseckanog, 2 šolje kuvanog integralnog pirinča, kašika parmezana, pola šolje pilećeg bujona (najbolje domaćeg, ali može i otopljenog od kocke za supu), 2 srednja pileća filea isečena na kocke, so i biber po ukusu\n\nUputstvo za pripremu: \n\n1. Staviti pirinač da se skuva. Za to vreme zagrejati ulje u tiganju, ubaciti piletinu i pržiti dok ne izgubi roze boju. Zatim je izvaditi i staviti sa strane.\n2. Dodati povrće i beli luk u tiganj, pa mešati naredna 2 minuta.\n3. Dodati pečurke i opet mešati naredna 2 minuta. Zatim vratiti piletinu u tiganj. \n4. Dodati bosiljak, origano i pileći bujon. \n5. Mešati dok piletina ne bude u potpunosti kuvana, još oko 4-5 minuta.\n6. Dodati parmezan i sve pomešati. Začiniti solju i biberom po ukusu. Preliti time pripremljeni pirinač i služiti dok je toplo.\n\n\n\n",
    "cost": 858.9690967320262,
    "prepTime": 20,
    "hint": "Integralni pirinač, hleb i testenine napravljene sa brašnom od celog zrna, predstavljaju dobar izbor za pacijente koji se leče od raka, jer poseduju blagotvorne vitamine i vlakna koja pomažu lakše varenje.",
    "slug": "italijanska-piletina-s-pirincem"
},{
    "_id": "64e5e5c00e1cff5a53e895b9",
    "name": "Brza lazanja sa spanaćem ",
    "recipe": "Od sastojaka je potrebno: 2 kašike ulja, 1 mali i na sitno iseckan crni luk, 3-4 izrendana čena belog luka, 3 šolje marinara sosa (domaći ili kupovni), 12 kora za lazanju, ¼ šolje parmezana, 1 šolja iseckane mocarele, pakovanje smrznutog spanaća, 1 jaje, 1 kašika origana, 1 kašika suvog bosiljka, ¼ kašike muskantnog oraščića, šolja i po rikote, so i biber po ukusu, malo svežeg peršuna ili bosiljka, po želji\n\nUputstvo za pripremu:\n\n1. Zagrejte rernu na 180 °C.\n2. U velikom tiganju zagrejte ulje na srednjoj temperaturi. Dodajte crni i beli luk i pržite 3-4 minuta dok ne postane staklast. Dodajte spanać i muskantni oraščić i nastavite s prženjem uz mešanje dok tečnost ne nestane, oko 3 minuta. Zatim sklonite tiganj sa šporeta i ostavite spanać da se ohladi oko 15 minuta. Za to vreme u odvojenoj činiji pomešajte rikotu, mocarelu, jaje, origano, bosiljak, so i biber.\n3. Ostavite sa strane pola šolje marinara sosa, a zatim u pleh razmera 23 x 33 cm od ostatka sosa prekrijete dno pleha. Preko toga složite 3 kore, zatim preko ide trećina pripremljenog spanaća, a onda trećina pripremljenog sira. Ponovite postupak dok imate sastojaka, dakle još 3 puta. Na kraju sipati prvobitno ostavljen marinara sos i posuti parmezanom. Prekriti folijom i peći 30 minuta. \n4. Nakon toga skinuti foliju i nastaviti s pečenjem još 15 minuta. Ostaviti malo da se prohladi pre služenja, a potom seći i po želji posuti sveže iseckanim peršunom ili bosiljkom. \n\n",
    "cost": 2206.4649800653597,
    "prepTime": 20,
    "hint": "Zeleno povrće poput spanaća, kelja ili zelja, predstavljaju namirnice sa kojim se, između ostalog, bori protiv raka dojke. Bogate antioksidansima imaju moć da uništavaju slobodne radikale koji uzrokuju bolest.",
    "slug": "brza-lazanja-sa-spanacem"
},{
    "_id": "64e5e8110e1cff5a53e89c89",
    "name": "Banana mafini",
    "recipe": "Od sastojaka je potrebno: 2 i po šolje brašna, kašika i po praška za pecivo, 2 veća jaja, šolja i po mekinja, ¾ šolje šećera, ¼ kašičice soli, šolja i po mleka, šolja izgnječenih zrelih banana \n\nBrašno ne mora biti od celog zrna mada je preporuka, a mleko može biti neobrano, ali i neko sa niskim procentom masti, konsultovati se sa svojim lekarom ili nutricionistom.\n\nUputstvo za pripremu: \n\n1. Zagrejati rernu na 220 °C. Pleh sa 12 kalupa za mafine pouljiti. \n2. Pomešati u većoj posudi zajedno brašno, šećer, prašak za pecivo i so.\n3. U drugoj činiji ulupati jaja, dodati mleko, mekinje i sve dobro izmešati. Ostaviti da odstoji oko 10 minuta.\n4. Dodati mokre sastojke u suve i mešati varjačom dok se smesa lepo ne sjedini. \n5. Na kraju dodati banane i sipati smesu u kalupe, otprilike sa smesa popuna ⅔ od svakog.\n6. Peći oko 25 minuta, mafini su gotovi kada probodete čačkalicom i ona ostane suva. \n\n\n\n",
    "cost": 322.92075000000006,
    "prepTime": 15,
    "hint": "Tokom dvadesetogodišnjeg internacionalnog ispitivanja, zaključeno je da vrsta skroba koja se nalazi u banani može umanjiti određene vrste kancera i do 60%. Ovo dragoceno saznanje proslavljamo uz recept za divne, ukusne i niskokalorične mafine.",
    "slug": "banana-mafini"
},{
    "_id": "64e5ef160e1cff5a53e8a354",
    "name": "Topla čokolada sa cimetom ",
    "recipe": "Od sastojaka je potrebno: ¼ šolje kakao praha, 4 štapića cimeta, ⅓ šolje crne čokolade, ¼ šolje šećera, 4 šolje mleka\n\nUputstvo za pripremu: \n\n1. U šerpici pomešati mleko, kakao i čokoladu i kuvati na srednjoj temperaturi. Mešati ručnom mućkalicom dok ne proključa i dok se sastojci lepo ne sjedine, a čokolada skroz otopi. \n2. Sipati u šolje i ubaciti u svaku štapić cimeta. \n3. Uživajte!\n",
    "cost": 363.79521666666665,
    "prepTime": 5,
    "hint": "U svojoj knjizi o vezi između mozga i creva, poznata doktorka, nutritivni psihijatar, Uma Naidu, između ostalog opisuje svoje iskustvo raka dojke i to kako se svakog četvrtka nakon hemioterapije izuzetno radovala šolji tople čokolade sa cimetom. Recept za ovu poslasticu sada i mi delimo.",
    "slug": "topla-cokolada-sa-cimetom"
},{
    "_id": "64e5f7800e1cff5a53e8b6ef",
    "name": "Tofu kebab sa sosom od kikirikija ",
    "recipe": "Sastojci za sos: ¼ šolje kikiriki putera (može i od badema), ¼ šolje konzerviranog ananasa, 2 izrendana čena belog luka, kašika limunovog soka, kašika soja sosa\n\nSastojci za kebab: 3 tikvice srednje velićine, oguljene i isečene na kocke srednje veličine, jedna zelena paprika srednje veličine, očišćena i isecena na osam kvadrata, 4 očišćena mlada luka ili jedan crveni luk isečen na 8 rebara, 300 g tvrdog tofu sira, štapići za nizanje kebaba/ražnjića\n\nSastojci za marinadu: kašika soja sosa, sok od jednog limuna, kašika meda, kašika mlevenog đumbira, tucana ljuta paprika po ukusu \n\nUputstvo za upotrebu: \n\n1. Dobro zagrejati pouljeni tiganj za grilovanje.\n2. U blenderu dobro ispasirati sve sastojke za sos pa staviti u frižider. \n3. U velikoj činiji pomešati sve sastojke i napraviti marinadu, dodati povrće i tofu pa ostaviti za strane 15-30 minuta.\n4. Napraviti kebabe tako što ćete naizmenično nizati povrće i tofu sir.\n5. Grilovati oko 2 minuta sa svih strana.\n6. Služiti sa sosom od kikirikija \n\n\n\n",
    "cost": 1117.5714075630253,
    "prepTime": 20,
    "hint": "Tofu je odličan izvor proteina, kalcijuma, gvožđa, kalijuma, a poseduje i zavidnu količinu vlakana. Kod nas ova namirnica iako ima puno benefita za zdravlje nije još uvek toliko popularna, ali je izuzetno ukusna, a pored toga, svetske studije su pokazale da konzumiranje namirnica od soje dugoročno, smanjuje rizik od raka dojke kod žena. ",
    "slug": "tofu-kebab-sa-sosom-od-kikirikija"
},{
    "_id": "64e5fa140e1cff5a53e8bd9c",
    "name": "Jabuka krisp",
    "recipe": "Od sastojaka je potrebno: pola šolje suvog grožđa, 3 kašike soka od jabuka, ¼ šolje brašna od celog zrna, 4 jabuke greni smit, oljuštene i iseckane na veličinu zalogaja, kašika putera, ¼ čaše ovsenih pahuljica, ¼ čaše braon šećera, ¾ kašičice cimeta, ¾ kašičice muskantnog oraščića\n\n1. Zagrejati rernu na 200 °C i obložiti pleh pek papirom.\n2. Pomešati u posudi iseckane jabuke, sok od jabuka i suvo grožđe pa sipati i rasporediti masu u pripremljeni pleh. \n3. U drugoj posudi pomešati brašno, ovsene pahuljice, braon šećer, cimet i muskantni oraščić.\n4. Umutiti puter i rasporediti po površini smese s jabukama. \n5. Peći 30 minuta prekriveno folijom, a zatim 20 minuta bez folije. \n6. Izvaditi pleh iz rerne i ostaviti oko 20 minuta da se hladi. Seći na kocke i služiti dok je toplo. \n",
    "cost": 233.80121111111112,
    "prepTime": 15,
    "hint": "Jabuka. Voće bogato vlaknima, kalijumom i vitaminom C, ono što je od izuzetne važnosti, posebno organizmu koji se oporavlja od kancera. ",
    "slug": "jabuka-krisp"
},{
    "_id": "64e5fa780e1cff5a53e8c400",
    "name": "Borovnica krambl",
    "recipe": "Od sastojaka je potrebno: 5 čaša svežih ili smrznutih borovnica. ¾ šolje braon šećera, 3 kašike belog mekog brašna, 1 i po kašika vanila ekstrakta, pakovanje lisnatog testa, ¼ kašičice kore od limuna, šolja kisele pavlake, ¼ šolje prezli, 1 kašika šećera, 1 kašika istopljenog margarina\n\n1. Zagrejati rernu na 180 °C.\n2. Rasporediti borovnice po razvučenom lisnatom testu i ostaviti sa strane.\n3. Pomešati braon šećer, brašno, vanilu, limunovu koru i kiselu pavlaku i rasporediti po borovnicama. \n4. Pomešati prezle, običan šećer i margarin pa posuti preko smese sa kiselom pavlakom.\n5. Peći 40 minuta, odnosno dok prezle ne postanu svetlo braon.\n6. Ohladiti 30 minuta do jedan sat, pa poslužiti. \n",
    "cost": 915.6589105263159,
    "prepTime": 20,
    "hint": "Ekstrakt borovnice ne samo da čini ćelije raka osetljivijim na zračenje, već takođe smanjuje rast ćelija koje podstiču razvoj raka.",
    "slug": "borovnica-krambl"
},{
    "_id": "64e76ec60e1cff5a53e989e1",
    "name": "Mali dobri brauni",
    "recipe": "Sastojci: ⅓ šolje bademovog brašna, ⅓ šolje pirinčanog brašna, 2 kašike kakao praha, ½ kašičice cimeta, ½ kašičice praška za pecivo, ⅛ kašičice soli, 200 g grama crne čokolade sa visokim procentom kakaa, ⅓ šolje maslinovog ulja, 2 jaja, ⅓ šolje javorovog sirupa, kašika vanile i pola šolje seckanih oraha su opcioni. \n\nUputstvo za pripremu: \n\n1. Zagrejati rernu na 180 °C. Pleh razmera 32x25x5 obložiti pek papirom tako da može kolač i da se prekrije. \n2. U posudi pomešati bademovo brašno, pirinčano brašno, kakao prah, cimet, prašak za pecivo i so.\n3. Staviti pola iseckane čokolade u šerpicu i kuvati na pari dok se ne otopi, a zatim je skloniti sa šporeta i umešati maslinovo ulje. \n4. Ulupati jaja i onda konstanto mućkajući dodati prvo javorov sirup, zatim vanila ekstrakt ako želimo, onda istopljenu čokoladu i mešamo sve dok ne dobijemo ujednačenu smesu.\n5. Dodajemo smesu sa brašnom i mešamo oko minuta dok se sve ne sjednini. Dodajemo ostatak iseckane čokolade i orahe ukoliko želimo. Prebacujemo i raspoređujemo smesu u pleh.\n6. Pečemo 30 minuta, dok čačkalica kad probodemo kolač ne ostane suva. Ostaviti da se ohladi na sobnoj temperaturi i iseći na 16 parčića. \n\n",
    "cost": 848.24615,
    "prepTime": 15,
    "hint": "Slasni čokoladni kolač bez glutena, sa zdravim mastima i mnogo ukusa!",
    "slug": "mali-dobri-brauni"
}]