import React from 'react';
import { Link } from "react-router-dom";
import { RECIPE_URLS, replaceWildcards } from "util/routes/urls";
import { formatFullLocaleDate } from "util/helpers/date";
import { capitalizeFirstLetter } from "util/helpers/string";
import { useLocation } from "react-router";
import { CLOUDFRONT_URL } from '../../../../util/constants/global';

const RecipeList = ({ recipes, isShowingDays = true }) => {
	return (
		<>
			{recipes &&
				recipes.map((recipe, index) => {
					// TODO Do on BE
					if (recipe._id && !recipe.id) {
						recipe.id = recipe._id;
					}
					return <SingleRecipe
						key={recipe.id + index}
						recipe={recipe}
						isShowingDays={isShowingDays}
					/>;
				})}
		</>
	);
};

const SingleRecipe = ({ recipe, isShowingDays }) => {
	console.log('recipe',recipe);
	const location = useLocation();
	const locationState = { prevPath: location.pathname };

	if (!recipe.ingredients) {
		return null;
	}

	const ingredientList = recipe.ingredients?.length > 0 ? recipe.ingredients.map(
		(ingredient) => ingredient.name
	) : false;

	const portionNumber = recipe.numberOfPeople * recipe.prepForDays;
	const cost = recipe.cost * portionNumber;

	const link = replaceWildcards(RECIPE_URLS.RECIPE, [
		recipe.slug,
	]);
	return (
		<div className="media mb-25 align-items-stretch d-block d-md-flex">
			<div className="media-thumb media-thumb-v2 mx-0 position-relative" style={{ aspectRatio: '3 / 2' }}>
				<strong className="food-price-v2 text-md d-lg-none">{cost.toFixed(2)} RSD</strong>
				<Link to={{ pathname: `${link}/${portionNumber}`, state: locationState }}>
					<img className="w-100 radius-10" src={CLOUDFRONT_URL + 'small/' + recipe.id + ' Small.jpeg'} alt="Food" style={{ aspectRatio: '3 / 2' }} />
				</Link>
			</div>
			<div
				className="media-body-v2 media-body px-md-30 d-flex align-items-lg-center"
			>
				<div className="food-content w-100 py-lg-2">
					<div className="mb-2">{capitalizeFirstLetter(formatFullLocaleDate(recipe.date))}</div>
					<h3 className="food-title text-lg mb-0 text-theme">
						<Link to={{ pathname: `${link}/${portionNumber}`, state: locationState }}>
							{recipe.name}
						</Link>
					</h3>
					{ingredientList && <p className="food-text mb-0 text-xs w-75">{ingredientList.join(', ')}</p>}
					<p className="food-text mb-0 text-xs">Porcija: {portionNumber}</p>
					{isShowingDays ? <p className="food-text mb-0 text-xs">Dana: {recipe.prepForDays}</p> : <p>&nbsp;</p>}
					<strong className="food-rating-icon text-theme text-md">{cost.toFixed(2)} RSD</strong>
				</div>
			</div>
		</div>
	);
};

export default RecipeList;
