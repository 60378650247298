import Api from 'main/Api';
import { SERVER_URLS } from 'util/routes/serverUrls';

const getBlogListAPI = (limit = 12, offset = 0) => {
	return Api.get(SERVER_URLS.GET_BLOG_LIST_URL, {params: { limit, offset }});
};

const getBlogAPI = (id) => {
	return Api.get(SERVER_URLS.GET_BLOG, {params: { id }});
};

const postBlogCommentAPI = (comment, email, name, id) => {
	return Api.post(SERVER_URLS.POST_COMMENT, { comment, email, name, id });
};

const getBlogCommentsAPI = (blogId) => {
	return Api.get(SERVER_URLS.GET_COMMENTS, {params: { blogId }});
};

export const blogApi = {
	getBlogListAPI,
	getBlogAPI,
	postBlogCommentAPI,
	getBlogCommentsAPI,
};
