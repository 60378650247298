import React from 'react';

import 'assets/css/global.css';

// Jquery
// import 'assets/theme/js/vendor/jquery-1.12.4.min.js';
// // Slick Slider
// import 'assets/theme/js/slick.min.js';
// Bootstrap
// import 'assets/theme/js/bootstrap.min.js';
// Layer Slider
// import 'assets/theme/js/greensock.min.js';
// import 'assets/theme/js/layerslider.kreaturamedia.jquery.js';
// import 'assets/theme/js/layerslider.transitions.js';
// // Counter js
// import 'assets/theme/js/jquery.counterup.min.js';
// import 'assets/theme/js/waypoints.min.js';
// // Date Picker
// import 'assets/theme/js/jquery.datetimepicker.min.js';
// // Magnific Popup
// import 'assets/theme/js/jquery.magnific-popup.min.js';
// // Nice Select
// import 'assets/theme/js/jquery.nice-select.min.js';
// // Custom Carousel
// import 'assets/theme/js/vscustom-carousel.min.js';
// // Mobile Menu
// import 'assets/theme/js/vsmenu.min.js';
// // Main Js File
// import 'assets/theme/js/main.js';

export const THEME_LIST = {
    orange: 1,
    blue: 2,
    green: 3,
    purple: 4,
    red: 5,
    lightBlue: 6,
};

const ThemeWrapper = (props) => {

    return <>{props.children}</>;
};

export default ThemeWrapper;
