import React, { useEffect } from 'react';

const ScrollToTop = () => {
    useEffect(() => {
        const $ = window.$;
        const scrollToTopBtn = '.scrollToTop';

        if ($) {
            $(window).on('scroll', function () {
                if ($(this).scrollTop() > 400) {
                    $(scrollToTopBtn).addClass('show');
                } else {
                    $(scrollToTopBtn).removeClass('show');
                }
            });
            $(scrollToTopBtn).on('click', function (e) {
                e.preventDefault();
                $('html, body').animate(
                    {
                        scrollTop: 0,
                    },
                    1000
                );
                return false;
            });
        }
        return () => {
            if ($) {
                $(window).off('scroll');
            }
        };
    }, []);
    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
            href="#"
            className="scrollToTop icon-btn bg-theme border-before-theme"

        >
            <i className="far fa-angle-up " />
        </a>
    );
};

export default ScrollToTop;
