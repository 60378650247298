import React from 'react';
import { CLOUDFRONT_URL } from '../../../../util/constants/global';

const translatePackagePlural = (amount) => {
	if (amount === 1) {
		return 'pakovanje';
	}
	return 'pakovanja';
};

export const pluralForUnits = (name, amount) => {
    if (name === 'goveđa kocka za supu ') {
        if (amount > 1) {
            return 'goveđih kocki za supu';
        }
        return name;
    }

    if (name === 'jaja') {
        if (amount === 1) {
            return 'jaje';
        }
        return name;
    }

    return name;
};

const IngredientListWrapper = ({ ingredients }) => {
	return (
		<div>
			<IngredientList ingredients={ingredients} />
		</div>
	);
};

const IngredientList = ({ ingredients }) => {
    return (
        <>
            {ingredients.map((ingredient, index) => {
                // TODO Do on BE
                const id = ingredient.id ?? ingredient?._id;
                return (
                    <IngredientRow key={id + index} ingredient={ingredient} />
                );
            })}
        </>
    );
};

const IngredientRow = ({ ingredient }) => {
    // TODO Do on BE
    const id = ingredient.id ?? ingredient?._id;

    const isKgMeasure = ingredient ? ingredient.baseUnit === 'kg-measure' : false;

    const priceText = `${ingredient.totalPrice.toFixed(2)} RSD`;

    return (
        <div className="media mb-25 align-items-stretch d-block d-md-flex ">
            <div className="media-thumb media-thumb-v2 mx-0 position-relative" style={{ aspectRatio: '3 / 2' }}>
                <strong className="food-price-v2 text-md d-lg-none">{priceText}</strong>
                <img
                    style={{ aspectRatio: '3 / 2' }}
                    className="w-100 radius-10"
                    src={`${CLOUDFRONT_URL}ingredients/${id} Medium.jpeg`}
                    alt={`Slika ${ingredient.name}`}
                />
            </div>
            <div
                className="media-body-v2 media-body px-md-30 d-flex align-items-lg-center"
            >
                <div className="food-content w-100 py-lg-2">
                    <div className="mb-2">
                        Potrebno: {ingredient.amountNeeded}
                        {ingredient.unitNeeded}
                    </div>
                    <h3 className="food-title text-lg mb-0 text-theme">
                        {ingredient.name}
                    </h3>
                    {!isKgMeasure &&
                        <p className="food-text mb-0 text-xs w-75">
                            Veličina pakovanja: {ingredient.minBuyUnit}
                            {ingredient.unit === 'units'
                                ? ' ' + pluralForUnits(ingredient.name, ingredient.minBuyUnit)
                                : ingredient.unit}
                        </p>
                    }
                    <p className="food-text mb-0 text-xs">
                        {!isKgMeasure ? 'Cena po pakovanju: ' : 'Cena: '}
                        {ingredient.costPerUnit.toFixed(2)} RSD {isKgMeasure ? '/ kg' : ''}
                    </p>
                    {!isKgMeasure &&
                        <p className="food-text mb-0 text-xs">
                            Potrebno kupiti: {ingredient.packages} {translatePackagePlural(ingredient.packages)}
                        </p>
                    }
                    <strong className="food-rating-icon text-theme text-md">{priceText}</strong>

                </div>
            </div>
        </div>
    );
};

// const IngredientModal = ({ingredient}) => {
//     const onClick = () => {
//         window.$("#ingredient").modal('toggle');
//     };
//
//     useEffect(() => {
//         if (ingredient) {
//             window.$("#ingredient").modal('show');
//         }
//     }, [ingredient]);
//
// 	const isKgMeasure = ingredient ? ingredient.baseUnit === 'kg-measure' : false;
//
//     return (
//         <div className="modal fade" id="ingredient" role="dialog"
//              aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-md" role="document">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h4 className="modal-title first-letter-capital">{ingredient?.name ?? ''}</h4>
//                         <button type="button" className="close" data-dismiss="modal" aria-label="Close">
//                             <span aria-hidden="true">&times;</span>
//                         </button>
//                     </div>
//                     <div className="modal-body">
//                         {ingredient &&
//                             <div>
//                                 <img
//                                     className="w-100 radius-10 mb-2"
//                                     src={`${CLOUDFRONT_URL}ingredients/${ingredient.id} Medium.jpeg`}
//                                     alt={`Slika ${ingredient.name}`}
//                                 />
//                             </div>
//                             }
//                             {ingredient && <table className="table table-borderless">
//                             <tbody>
//                             <tr>
//                                 <td>Potrebna količina: </td>
//                                 <td>{ingredient.amountNeeded}
//                                     {ingredient.unitNeeded}</td>
//                             </tr>
//                             {!isKgMeasure && <tr>
//                                 <td>Veličina pakovanja:</td>
//                                 <td>{ingredient.minBuyUnit}
//                                     {ingredient.unit === 'units'
//                                     ? ' ' + pluralForUnits(ingredient.name, ingredient.minBuyUnit)
//                                     : ingredient.unit}</td>
//                             </tr>}
//                             <tr>
//                                 <td>{!isKgMeasure ? 'Cena po pakovanju:' : 'Cena:'} </td>
//                                 <td>{ingredient.costPerUnit.toFixed(2)} RSD {isKgMeasure ? '/ kg' : ''}</td>
//                             </tr>
//                             {!isKgMeasure && <tr>
//                                 <td>Potrebno kupiti: </td>
//                                 <td>{ingredient.packages} {translatePackagePlural(ingredient.packages)}</td>
//                             </tr>}
//                             <tr>
//                                 <td>Ukupna cena za {ingredient.name}: </td>
//                                 <td>{ingredient.totalPrice.toFixed(2)} RSD</td>
//                             </tr>
//                             </tbody>
//                         </table>}
//                     </div>
//                     <div className="modal-footer" style={{display: 'flex'}}>
//                         <button type="button" className="btn btn-success" onClick={onClick}>
//                             Zatvori
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// const IngredientRow = ({ ingridient }) => {
//     const [isCollapsed, setCollapsed] = useState(true);
//     if (isCollapsed) {
//         return (
//             <React.Fragment>
//                 <tr onClick={() => setCollapsed(false)} style={{cursor: 'pointer'}}>
//                     <td><b>{ingridient.name}</b></td>
//                     <td>
//                         Potrebno: {ingridient.amountNeeded}
//                         {ingridient.amountUnit === 'units'
//                             ? ' komada'
//                             : ingridient.amountUnit}
//                     </td>
//                     <td className="custom-price">
//                         <b>{ingridient.totalPrice.toFixed(2)} RSD</b>
//                     </td>
//                 </tr>
//             </React.Fragment>
//         );
//     }
//     if (!isCollapsed) {
//         return (
//             <React.Fragment>
//                 <tr onClick={() => setCollapsed(true)}>
//                     <td>{ingridient.name}</td>
//                     <td>
//                         Potrebno: {ingridient.amountNeeded}
//                         {ingridient.amountUnit === 'units'
//                             ? ' komada'
//                             : ingridient.amountUnit}
//                     </td>
//                     <td className="custom-price">
//                         <a href="#"><u>Ovo imam</u></a>
//                     </td>
//                 </tr>
//                 <tr onClick={() => setCollapsed(true)}>
//                     <td>{ingridient.packages} X</td>
//                     <td>
//                         {ingridient.minBuyUnit} {ingridient.unit}
//                     </td>
//                     <td className="custom-price">
//                         = {ingridient.amountToBuy}
//                         {ingridient.amountUnit === 'units'
//                             ? ' komada'
//                             : ingridient.amountUnit}
//                         {ingridient.wastage && (
//                             <span
//                                 style={{
//                                     fontSize: '12px',
//                                     fontWeight: '300',
//                                     marginLeft: '20px',
//                                 }}
//                             />
//                         )}
//                     </td>
//                 </tr>
//                 <tr
//                     className="checkout-subtotal"
//                     style={{
//                         borderBottom: '1px solid black',
//                     }}
//                     onClick={() => setCollapsed(true)}
//                 >
//                     <td>{ingridient.packages} X </td>
//                     <td>{ingridient.costPerUnit} RSD</td>
//                     <td className="custom-price">
//                         = <b>{ingridient.totalPrice} RSD</b>
//                     </td>
//                 </tr>
//             </React.Fragment>
//         );
//     }
// };

export default IngredientListWrapper;
