import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from 'store/user/user';
import { selectUser } from 'store/user/selector';
import { BLOG_URLS, CALCULATOR_URLS, GLOBAL_URLS, MAIN_URLS, REGISTER_URLS, replaceWildcards } from 'util/routes/urls';

import { CLOUDFRONT_URL } from '../../../util/constants/global';
import { MEAL_MENU_SLUGS } from '../../../util/constants/meals';
import { resultSelector } from '../../../store/callculator/result';
import { lsClearIsLoggedIn } from '../../../util/helpers/localStorage';
const Logo = CLOUDFRONT_URL + 'assets/logo/logov2.png';

const getUsername = (email) => {
	if (email) {
		return email.split('@')[0];
	}
	return '';
};

const Menu = () => {
	const user = useSelector(selectUser);
	const result = useSelector(resultSelector.selectSavedCalculatorResults);

	const [isMobileOpen, setIsMobileOpen] = useState(false);

	const hasResult = result && result.isActive;

	useEffect(() => {
		const $ = window.$;
		let lastScrollTop = '';

		if (isMobileOpen) {
			return;
		}

		const stickyMenu = ($targetMenu, $toggleClass) => {
			const st = $(window).scrollTop();
			if ($(window).scrollTop() > 600) {
				if (st > lastScrollTop) {
					$targetMenu.removeClass($toggleClass);
				} else {
					$targetMenu.addClass($toggleClass);
				}
			} else {
				$targetMenu.removeClass($toggleClass);
			}
			lastScrollTop = st;
		};
		// On scroll activate/deactivate sticky menu
		$(window).on('scroll', function () {
			stickyMenu($('.sticky-header'), 'active');
		});

		// Activate mobile menu
		// setTimeout(() => {
		// 	$('.mobile-menu-active').vsmobilemenu({
		// 		menuContainer: '.vs-mobile-menu',
		// 		expandScreenWidth: 992,
		// 		menuToggleBtn: '.vs-menu-toggle',
		// 	});
		// }, 30);

		return () => {
			if ($) {
				$(window).off('scroll');
			}
		};
	}, [isMobileOpen]);

	return useMemo(
		() => (
			<>
				<StickyHeader
					user={user}
					hasResult={hasResult}
					setIsMobileOpen={setIsMobileOpen}
				/>
				<WebHeader
					user={user}
					hasResult={hasResult}
					setIsMobileOpen={setIsMobileOpen}
				/>
				<MobileMenu
					user={user}
					hasResult={hasResult}
					isMobileOpen={isMobileOpen}
					setIsMobileOpen={setIsMobileOpen}
				/>
			</>
		),
		[user, hasResult, isMobileOpen]
	);
};

const StickyHeader = ({ user, hasResult, setIsMobileOpen }) => {
	const homeLink = MAIN_URLS.HOME;

	return (
		<div className="sticky-header-wrap sticky-header py-1 py-sm-2 py-lg-1" >
			<div className="container-fluid position-relative">
				<div className="row align-items-center" style={{height: '80px', paddingRight: '20px'}}>
					<div className="col-6 col-lg-2">
						<div className="header-logo mb-lg-2 ml-lg-3">
							<Link to={homeLink}>
								<img
									src={Logo}
									alt="Logo"
									style={{ height: '50px' }}
								/>
							</Link>
						</div>
					</div>
					<div className="col-6 col-lg-6 col-xl-5 position-static">
						<nav className="main-menu menu-sticky1 d-none d-lg-block link-inherit">
							<MenuList user={user} hasResult={hasResult} isToolbar={true} />
						</nav>
						<button
							onClick={() => setIsMobileOpen((prevValue) => !prevValue)}
							className="vs-menu-toggle text-theme border-theme d-block d-lg-none ml-auto"
						>
							<i className="far fa-bars" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const WebHeader = ({ user, hasResult, setIsMobileOpen }) => {
	const homeLink = MAIN_URLS.HOME;

	return (
		<header className="header-wrapper header-layout4 position-absolute px-lg-100 ">
			<div className="container-fluid position-relative py-15 py-lg-20">
				<div className="row align-items-center">
					<div className="col-6 col-lg-2">
						<div className="header-logo mb-lg-2 ml-lg-3">
							<Link to={homeLink}>
								<img
									src={Logo}
									alt="Logo"
									style={{ height: '50px' }}
								/>
							</Link>
						</div>
					</div>
					<div className="col-6 col-lg-6 col-xl-5 position-static">
						<nav className="d-none d-lg-block main-menu menu-style3 link-inherit text-xl-left mobile-menu-active dark-style">
							<MenuList user={user} hasResult={hasResult} />
						</nav>

						<button
							type="button"
							className="vs-menu-toggle ml-auto d-block text-theme border-theme d-lg-none"
							onClick={() => {
								setIsMobileOpen((prevValue) => !prevValue)
							}
						}
						>
							<i className="far fa-bars" />
						</button>
					</div>
				</div>
			</div>
		</header>
	);
};

const MenuList = ({ user, hasResult, isToolbar = false }) => {
	const dispatch = useDispatch();
	const onLogout = () => {
		dispatch(logoutUser());
	};

	const location = window.location.pathname;

	const createColor = (url, color = '1px solid #009432') => {
		return location.indexOf(url) !== -1 ? color : 'none'
	}

	return (
		<ul>
			<li className="menu-item">
				<Link
					to={MAIN_URLS.CALCULATOR}
					  style={{
						  borderBottom: createColor(MAIN_URLS.CALCULATOR)
					  }}
				>
					Kalkulator
				</Link>
			</li>
			{user.loggedIn && hasResult &&
				<li className="menu-item">
					<Link to={CALCULATOR_URLS.CALCULATOR_SAVED}
						  style={{
							  borderBottom: createColor(CALCULATOR_URLS.CALCULATOR_SAVED)
						  }}
					>
						Sačuvani rezultati
					</Link>
				</li>
			}
			<li className="menu-item">
				<Link
					to={MAIN_URLS.FRIDGE}
					style={{
						borderBottom: createColor(MAIN_URLS.FRIDGE)
					}}
				>
					Frižider
				</Link>
			</li>
			<li className="menu-item ">
				<button className="menu-item " style={{
					color: isToolbar ? 'inherit' : 'white',
					fontWeight: 600,
					background: 'transparent',
					border: 'none',
					borderBottom: createColor(replaceWildcards(MAIN_URLS.RECIPE_LIST, [''])),
				}}>
					Recepti
				</button>
				<ul className="sub-menu radius-10">
					<li>
						<Link to={replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.mainMeals])}>Glavna jela</Link>
					</li>
					<li>
						<Link to={replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.desserts])}>Dezerti</Link>
					</li>
					<li>
						<Link to={replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.pastries])}>Hleb i kiflice</Link>
					</li>
					<li>
						<Link to={replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.guests])}>Za goste</Link>
					</li>
					<li>
						<Link to={replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.christmas])}>Praznični jelovnik</Link>
					</li>
				</ul>
			</li>
			<li
				className="menu-item "
			>
				<Link
					style={{
						borderBottom: createColor(BLOG_URLS.BLOG_LIST)
					}}
					to={BLOG_URLS.BLOG_LIST}
				>
					Blog
				</Link>
			</li>
			<li
				className="menu-item "
			>
				<Link
					style={{
						borderBottom: createColor(GLOBAL_URLS.RACE_FOR_THE_CURE, '1px solid deeppink')
					}}
					to={GLOBAL_URLS.RACE_FOR_THE_CURE}
				>
					Pink Recepti
				</Link>
			</li>

			{!user.loggedIn && (
				<>

					<li
						className="menu-item d-none d-lg-inline"
						style={{ position: 'absolute', right: '210px' }}
					>
						<Link to={REGISTER_URLS.NEWSLETTER}>Newsletter</Link>
					</li>
					<li
						className="menu-item d-none d-lg-inline"
						style={{ position: 'absolute', right: '120px' }}
					>
						<Link to={REGISTER_URLS.CONTACT_FORM}>Kontakt</Link>
					</li>
					<li
						className="menu-item d-none d-lg-inline"
						style={{ position: 'absolute', right: '50px' }}
					>
						<Link to={REGISTER_URLS.LOGIN}>Prijava</Link>
					</li>
				</>
			)}

			{user.loggedIn && (
				<>
					<li
						className="mega-menu-wrap menu-item-has-children d-none d-lg-inline"
						style={{ position: 'absolute', right: '50px', top: '50px', cursor: 'pointer' }}
					>
						<li className="menu-item " style={{
							color: 'white',
							fontWeight: 600,
						}}>
							Dobro došli, {getUsername(user.email)}
						</li>
						<ul className="sub-menu">
							{/* <li>*/}
							{/*    <Link to={REGISTER_URLS.USER_PROFILE}>Profil</Link>*/}
							{/*</li>*/}
							<li>
								<Link to={REGISTER_URLS.CHANGE_PASSWORD}>
									Promeni lozinku
								</Link>
							</li>
							<li>
								<Link to={REGISTER_URLS.CONTACT_FORM}>Kontakt</Link>
							</li>
							<li onClick={onLogout}>
								<Link to={'#'}>Izloguj se</Link>
							</li>
						</ul>
					</li>
				</>
			)}
		</ul>
	);
};

const MenuListMobile = ({ user, hasResult, setIsMobileOpen }) => {
	const dispatch = useDispatch();
	const onLogout = () => {
		lsClearIsLoggedIn();
		dispatch(logoutUser());
	};

	const location = window.location.pathname;

	const createColor = (url, color = '#009432') => {
		return location.indexOf(url) !== -1 ? color : '#332233'
	}

	const recipesOpenClass = location.indexOf(replaceWildcards(MAIN_URLS.RECIPE_LIST, [''])) !== -1 ? 'show' : '';

	return (
		<ul>
			<li className="menu-item"
			>
				<Link to={MAIN_URLS.CALCULATOR}
					  style={{
						  color: createColor(MAIN_URLS.CALCULATOR)
					  }}
				>
					<i className="fab fa-nutritionix mr-1" style={{width: '40px'}} />
					Kalkulator
				</Link>
			</li>
			{hasResult && user.loggedIn &&
				<li className="menu-item">
					<Link to={CALCULATOR_URLS.CALCULATOR_SAVED}
						  style={{
							  color: createColor(CALCULATOR_URLS.CALCULATOR_SAVED)
						  }}
					>
						<i className="fa fa-save mr-1" style={{width: '40px'}} />
						Sačuvano
					</Link>
				</li>
			}
			<li className="menu-item">
				<Link to={MAIN_URLS.FRIDGE}
					  style={{
						  color: createColor(MAIN_URLS.FRIDGE)
					  }}>
					<i className="fa fa-refrigerator mr-1" style={{width: '40px'}} />
					Frižider
				</Link>
			</li>
			<li className="menu-item mobile-menu">
				<a data-toggle="collapse" href="#collapseMenuRecipes" role="button"
				   aria-expanded="false" aria-controls="collapseMenuRecipes"
				   style={{
					   color: createColor(replaceWildcards(MAIN_URLS.RECIPE_LIST, ['']))
				   }}
				>
					<i className="fa fa-pizza mr-1" style={{width: '40px'}} />
					Recepti
				</a>
				<div className={"collapse mt-2 " + recipesOpenClass} id="collapseMenuRecipes">
					<ul className="sub-menu">
						<li onClick={() => setIsMobileOpen(false)}>
							<Link to={replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.mainMeals])}
								style={{
								   color: createColor(replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.mainMeals]))
							   }}
							>Glavna jela</Link>
						</li>
						<li onClick={() => setIsMobileOpen(false)}>
							<Link to={replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.desserts])}
								style={{
								   color: createColor(replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.desserts]))
							   }}
							>Dezerti</Link>
						</li>
						<li onClick={() => setIsMobileOpen(false)}>
							<Link to={replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.pastries])}
								  style={{
									  color: createColor(replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.pastries]))
								  }}
							>Hleb i kiflice</Link>
						</li>
						<li onClick={() => setIsMobileOpen(false)}>
							<Link to={replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.guests])}
								  style={{
									  color: createColor(replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.guests]))
								  }}
							>Za goste</Link>
						</li>
						<li onClick={() => setIsMobileOpen(false)}>
							<Link to={replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.christmas])}
								  style={{
									  color: createColor(replaceWildcards(MAIN_URLS.RECIPE_LIST, [MEAL_MENU_SLUGS.christmas]))
								  }}
							>
								Praznični jelovnik
							</Link>
						</li>
					</ul>
				</div>

			</li>

			<li
				className="menu-item"
			>
				<i className="fa fa-newspaper mr-1" style={{width: '40px'}} />
				<Link to={BLOG_URLS.BLOG_LIST}
					  style={{
						  color: createColor(BLOG_URLS.BLOG_LIST)
					  }}
				>Blog</Link>
			</li>
			<li
				className="menu-item"
			>
				<i className="fa fa-ribbon mr-1" style={{width: '40px'}} />
				<Link
					style={{
						color: createColor(GLOBAL_URLS.RACE_FOR_THE_CURE, 'deeppink')
					}}
					to={GLOBAL_URLS.RACE_FOR_THE_CURE}
				>
					Pink Recepti
				</Link>
			</li>
			<li
				className="menu-item"
			>
				<i className="fa fa-envelope mr-1" style={{width: '40px'}} />
				<Link to={REGISTER_URLS.NEWSLETTER}
					  style={{
						  color: createColor(REGISTER_URLS.NEWSLETTER)
					  }}
				>Newsletter</Link>
			</li>
			<li
				className="menu-item "
			>
				<i className="fa fa-comment mr-1" style={{width: '40px'}} />
				<Link to={REGISTER_URLS.CONTACT_FORM}
					  style={{
						  color: createColor(REGISTER_URLS.CONTACT_FORM)
					  }}
				>Kontakt</Link>
			</li>

			{!user.loggedIn && (
				<>
					<li
						className="menu-item"
					>
						<i className="fa fa-clipboard-user mr-1" style={{width: '40px'}} />
							<Link to={REGISTER_URLS.LOGIN}
							  style={{
								  color: createColor(REGISTER_URLS.LOGIN)
							  }}
						>Prijava</Link>
					</li>
				</>
			)}

			{user.loggedIn && (
				<>
					<li className="menu-item ">
						<a data-toggle="collapse" href="#collapseMenuLogin" role="button"
						   aria-expanded="false" aria-controls="collapseMenuLogin">
							<i className="fa fa-clipboard-user mr-1" style={{width: '40px'}} />
							{getUsername(user.email)}
						</a>
						<div className="collapse" id="collapseMenuLogin">
							<ul className="sub-menu">
								<li>
									<Link to={REGISTER_URLS.CHANGE_PASSWORD}>
										Promeni lozinku
									</Link>
								</li>
								<li>
									<Link to={REGISTER_URLS.CONTACT_FORM}>Kontakt</Link>
								</li>
								<li onClick={onLogout}>
									<Link to={'#'}>Izloguj se</Link>
								</li>
							</ul>
						</div>

					</li>
				</>
			)}
		</ul>
	);
};

let timeout;
const MobileMenu = ({
	user,
	hasResult,
	isMobileOpen,
	setIsMobileOpen,
}) => {
	const [isOpen, setIsOpen] = useState(isMobileOpen);

	useEffect(() => {
		timeout = setTimeout(() => {
			setIsOpen(isMobileOpen);
			if (isMobileOpen) {
				window.$('body').css('overflow', 'hidden');
			}
		}, 800)
		return () => {
			clearTimeout(timeout);
			window.$('body').css('overflow', 'auto');
		}
	}, [isMobileOpen]);

	return (
		<div className={"mobile-menu-wrapper "}
			 style={{
				 backgroundColor: isMobileOpen  ? 'rgba(0, 41, 22, 0.6)' : 'transparent',
				 width: isOpen || isMobileOpen  ? '100vw' : 0,
				 height: isOpen || isMobileOpen ? 'calc(100vh - 80px)' : 0,
			 }}
		>
			<div className={"mobile-menu-left"}
				 style={{
					 width: isMobileOpen ? '300px' : '0'
				 }}
			>
				{isOpen && isMobileOpen && (
					<MenuListMobile user={user} hasResult={hasResult} setIsMobileOpen={setIsMobileOpen} />
				)}
			</div>
			<div className="mobile-menu-overlay"></div>
		</div>
	);
}

export default Menu;
