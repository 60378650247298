import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { blogApi, getblogListApi } from './api';

const initialState = {
	blogList: [],
	inProgress: false,
	hasMoreBlogs: false,
	offset: 0,
	blogComments: {},
};

const getBlogListAction = createAsyncThunk('blog-list/get', async ({ limit, offset }) => {
	const res = await blogApi.getBlogListAPI(limit, offset);
	return { data: res.data, offset };
});

const getBlogCommentsAction = createAsyncThunk('blog-comments/get', async ({ blogId }) => {
	const res = await blogApi.getBlogCommentsAPI(blogId);
	return { data: res.data, blogId };
});

export const blogListReducer = createSlice({
	name: 'BLOG_LIST',
	initialState,
	reducers: {
		changeOffsetAction: (state, action) => {
		 	state.offset = action.payload ?? 0;
		}
	},
	extraReducers: {
		[getBlogListAction.pending]: (state) => {
			state.inProgress = true;
		},
		[getBlogListAction.fulfilled]: (state, args) => {
			const {payload} = args;

			if (payload.offset === 0) {
				state.blogList = [...payload.data];
			} else {
				state.blogList = [...state.blogList, ...payload.data];
			}
			state.hasMoreBlogs = payload.data.length !== 0;
			state.inProgress = false;
		},
		[getBlogListAction.rejected]: (state) => {
			state.inProgress = false;
		},

		[getBlogCommentsAction.pending]: (state) => {
			state.inProgress = true;
		},
		[getBlogCommentsAction.fulfilled]: (state, args) => {
			const {payload} = args;

			state.blogComments = {
				...state.blogComments,
				[payload.blogId]: payload.data,
			};

			state.inProgress = false;
		},
		[getBlogCommentsAction.rejected]: (state) => {
			state.inProgress = false;
		},
	},
});

const { changeOffsetAction } = blogListReducer.actions;

// Action creators are generated for each case reducer function
export { getBlogListAction, getBlogCommentsAction, changeOffsetAction };

export default blogListReducer.reducer;
