import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOffsetAction } from '../store/recipes/recipeList';
import { selectRecipesOffset } from '../store/recipes/selector';

const LIMIT = 12;

const useInfiniteScroll = (inProgress, hasMore) => {
	const dispatch = useDispatch();

	const offset = useSelector(selectRecipesOffset);

	const setOffset = (value) => {
		dispatch(changeOffsetAction(value));
	};

	const observer = useRef();

	const lastElementRef = useCallback(
		(node) => {
			if (inProgress) {
				return;
			}

			if (observer.current) {
				observer.current.disconnect();
			}

			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasMore) {
					setOffset(offset + LIMIT);
				}
			});

			if (node) {
				observer.current.observe(node);
			}
		},
		[inProgress, hasMore]
	);

	return [lastElementRef, offset, setOffset];
};

export { useInfiniteScroll };
