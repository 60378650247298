import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fridgeApi } from './fridge.api';
import { replaceCyrilic } from '../../util/helpers/string';

const selectIngredients = [
    '619a850a3330df1c6f1b01b1', // Ulje
    '619a86523330df1c6f1b01d3', // Biber
    '61acf7ef03745a86380753ae', // Secer
    '619a957e3330df1c6f1b0335', // So
];

const EMPTY_DATA = {
    inProgress: false,
    data: [],
    selectedNumber: 0,
};

const initialState = {
    ingredientList: EMPTY_DATA,
    recipeList: EMPTY_DATA,
};

const getFridgeIngredientsListAction = createAsyncThunk('fridge/getIngredients', async () => {
    const res = await fridgeApi.getIngredientListApi();
    return { data: res.data.map((ingredient) => {
            if (selectIngredients.indexOf(ingredient.id) !== -1){
                return {
                    ...ingredient,
                    nonCyrilicName: replaceCyrilic(ingredient.name).toLowerCase(),
                    selected: true
                };
            }
            return {...ingredient, nonCyrilicName: replaceCyrilic(ingredient.name).toLowerCase()};
        }).sort((a, b) => a.name.localeCompare(b.name)),
    };
});

const getFridgeRecipeListAction = createAsyncThunk('fridge/getRecipes', async () => {
    const res = await fridgeApi.getRecipeListApi();

    return { data: res.data.map((recipe) => {
            let totalSelected = 0;
            recipe.ingredients = recipe.ingredients.map((ingredient) => {

                if (selectIngredients.indexOf(ingredient.id) !== -1) {
                    if (!ingredient.selected) {
                        totalSelected++;
                    }

                    return {
                        ...ingredient,
                        selected: true,
                    };
                }

                return {...ingredient};
            });

            return {
                ...recipe,
                selected: totalSelected === recipe.ingredients.length,
                oneMissing: totalSelected === recipe.ingredients.length - 1,
                twoMissing: totalSelected === recipe.ingredients.length - 2,
            };
        }),
    };
});

export const FridgeReducer = createSlice({
    name: 'FRIDGE',
    initialState,
    reducers: {
        toggleIngredientSelection: (state, action) => {
            const id = action.payload.id;

            // TODO improve this entire system by separating list of ids with data
            /* Ingredient number */
            let numberOfSelectedIngredients = 0;
            state.ingredientList.data = state.ingredientList.data.map((ingredient) => {
                if (ingredient.id === id) {
                    if (!ingredient.selected) {
                        numberOfSelectedIngredients++;
                    }

                    return {
                        ...ingredient,
                        selected: !ingredient.selected,
                    };
                }

                if (ingredient.selected) {
                    numberOfSelectedIngredients++;
                }

                return ingredient;
            });
            state.ingredientList.selectedNumber = numberOfSelectedIngredients;

            /* Recipe section */
            let numberOfSelectedRecipes = 0;
            let numberOfMissingOneOrTwo = 0;
            state.recipeList.data = state.recipeList.data.map((recipe) => {
                let changed = false;
                let totalSelected = 0;

                recipe.ingredients = recipe.ingredients.map((ingredient) => {
                    if (ingredient.id === id) {
                        changed = true;

                        if (!ingredient.selected) {
                            totalSelected++;
                        }

                        return {
                            ...ingredient,
                            selected: !ingredient.selected,
                        };
                    }

                    if (ingredient.selected) {
                        totalSelected++;
                    }

                    return ingredient;
                });

                if (changed) {
                    if (totalSelected === recipe.ingredients.length) {
                        numberOfSelectedRecipes++;
                    }

                    if (totalSelected === recipe.ingredients.length - 1
                    || totalSelected === recipe.ingredients.length - 2) {
                        numberOfMissingOneOrTwo++;
                    }

                    return {
                        ...recipe,
                        selected: totalSelected === recipe.ingredients.length,
                        oneMissing: totalSelected === recipe.ingredients.length - 1,
                        twoMissing: totalSelected === recipe.ingredients.length - 2,
                        totalSelected,
                    };
                }

                if (recipe.selected) {
                    numberOfSelectedRecipes++;
                }

                if (recipe.oneMissing || recipe.twoMissing) {
                    numberOfMissingOneOrTwo++;
                }

                return recipe;
            });
            state.recipeList.selectedNumber = numberOfSelectedRecipes;
            state.recipeList.missingOneOrTwo = numberOfMissingOneOrTwo;
        }
    },
    extraReducers: {
        // Ingredients
        [getFridgeIngredientsListAction.pending]: (state) => {
            state.ingredientList.inProgress = true;
        },
        [getFridgeIngredientsListAction.fulfilled]: (state, args) => {
            const {payload} = args;

            state.ingredientList.data = payload.data;
            state.ingredientList.inProgress = false;
            state.ingredientList.object = payload.data.reduce((accumulator, ingredient) => ({ ...accumulator, [ingredient.id]: ingredient}), {});
        },
        [getFridgeIngredientsListAction.rejected]: (state) => {
            state.ingredientList.inProgress = false;
        },

        // Recipes
        [getFridgeRecipeListAction.pending]: (state) => {
            state.recipeList.inProgress = true;
        },
        [getFridgeRecipeListAction.fulfilled]: (state, args) => {
            const {payload} = args;

            let numberOfSelectedRecipes = 0;
            let numberOfMissingOneOrTwo = 0;

            payload.data.forEach((recipe) => {
                if (recipe.selected) {
                    numberOfSelectedRecipes++;
                }
                if (recipe.oneMissing || recipe.twoMissing) {
                    numberOfMissingOneOrTwo++;
                }
            });

            state.recipeList.data = payload.data;
            state.recipeList.inProgress = false;
            state.recipeList.selectedNumber = numberOfSelectedRecipes;
            state.recipeList.missingOneOrTwo = numberOfMissingOneOrTwo;
        },
        [getFridgeRecipeListAction.rejected]: (state) => {
            state.recipeList.inProgress = false;
        },
    },
});

const { toggleIngredientSelection } = FridgeReducer.actions;

// Action creators are generated for each case reducer function
export const fridgeActions = {
    getFridgeRecipeListAction,
    getFridgeIngredientsListAction,
    toggleIngredientSelection,
};

export default FridgeReducer.reducer;
