import React, {useEffect} from 'react';
import Api from "../Api";
import {SERVER_URLS} from "../../util/routes/serverUrls";
import {setUser} from "../../store/user/user";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../store/user/selector";
import { getSavedRecipesAction } from '../../store/callculator/result';
import { lsClearIsLoggedIn, lsGetIsLoggedIn, lsSaveIsLoggedIn } from '../../util/helpers/localStorage';

const UserWrapper = ({children}) => {
    const dispatch = useDispatch();

    const user = useSelector(selectUser);

    useEffect(() => {
        if (user.email !== '') {
            dispatch(getSavedRecipesAction());
            return;
        }

        // If user is logged in by cookie, but we don't have server info, get server info
        if (!user.loggedIn) {
            const hasUserCookie = lsGetIsLoggedIn();
            if (hasUserCookie) {
                Api.get(SERVER_URLS.GET_USER)
                    .then((response) => {
                        // TODO Since email is only data we have, perhaps move all of it to coookie?
                        if (response && response.data) {
                            dispatch(setUser({ email: response.data.email }));
                            lsSaveIsLoggedIn();
                            return;
                        }
                        lsClearIsLoggedIn();
                    }).catch(() => {
                    lsClearIsLoggedIn();
                });
            }
        }
    }, [user]);

    return (
        <div>
            {children}
        </div>
    );
};

export default UserWrapper;