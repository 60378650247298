import { createSelector } from "@reduxjs/toolkit";

const selectWeeklyInProgress = createSelector(
	(state) => state.weekly,
	(weekly) => weekly.inProgress,
);

const selectWeeklyHasToday = createSelector(
	(state) => state.weekly,
	(weekly) => weekly.hasToday,
);

const selectWeeklyToday = createSelector(
	(state) => state.weekly,
	(weekly) => weekly.day,
);

const selectWeeklyWeek = createSelector(
	(state) => state.weekly,
	(weekly) => weekly.week,
);


export const weeklySelector = {
	selectWeeklyInProgress,
	selectWeeklyHasToday,
	selectWeeklyToday,
	selectWeeklyWeek,
};
