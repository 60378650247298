export const baseUnitToUnitRatio = (unit, unitForPrice, baseUnit) => {
    if (!baseUnit || baseUnit === 'un' || !unitForPrice) {
        return 1;
    }
    if (unit === unitForPrice) {
        return 1;
    }

    if (baseUnit.indexOf('kg') !== -1) {
        if (unit === 'g' && unitForPrice === 'kg') {
            return 0.001;
        }
        if (unit === 'kg' && unitForPrice === 'g') {
            return 1000;
        }
    }
    if (baseUnit === 'l') {
        if (unitForPrice === 'l') {
            if (unit === 'dl') {
                return 0.1;
            }
            if (unit === 'ml') {
                return 0.001;
            }
        }

        if (unitForPrice === 'dl') {
            if (unit === 'l') {
                return 10;
            }
            if (unit === 'ml') {
                return 0.01;
            }
        }

        if (unitForPrice === 'ml') {
            if (unit === 'l') {
                return 1000;
            }
            if (unit === 'dl') {
                return 10;
            }
        }
    }
    return 1;
};
