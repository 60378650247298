export const SERVER_URLS = {
	REGISTER: '/register',
	LOGIN: '/login',
	LOGOUT: '/logout',
	ACTIVATE: '/users/validate-code',
	CHANGE_PASSWORD: '/user/update-password',
	RESET_PASSWORD: '/users/reset-password-email',
	RESET_PASSWORD_CHECK_CODE: '/users/reset-password-code',
	RESET_PASSWORD_SEND_NEW: '/users/reset-password',

	GET_USER: '/get-user-info',
	GET_RECIPE_BY_ID: '/get-recipe-by-id',
	GET_RECIPE_BY_SLUG: '/get-recipe-by-slug',

	CALCULATE: '/calculate-recipe',
	SAVE_RESULTS: '/save-results',
	GET_RESULTS: '/get-results',

	GET_RECIPE_LIST: '/recipe/list',
	GET_TAG_LIST: '/recipe/get-tag-list',
	GET_INGREDIENT_LIST: '/recipe/get-ingredient-list',

	POST_CONTACT_FORM: '/contact/form',
	POST_NEWSLETTER: '/contact/newsletter',

	// Fridge urls
	GET_FRIDGE_INGREDIENT_LIST: '/fridge/get-ingredients',
	GET_FRIDGE_RECIPE_LIST: '/fridge/get-recipes',

	// Home urls
	GET_DALY_RECOMMENDATION_URL: '/recommendation/daily',

	// Blog URLS
	GET_BLOG_LIST_URL: '/blog/get-list',
	GET_BLOG: '/blog/get-blog',
	BLOG_VISIT_LOG: '/blog/visit-blog',
	POST_COMMENT: '/blog/post-comment',
	GET_COMMENTS: '/blog/get-comments',

	// Weekly URLS
	GET_WEEKLY_DAY: '/weekly/get-today',
	GET_WEEKLY_WEEK: '/weekly/get-week',

	// Pink urls
	PINK_LOG_DATA: '/pink/get',

	// Log url
	LOG_PREMIER: '/premier/log',

};
