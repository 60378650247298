const sr = {
    home: 'Početna',
    about: 'Opis',
    contact: 'Kontakt',
    calculator: 'Kalkulator',
    eeee: 'aaaaa',

};

const en = {
    home: 'Home',
    about: 'About',
    contact: 'Contact',
    calculator: 'Calculator',
    eeee: 'aaaaa',
};

const translate = {sr, en};

export default translate;