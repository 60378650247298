import React, { useState } from 'react';
import FridgeIngredientList from './FridgeIngredientList';
import FridgeRecipeList from './FridgeRecipeList';

const FridgeComponent = ({
    ingredientList,
    recipeList,
}) => {
    return (
        <section className="vs-contact-wrapper vs-contact-layout1 pb-60" id="fridge-main-content" style={{opacity: 0}}>
            <div className="container">
                <div
                    className="inner-wrapper  "
                >
                    <div>
                        <div className="col-lg-12 pt-0">


                            <FridgeIngredientList
                                ingredientList={ingredientList}
                            />

                        </div>
                        <section className="vs-food-box-wrapper food-box-layout3 position-relative link-inherit py-20 py-lg-20">
                            <div className="container overflow-hidden">

                                <FridgeRecipeList
                                    recipeList={recipeList}
                                />

                            </div>
                        </section>
                    </div>
                </div>
            </div>
            
        </section>
    );
};

export default FridgeComponent;