import React, { useState } from 'react';
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { MAIN_URLS } from "../../../util/routes/urls";
import Api from "../../../main/Api";
import { SERVER_URLS } from "../../../util/routes/serverUrls";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/user/user";
import RegisterLayout from '../../layouts/RegisterLayout';
import { CLOUDFRONT_URL } from '../../../util/constants/global';

const SERVER_ERROR_TRANSLATION = {
	'code_format_invalid': 'Kod koji ste poslali nije validan.',
	'no_user_code': 'Kod koji ste poslali nije validan.',
	'wrong_code': 'Kod koji ste poslali nije validan.',
	'user_invalid': 'User is not valid.',
};

export const CodeActivation = ({ email }) => {
	const Login1 = CLOUDFRONT_URL + 'assets/register/register.jpeg';

	const dispatch = useDispatch();

	const [isActive, setIsActive] = useState(false);
	const [inProgress, setInProgress] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [form, setForm] = useState({
		code: '',
	});

	const onFormChange = (e) => {
		if (errorMessage) {
			setErrorMessage(false);
		}

		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const submitCode = (e) => {
		e.preventDefault();

		if (!form.code) {
			setErrorMessage('Polje Aktivacioni kod ne može biti prazno');
			return;
		}

		setInProgress(true);

		Api.post(SERVER_URLS.ACTIVATE, { code: form.code, email })
			.then((response) => {
				if (response?.data?.status) {
					dispatch(setUser({ email: response.data.email }));
					setIsActive(true);
				} else {
					if (response?.data?.message) {
						setErrorMessage(SERVER_ERROR_TRANSLATION[response.data.message]);
					}
				}

				setInProgress(false);
			})
			.catch((e) => {
				setErrorMessage(e.response.data);
				setInProgress(false);
			});
	};

	if (isActive) {
		return (
			<RegisterLayout
				isRegister={true}
				form={{email}}
				submitRegister={submitCode}
				image={Login1}
			>
				<div className="sec-subtitle text-theme h3 color-green text-center">
					Aktivacija koda
				</div>
				<div className="py-20 text-semibold">
					Uspešno ste aktivirali vaš nalog!
				</div>

				<div className="form-group mb-0 text-center">
					<Link to={MAIN_URLS.HOME}>
						<button
							style={{borderRadius: '12px'}}
							className="vs-btn w-100 mb-0 mask-style1"
							type="submit"
						>
							Početna strana
						</button>
					</Link>
				</div>
			</RegisterLayout>
		);
	}

	return (
		<RegisterLayout
			isRegister={true}
			form={{email}}
			submitRegister={submitCode}
			image={Login1}
		>
			<div className="sec-subtitle text-theme h3 color-green text-center">
				Aktivacija koda
			</div>
			<div className="py-20 text-semibold">
				Neophodno je da aktivirate nalog. <br />
				Unesite u polje ispod aktivacioni kod koji je poslat na vaš email
			</div>
			<div className="form-group"
				 style={{
					 marginTop: '4px',
					 marginBottom: '4px'
				 }}
			>
				<label
					htmlFor="signUpUserEmail"
					className="sr-only"
				>
					Aktivacioni kod
				</label>
				<input
					style={{
						background: '#f1f1f1',
						borderTopRightRadius: '12px',
						borderTopLeftRadius: '12px',
						marginBottom: '4px'
					}}
					type="text"
					className="form-control"
					placeholder="Aktivacioni kod*"
					name="code"
					value={form.code}
					onChange={onFormChange}
				/>
			</div>

			{errorMessage && (
				<div className="text-red mb-3">
					{errorMessage}
				</div>
			)}
			<div className="form-group mb-0 text-center">
				<button
					style={{
						borderTopRightRadius: '0',
						borderTopLeftRadius: '0',
						borderBottomRightRadius: '12px',
						borderBottomLeftRadius: '12px',
					}}
					className="vs-btn w-100 mb-0 mask-style1"
					type="submit"
				>
					{!inProgress && <span>Aktivirajte nalog</span>}
					{inProgress && (
						<Loader
							type={'TailSpin'}
							height={'20px'}
							color={'green'}
						/>
					)}
				</button>
			</div>
		</RegisterLayout>
	);
};
