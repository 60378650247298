import { createSelector } from "@reduxjs/toolkit";

const selectRecipeList = createSelector(
	(state) => state.recipeList,
	(recipeList) => recipeList.recipeList
);

const selectRecipeListInProgress = createSelector(
	(state) => state.recipeList,
	(recipeList) => recipeList.inProgress
);

const selectHasMoreRecipes = createSelector(
	(state) => state.recipeList,
	(recipeList) => recipeList.hasMoreRecipes
);

const selectRecipesOffset = createSelector(
	(state) => state.recipeList,
	(recipeList) => recipeList.offset
);

const selectRecipesCategoryId = createSelector(
	(state) => state.recipeList,
	(recipeList) => recipeList.categoryId
);


export { selectRecipeList, selectRecipeListInProgress, selectHasMoreRecipes, selectRecipesOffset, selectRecipesCategoryId };
