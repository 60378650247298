import React, { useEffect } from 'react';

import Menu from 'components/pages/Menu/Menu';
import Hero from 'components/pages/Home/Hero';
import About from 'components/pages/Home/AboutV2';
// import HowToUse from 'components/pages/Home/HowToUse';
import Footer from 'components/pages/Menu/Footer';
import ScrollToTop from 'components/pages/Menu/ScrollToTop';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { homeSelector } from '../store/home/home.slector';
import { homeActions } from '../store/home/home.reducer';
// import SummerMenu from '../components/pages/Home/SummerMenu';
// import SpringMenu from '../components/pages/Home/SpringMenu';
import WeeklyRecipesV2 from '../components/pages/Home/WeeklyRecipesV2';
import WinterMenu from '../components/pages/Home/WinterMenu';
import Top50Reward from '../components/pages/Home/Top50Reward';
import ValentineMenu from '../components/pages/Home/ValentineMenu';
import PremierMenu from '../components/pages/Home/PremierMenu';

const Home = () => {
    const dispatch = useDispatch();
    const dailyRecommendation = useSelector(homeSelector.selectHomeDailyRecipe);

    useEffect(() => {
        if (!dailyRecommendation) {
            dispatch(homeActions.getHomeDailyRecipeAction());
        }
    }, []);

    return (
        <div>
            <HomeHelmet />
            <Menu />
            <ScrollToTop />
            <Hero />

            {/*<PremierMenu />*/}

            <WeeklyRecipesV2 />



            <About />


            <Top50Reward />

            {/*<SummerMenu />*/}
            {/*<SpringMenu />*/}
            {/*<HowToUse />*/}
            {/*<Testimonials />*/}
            {/*<ValentineMenu />*/}
            {/*<WinterMenu />*/}

            <Footer />
        </div>
    );
};

const HomeHelmet = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Stomačko</title>
            <meta property='title' content="Stomačko" />
            <meta property='description' content="Najbolji proizvod za pun stomak i novčanik" />
            <meta property="og:site_name" content = "Stomačko" />

            <meta property='og:title' content="Stomačko" />
            <meta property="og:type" content='website'/>
            <meta property='og:description' content="Najbolji proizvod za pun stomak i novčanik" />

            <meta property="og:image" content="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/assets/public/og-image.png" />
            <meta property="og:image:width" content="378" />
            <meta property="og:image:height" content="156" />
            <meta property='og:url' content={`https://stomacko.com/pocetna`} />

            <meta property='twitter:title' content="Stomačko" />
            <meta property='twitter:image' content="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/assets/public/og-image.png" />
            <meta property='twitter:url' content={`https://stomacko.com/pocetna`} />

            <meta name='keywords' content="stomacko kalkulator, recepti, frizider, jelo, sta jesti, usteda na jelu, hrana, rucak, dezert, slatkisi, hleb" />
        </Helmet>
    );
}

export default Home;
