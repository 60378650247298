import Api from 'main/Api';
import { SERVER_URLS } from 'util/routes/serverUrls';

export const LOG_CATEGORY = 'premier';
export const LOG_ACTIONS = {
    recipe: 'open-recipe',
    list: 'open-list',
    product: 'open-product',
}

const log = (category, action, label) => {
    return Api.post(SERVER_URLS.LOG_PREMIER, {
        category, action, label
    });
};

export const logApi = {
    log,
    LOG_CATEGORY,
    LOG_ACTIONS,
};
