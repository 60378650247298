import React from 'react';
import { Link } from 'react-router-dom';

import { MAIN_URLS } from 'util/routes/urls';
import { translate } from 'util/helpers/translation';
import menu_translate from 'translation/menu';
import { CLOUDFRONT_URL } from '../../../util/constants/global';

const HeaderBackground = CLOUDFRONT_URL + 'assets/general/header2%20Large.avif';

const t = translate(menu_translate, window.lang); // TODO Helper function

const Header = ({ current, listOfPrevious, nomb = false, ImageBackground = null }) => {
    const topDivClass = nomb ? '' : ' mb-30';
    return (
        <div
            className={"breadcumb-wrapper breadcumb-layout1 background-image link-inherit " + topDivClass}
            data-overlay="black"
            data-opacity="1"
            style={{
                backgroundColor: '#111',
                backgroundImage: 'url(' + (ImageBackground ?? HeaderBackground) + ')'
            }}
        >
            <div className="container z-index-common">
                <div className="breadcumb-content text-center pt-65 pt-lg-105 pb-95 pb-lg-100">
                    <h1 className="breadcumb-title sec-title1 text-white my-0">
                        {t(current)}
                    </h1>
                    <ul className="breadcumb-menu-style1 bg-white" style={{
                        boxShadow: '0px 1px 10px 0px rgb(0 0 0 / 15%)',
                    }}>
                        <li key={-1}>
                            <Link to={MAIN_URLS.HOME}>
                                <i className="fal fa-home text-theme" />
                                {t('home')}
                            </Link>
                        </li>
                        {listOfPrevious &&
                            listOfPrevious.map((item, index) => {
                                if (item.text && item.link) {
                                    return (
                                        <li key={index}>
                                            <Link to={item.link}>
                                                {item.text}
                                            </Link>
                                        </li>
                                    );
                                }
                                return null;
                            })}
                        <li className="active">{t(current)}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header;
