import React, { useCallback, useState } from 'react';
import { fridgeActions } from '../../../store/fridge/fridge.reducer';
import { useDispatch } from 'react-redux';
import { replaceCyrilic } from '../../../util/helpers/string';

const FridgeIngredientList = ({ingredientList}) => {
    const [filter, setFilter] = useState();
    const dispatch = useDispatch();

    const onIngredientClick = useCallback((ingredient) => {
        dispatch(fridgeActions.toggleIngredientSelection(ingredient));
    }, []);

    let numberOfIngredientsInList = 0;

    const lowerCaseNonCyrilicFilterFnc = useCallback(() => {
        if (!filter) {
            return '';
        }
        return replaceCyrilic(filter).toLowerCase();
    }, [filter]);

    const lowerCaseNonCyrilicFilter = lowerCaseNonCyrilicFilterFnc();

    const isMobile = window.innerWidth < 991;

    return (
        <>
            <div >
                <div className="mb-3 fridge-ingredient-container">
                {ingredientList.length > 0 && ingredientList.map((ingredient) => {
                    if (!ingredient.selected) {
                        return null;
                    }

                    return (
                        <span
                            key={ingredient.id}
                            onClick={() => {
                                onIngredientClick(ingredient);
                            }}
                            className="radius-10 no-break d-inline-block fridge-ingredient-selected"
                        >
                            {ingredient.name.toLowerCase()}
                        </span>
                    );
                })}

                </div>

                <div style={isMobile ? {
                    display: 'flex',
                    justifyContent: 'center',
                }: {}
                }>
                    <span
                        className={isMobile ? "radius-10 no-break d-inline-block fridge-ingredient" : "radius-10 no-break d-inline-block"}
                        style={isMobile ? {
                            position: 'relative',
                            top: '-10px',
                            marginBottom: '10px',
                        } :{
                            position: 'absolute',
                            right: 0,
                            top: '-40px',
                            color: '#009432',
                            cursor: 'pointer',
                            '&:hover': {
                                textStyle: 'bold',
                                color: 'green'
                            }
                        }}
                        onClick={() => {
                            ingredientList.forEach((ingredient) => {
                                if (ingredient.selected) {
                                    onIngredientClick(ingredient);
                                }
                            })
                        }}
                    >
                        Obriši sve izabrano
                    </span>
                </div>

                <div className="col-lg-12 d-flex align-self-center justify-content-center">
                    <div>
                        <input
                            style={{
                                width: '300px',
                                height: '40px',
                                paddingLeft: "10px",
                                marginBottom: '20px',
                            }}
                            placeholder="Pretraga sastojaka"
                            className="radius-10"
                            type="text"
                            onChange={(e) => {
                                setFilter(e.currentTarget.value);
                            }}
                        />
                    </div>
                </div>

                <div style={{minHeight: '200px'}}>
                    <div className="mb-3 fridge-ingredient-container">
                    {ingredientList.length > 0 && ingredientList.map((ingredient) => {
                        if (ingredient.selected) {
                            return null;
                        }
                        if (filter) {
                            if (ingredient.nonCyrilicName.indexOf(lowerCaseNonCyrilicFilter) === -1) {
                                return null;
                            }
                        }

                        numberOfIngredientsInList++;
                        const additionalClass = ingredient.selected ? '-selected' : '';

                        return (
                            <span
                                key={ingredient.id}
                                onClick={() => {
                                    onIngredientClick(ingredient);
                                }}
                                className={`radius-10 no-break d-inline-block fridge-ingredient${additionalClass}`}
                            >
                            {ingredient.name.toLowerCase()}
                        </span>
                        );
                    })}
                    </div>
                </div>

                {numberOfIngredientsInList === 0 && (
                    <div className="text-center" style={{marginTop: '-200px'}}>Ne postoje sastojci u Stomačko kuhinji sa unetim imenom u filteru.</div>
                )}

            </div>
        </>
    );
};

export default FridgeIngredientList;