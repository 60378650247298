import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getRecipeListAction } from "../store/recipes/recipeList";
import {
	selectRecipeList,
	selectRecipeListInProgress,
	selectHasMoreRecipes,
	selectRecipesCategoryId,
} from '../store/recipes/selector';
import Menu from "../components/pages/Menu/Menu";
import Header from "../components/pages/General/Header";
import ScrollToTop from "../components/pages/Menu/ScrollToTop";
import Footer from "../components/pages/Menu/Footer";
import { RecipeListMain } from "../components/pages/RecipeList/RecipeList";
import { useParams } from "react-router";
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { Helmet } from 'react-helmet';
import { MEAL_MENU_SLUG_TO_CATEGORY, MEAL_MENU_SLUG_TO_TITLE } from '../util/constants/meals';
import { CLOUDFRONT_URL } from '../util/constants/global';
import ReactGA from 'react-ga4';
import { logApi } from '../store/log/log.api';

const RecipeList = () => {
	const dispatch = useDispatch();

	const params = useParams();

	const inProgress = useSelector(selectRecipeListInProgress);
	const filters = useSelector((state) => state.recipeList.filters);
	const recipeList = useSelector(selectRecipeList);
	const hasMoreRecipes = useSelector(selectHasMoreRecipes);
	const lastCategoryId = useSelector(selectRecipesCategoryId);

	const [lastElementRef, offset, setOffset] = useInfiniteScroll(inProgress, hasMoreRecipes);

	useEffect(() => {
		if (params?.categoryId) {
			const filtersToSend = {};

			const slugToCategory = MEAL_MENU_SLUG_TO_CATEGORY[params.categoryId];

			if (lastCategoryId !== slugToCategory && !inProgress) {
				setOffset(0);
				dispatch(getRecipeListAction({
					...filtersToSend,
					categoryId: slugToCategory,
					limit: 12,
					offset: 0,
				}));
			}
		}
	}, [params.categoryId]);

	useEffect(() => {
		if (params?.tagName) {
			const filtersToSend = {};

			// TODO Add aditional condition
			if (!inProgress) {
				setOffset(0);
				dispatch(getRecipeListAction({
					...filtersToSend,
					tags: [params.tagName],
					limit: 30,
					offset: 0,
				}));
			}

			// Send a custom event
			ReactGA.event({
				category: logApi.LOG_CATEGORY,
				action: logApi.LOG_ACTIONS.list,
				label: 'Premier recepti', // optional
				value: 99, // optional, must be a number
				nonInteraction: true, // optional, true/false
				transport: "xhr", // optional, beacon/xhr/image
			});

			logApi.log(logApi.LOG_CATEGORY, logApi.LOG_ACTIONS.list, 'Premier recepti');
		}
	}, [params.tagName]);

	useEffect(() => {
		if (offset !== 0 && recipeList.length === offset) {
			dispatch(getRecipeListAction({ ...filters, categoryId: MEAL_MENU_SLUG_TO_CATEGORY[params.categoryId], limit: 12, offset }));
		}
	}, [offset]);


	return (
		<>
			{params.categoryId && <ListHelmet category={params.categoryId} />}
			{params.tagName && <ListHelmet tag={params.tagName} />}
			
			<Menu />
			<Header current={'Recepti'} />

			<RecipeListMain
				recipeList={recipeList && recipeList}
				ref={lastElementRef}
				inProgress={inProgress}
			/>

			<ScrollToTop />
			<Footer />
		</>
	);
};

const ListHelmet = ({category, tag}) => {
	if (tag) {
		return (
			<Helmet>
				<meta charSet="utf-8" />
				<title>Stomačko recepti</title>
				<meta property='title' content={`Stomačko recepti - ${tag}`} />
				<meta property='description' content="Stomačko recepti" />
				<meta property="og:site_name" content = "Stomačko" />

				<meta property='og:title' content={`Stomačko recepti - ${tag}`} />
				<meta property="og:type" content='website'/>
				<meta property='og:description' content="Stomačko recepti" />

				<meta property='og:image' content={CLOUDFRONT_URL + 'assets/public/how-to-use2.jpeg'} />
				<meta property='og:image:type' content="image/jpeg" />
				<meta property='og:image:width' content="358" />
				<meta property='og:image:height' content="381" />
				<meta property='og:url' content={`https://stomacko.com/recepti/tag/${tag}`} />

				<meta property='twitter:title' content={`Stomačko recepti - ${tag}`} />
				<meta property='twitter:image' content={CLOUDFRONT_URL + 'assets/public/how-to-use2.jpeg'} />
				<meta property='twitter:url' content={`https://stomacko.com/recepti/tag/${tag}`} />

				<meta name='keywords' content={`recepti, lista recepata, ${tag}`} />
			</Helmet>
		)
	}
	return (
		<Helmet>
			<meta charSet="utf-8" />
			<title>Stomačko recepti - {MEAL_MENU_SLUG_TO_TITLE[category]}</title>
			<meta property='title' content={`Stomačko recepti - ${MEAL_MENU_SLUG_TO_TITLE[category]}`} />
			<meta property='description' content="Stomačko recepti" />
			<meta property="og:site_name" content = "Stomačko" />

			<meta property='og:title' content={`Stomačko recepti - ${MEAL_MENU_SLUG_TO_TITLE[category]}`} />
			<meta property="og:type" content='website'/>
			<meta property='og:description' content="Stomačko recepti" />

			<meta property='og:image' content={CLOUDFRONT_URL + 'assets/public/how-to-use2.jpeg'} />
			<meta property='og:image:type' content="image/jpeg" />
			<meta property='og:image:width' content="358" />
			<meta property='og:image:height' content="381" />
			<meta property='og:url' content={`https://stomacko.com/recepti/${category}`} />

			<meta property='twitter:title' content={`Stomačko recepti - ${MEAL_MENU_SLUG_TO_TITLE[category]}`} />
			<meta property='twitter:image' content={CLOUDFRONT_URL + 'assets/public/how-to-use2.jpeg'} />
			<meta property='twitter:url' content={`https://stomacko.com/recepti/${category}`} />

			<meta name='keywords' content={`recepti, lista recepata, ${MEAL_MENU_SLUG_TO_TITLE[category]}`} />
		</Helmet>
	)
}

export default RecipeList;
