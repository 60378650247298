import React, { useEffect } from 'react';

import Menu from 'components/pages/Menu/Menu';
import ScrollToTop from 'components/pages/Menu/ScrollToTop';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { homeSelector } from '../store/home/home.slector';
import { homeActions } from '../store/home/home.reducer';
import HeaderV3 from '../components/pages/Home/V3/HeaderV3';

/*
Slider ima
kalkulator
sezonski recepti
frizider
pink recepti
 */


const Home = () => {
    const dispatch = useDispatch();
    const dailyRecommendation = useSelector(homeSelector.selectHomeDailyRecipe);

    useEffect(() => {
        if (!dailyRecommendation) {
            dispatch(homeActions.getHomeDailyRecipeAction());
        }
    }, []);

    return (
        <div style={{
            background: 'linear-gradient(0deg, rgba(255,255,255,1) 32%, rgba(0,148,50,1) 48%, rgba(0,148,50,1) 100%)', //'#f8f8f8'
        }}>
            <HomeHelmet />
            <div className="home">
                <Menu />
            </div>
            <ScrollToTop />



            <HeaderV3 />
            {/*<NewWeeklyV3 />*/}
            {/*<NewPresentationV3 />*/}
            {/*<NewMobileAppSoonV3 />*/}

            {/*<NewFooterV3 />*/}
        </div>
    );
};

const HomeHelmet = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Stomačko</title>
            <meta property='title' content="Stomačko" />
            <meta property='description' content="Najbolji proizvod za pun stomak i novčanik" />
            <meta property="og:site_name" content = "Stomačko" />

            <meta property='og:title' content="Stomačko" />
            <meta property="og:type" content='website'/>
            <meta property='og:description' content="Najbolji proizvod za pun stomak i novčanik" />

            <meta property="og:image" content="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/assets/public/og-image.png" />
            <meta property="og:image:width" content="378" />
            <meta property="og:image:height" content="156" />
            <meta property='og:url' content={`https://stomacko.com/pocetna`} />

            <meta property='twitter:title' content="Stomačko" />
            <meta property='twitter:image' content="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/assets/public/og-image.png" />
            <meta property='twitter:url' content={`https://stomacko.com/pocetna`} />

            <meta name='keywords' content="stomacko kalkulator, recepti, frizider, jelo, sta jesti, usteda na jelu, hrana, rucak, dezert, slatkisi, hleb" />
        </Helmet>
    );
}

export default Home;
