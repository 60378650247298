import React, { useEffect } from 'react';
import Divider from '../General/DIvider';
import CollapsableYoutubeVideo from '../../general/CollapsableYoutubeVideo';

const FridgeHeader = () => {
    return (
        <div
            className="row justify-content-center"
            style={{ paddingTop: '35px' }}
        >
            <div className="col-md-11 col-lg-9 col-xl-7">
                <div className="section-title text-center " style={{marginBottom: '0px'}}>
                    <div id="basic-fridge-header" style={{opacity: 0}}>
                        {/*<span className="sec-subtitle h3">beta</span>*/}

                        <h2 className="sec-title1 text-theme" >
                            Stomačko frižider
                        </h2>
                    </div>

                    <>
                        <p className="sec-text text-md" id="basic-fridge-text" style={{opacity: 0}}>
                            Izaberite sastojke koje već imate u frižideru i vidite koja jela možete pripremiti pomoću njih.
                            <br />
                            Radi lakše upotrebe, neki od standardnih sastojaka i začina su unapred selektovani (so, biber, ulje, itd...), ali ih uvek možete odselektovati i pretražiti druge sastojke.
                        </p>
                        <Divider />
                    </>

                    <div style={{marginTop: '-20px', paddingBottom: '15px'}}>
                        <CollapsableYoutubeVideo
                            video={'https://www.youtube.com/embed/HAnht7iVMhM'}
                            mobileLink={'https://www.youtube.com/shorts/X6cblqOTMbU'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FridgeHeader;