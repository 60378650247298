import Api from 'main/Api';
import { SERVER_URLS } from 'util/routes/serverUrls';

const calculateApi = (params) => {
	return Api.post(SERVER_URLS.CALCULATE, params);
};

const saveApi = (params) => {
	return Api.post(SERVER_URLS.SAVE_RESULTS, params);
};

const getResultApi = () => {
	return Api.get(SERVER_URLS.GET_RESULTS);
};

export { calculateApi, saveApi, getResultApi };
