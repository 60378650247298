import { formatDate } from '../helpers/date';

export const initDaterange = (
    selector,
    ranges,
    onSelect,
    startDate,
    endDate,
    minDate,
    maxDate,
) => {
    const $ = window.$;

    $(selector).daterangepicker(
        {
            showDropdowns: true,
            ranges: ranges,
            locale: {
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Primeni',
                cancelLabel: 'Ponsti',
                fromLabel: 'Od',
                toLabel: 'Do',
                customRangeLabel: 'Sami izaberite datume',
                weekLabel: 'N',
                daysOfWeek: ['N', 'P', 'U', 'S', 'Č', 'P', 'S'],
                monthNames: [
                    'Januar',
                    'Februar',
                    'Mart',
                    'April',
                    'Maj',
                    'Jun',
                    'Jul',
                    'Avgust',
                    'Septembar',
                    'Octobar',
                    'Novembar',
                    'Decembar',
                ],
                firstDay: 1,
            },
            startDate: formatDate(startDate, '/'),
            endDate: formatDate(endDate, '/'),
            minDate: formatDate(minDate),
            maxDate: formatDate(maxDate),
        },
        function (start, end) {
            onSelect(start, end);
        }
    );
};
