import React from 'react';
import { CLOUDFRONT_URL } from '../../../util/constants/global';

const DividerImage = CLOUDFRONT_URL +  'assets/general/divider.png';

const Divider = ({style = {}}) => {
    console.log('style', style);
    return (
        <div className="sec-line" style={style}>
            <img
                src={DividerImage}
                alt="Section Shape Icon"
            />
        </div>
    );
};

export default Divider;