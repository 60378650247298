import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { homeApi } from './home.api';


const EMPTY_DATA = {
    inProgress: false,
    data: null,
    selectedNumber: 0,
};

const initialState = {
    dailyRecipe: EMPTY_DATA,
};

const getHomeDailyRecipeAction = createAsyncThunk('home/dailyRecipe', async () => {
    const res = await homeApi.getDailyRecipeRecommendation();
    return {
        data: res.data
    };
});

export const HomeReducer = createSlice({
    name: 'HOME',
    initialState,
    reducers: {},
    extraReducers: {
        // Ingredients
        [getHomeDailyRecipeAction.pending]: (state) => {
            state.dailyRecipe.inProgress = true;
        },
        [getHomeDailyRecipeAction.fulfilled]: (state, args) => {
            const {payload} = args;

            state.dailyRecipe.data = payload.data;
            state.dailyRecipe.inProgress = false;
            state.dailyRecipe.object = payload.data;
        },
        [getHomeDailyRecipeAction.rejected]: (state) => {
            state.dailyRecipe.inProgress = false;
        },
    },
});

// const {  } = HomeReducer.actions;

// Action creators are generated for each case reducer function
export const homeActions = {
    getHomeDailyRecipeAction,
};

export default HomeReducer.reducer;
