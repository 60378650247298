import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

import Api from 'main/Api';
import { setUser } from 'store/user/user';
import { validateEmail, validatePassword } from 'util/helpers/validators';

import { CALCULATOR_URLS, MAIN_URLS, REGISTER_URLS } from 'util/routes/urls';
import { SERVER_URLS } from 'util/routes/serverUrls';
import { CodeActivation } from "../Register/CodeActivation";
import RegisterLayout from '../../layouts/RegisterLayout';
import { CLOUDFRONT_URL } from '../../../util/constants/global';
import { getSavedRecipesAction } from '../../../store/callculator/result';
import { lsSaveIsLoggedIn } from '../../../util/helpers/localStorage';

export const EMPTY_STRING = '';

const onEyeClick = () => {
	const pass = window.$('#signUpUserPass');

	if (pass.attr('type') === 'text') {
		pass.attr('type', 'password');
		window.$('.fa').removeClass('fa-eye').addClass('fa-eye-slash');
	} else if (pass.attr('type') === 'password') {
		pass.attr('type', 'text');
		window.$('.fa').addClass('fa-eye').removeClass('fa-eye-slash');
	}
};

const Login = () => {
	const Login2 = CLOUDFRONT_URL + 'assets/register/login.jpeg';

	const hasCalculatorResults = useSelector((state) => state.calculatorResult.recipes);

	const [form, setForm] = useState({ email: '', password: '' });
	const [errorMessage, setErrorMessage] = useState(null);
	const [redirect, setRedirect] = useState(null);
	const [inProgress, setInProgress] = useState(false);
	const [emailInvalid, setEmailInvalid] = useState(false);
	const [passInvalid, setPassInvalid] = useState(false);
	const [showActivation, setShowActivation] = useState(false);

	const dispatch = useDispatch();

	const onFormChange = (e) => {
		if (errorMessage !== EMPTY_STRING) {
			setErrorMessage(EMPTY_STRING);
		}
		if (emailInvalid) {
			setEmailInvalid(false);
		}
		if (passInvalid) {
			setPassInvalid(false);
		}
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const submitLogin = (e) => {
		e.preventDefault();

		if (inProgress) {
			return;
		}

		if (!validateEmail(form.email)) {
			setEmailInvalid('Unestite ispravnu email adresu');
			if (!validatePassword(form.password)) {
				setPassInvalid('Unesite ispravnu lozinku');
			}
			return;
		}

		if (!validatePassword(form.password)) {
			setPassInvalid('Unesite ispravnu lozinku');
			return;
		}

		setInProgress(true);

		form.remember = true;

		Api.post(SERVER_URLS.LOGIN, form)
			.then((response) => {
				if (response?.data?.email) {
					dispatch(setUser({ email: response.data.email }));
					dispatch(getSavedRecipesAction());

					lsSaveIsLoggedIn();

					if (hasCalculatorResults?.length > 0) {
						setRedirect(CALCULATOR_URLS.CALCULATOR_RESULTS);
					} else {
						setRedirect(MAIN_URLS.HOME);
					}
					setInProgress(false);
				}

				if (response?.data?.code) {
					setShowActivation(true);
				}
			})
			.catch((e) => {
				setErrorMessage(e.response.data);
				setInProgress(false);
			});
	};

	if (redirect) {
		return <Redirect to={{
			pathname: redirect,
			state: { referrer: 'login' }
		}} />;
	}

	if (showActivation) {
		return (
			<CodeActivation email={form.email} />
		);
	}

	return (
		<RegisterLayout
			submitRegister={submitLogin}
			image={Login2}
		>
			<div className="sec-subtitle text-theme h3 color-green text-center">
				Login
			</div>
			<div className="form-group"
				 style={{
					 marginTop: '4px',
					 marginBottom: '4px'
				 }}
			>
				<label
					htmlFor="signUpUserEmail"
					className="sr-only"
				>
					Email adresa
				</label>
				<input
					style={{
						background: '#f1f1f1',
						borderTopRightRadius: '12px',
						borderTopLeftRadius: '12px',
						marginBottom: '4px'
					}}
					type="text"
					className="form-control"
					placeholder="Email adresa*"
					id="signUpUserEmail"
					name="email"
					onChange={onFormChange}
				/>
				{emailInvalid && (
					<div className="text-red">
						{emailInvalid}
					</div>
				)}
			</div>
			<div className="form-group"
				 style={{
					 marginTop: '4px',
					 marginBottom: '4px'
				 }}

			>
				<label
					htmlFor="signUpUserPass"
					className="sr-only"
				>
					Lozinka
				</label>
				<div className="input-group" >
					<input
						style={{
							background: '#f1f1f1',
						}}
						type="password"
						className="form-control"
						placeholder="Lozinka*"
						id="signUpUserPass"
						name="password"
						onChange={onFormChange}
					/>
					<div style={{ position: 'absolute', top: '18px', right: '10px', zIndex: 3 }}>
						<span className="color-green cursor-pointer" onClick={onEyeClick}>
							<i className="fa fa-eye-slash" aria-hidden="true" />
						</span>
					</div>
				</div>
				{passInvalid && (
					<div className="text-red">
						{passInvalid}
					</div>
				)}
			</div>

			{errorMessage && (
				<div className="text-red mb-3">
					{errorMessage}
				</div>
			)}
			<div className="form-group mb-0 text-center">
				<button
					className="vs-btn w-100 mb-0 mask-style1"
					type="submit"
					style={{
						borderTopRightRadius: '0',
						borderTopLeftRadius: '0',
						borderBottomRightRadius: '12px',
						borderBottomLeftRadius: '12px',
					}}
				>
					{!inProgress && <span>Ulogujte se</span>}
					{inProgress && (
						<Loader
							type={'TailSpin'}
							height={'20px'}
							color={'green'}
						/>
					)}
				</button>
				<div className="bottom-links link-inherit d-md-flex justify-content-center mt-3">
					<Link to={REGISTER_URLS.FORGOT_PASSWORD}>
						<span className="text-theme ">
							Zaboravili ste lozinku?
						</span>
					</Link>
				</div>

				<div className="bottom-links link-inherit d-md-flex justify-content-center mt-1">
					<Link to={REGISTER_URLS.REGISTER}>
						<span className="text-theme">
							Nemate nalog?
						</span>
					</Link>
				</div>

			</div>
		</RegisterLayout>
	);
};

export default Login;
