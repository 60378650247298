import React, { useEffect } from 'react';

import Divider from '../General/DIvider';
import CollapsableYoutubeVideo from '../../general/CollapsableYoutubeVideo';

const BasicFormHeader = ({ step = 1 }) => {

    return (
        <div
            id="basic-form-header-content"
            className="row justify-content-center"
            style={{ paddingTop: '35px' }}
        >
            <div className="col-md-11 col-lg-9 col-xl-7">
                <div className="section-title text-center " id="basic-form-header">
                    {/*<span className="sec-subtitle h3">beta</span>*/}

                    <h2 className="sec-title1 text-theme">
                        <span className="shape2 position-absolute">
                            <i
                                className="calculator-left-icon flaticon-chef icon-6x"
                                data-opacity="1"
                            />
                        </span>
                        <span >
                        Stomačko kalkulator
                            </span>
                        <span className="shape2 position-absolute">
                            <i
                                style={{position: 'relative', bottom: '20px'}}
                                className="flaticon-certification icon-6x"
                                data-opacity="1"
                            />
                        </span>
                    </h2>

                    {step === 1 && (
                        <>
                            <p className="sec-text text-md">
                                Unesite za koliko ljudi kuvate, za koji period i do koliko novca želite da potrošite i iskustite{' '}
                                <span className="text-theme">Stomačko</span>{' '}
                                magiju!
                            </p>
                            <Divider />
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <p className="sec-text text-md">
                                Kliknite na dane kada NE ŽELITE da kuvate. Dani tokom kojih je predviđeno da kuvate su označeni zelenom pozadinom.
                            </p>
                            <p className="sec-text text-md">
                                Kada završite, kliknite na dugme Nazad
                            </p>
                            <Divider />
                        </>
                    )}
                    {step === 3 && (
                        <>
                            <p className="sec-text text-md">
                                Izaberite dan i izmenite broj
                                osoba za koje kuvate taj dan.
                            </p>
                            <p className="sec-text text-md">
                                Kada završite, kliknite na dugme Nazad
                            </p>
                            <Divider />
                        </>
                    )}

                    <div style={{marginTop: '-20px'}}>
                        <CollapsableYoutubeVideo
                            video={'https://www.youtube.com/embed/R1_XlWhcbTg'}
                            mobileLink={'https://youtube.com/shorts/RIug3UwSTM8'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicFormHeader;
