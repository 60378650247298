import React, {useEffect, useState} from 'react';

import { getDateDMY } from 'util/helpers/date';

import { initDaterange } from 'util/jquery/daterange';

import InputWrapper from 'components/general/form/InputWrapper';
import TextInput from 'components/general/form/TextInput';
import PickerInput from 'components/general/form/PickerInput';

import {REGISTER_URLS} from 'util/routes/urls';
import {Redirect} from "react-router";

const BasicFormMain = ({
   form,
   actions,
   errorMessage,
}) => {
	// const user = useSelector(selectUser);
	// const userLoggedIn = user && user.email;

	const [redirect, setRedirect] = useState(null);

	const { today, nextWeek, nextTwoWeeks, next30Days } = form.dates;

	// const activateLoginModal = () => {
	// 	window.$('#login-modal').modal('toggle');
	// };

	const numberOfPeopleOptions = Array.from(Array(10).keys(), (x) => x + 1);

	useEffect(() => {
		const ranges = {
			Danas: [today, today],
			'Narednih 7 dana': [today, nextWeek],
			'Naredne 2 nedelje': [today, nextTwoWeeks],
			'Narednih mesec dana': [today, next30Days],
		};

		initDaterange(
			'#dateFrame',
			ranges,
			onDaterangeChange,
			form.dateRangeStart,
			form.dateRangeEnd,
			today,
			next30Days
		);

		const $ = window.$;
		$('.fa-calendar').addClass('cursor-pointer').on('click', () => {
			$('#dateFrame').click();
		});
	}, []);

	const onDaterangeChange = (start, end) => {
		actions.onDateRangeChange({
			start: getDateDMY(start._d),
			end: getDateDMY(end._d),
		});
	};

	const onRouteLink = (route) => () => {
		window.$('#login-modal').modal('toggle');
		setRedirect(route);
	};

	if (redirect) {
		return <Redirect to={redirect} />;
	}

	return (
		<div
			className="contact-form-style1"
			style={{ paddingBottom: '35px' }}
		>
			<div className="col-lg-12">
				<LoginModal onRouteLink={onRouteLink} />

				<div className="row">
					<div
						id="za-koliko-osoba-kuvate"
						style={{
							left: '800px',
							opacity: 0,
						}}
						className="col-lg-6 cursor-pointer"
					>
						<div className="col-lg-12 p-0">
							<label className="col-lg-12" htmlFor="numberOfPeople">
								Za koliko osoba kuvate?
							</label>
							<InputWrapper
								bootstrapClass="col-lg-12 justify-content-between d-flex "
							>
								<PickerInput
									choicesArray={numberOfPeopleOptions}
									active={form.numberOfPeople}
									onChange={actions.onNumberOfPeopleChange}
								/>
							</InputWrapper>
						</div>

						{/*<div className="col-lg-12">*/}
						{/*	{userLoggedIn && <Link to={CALCULATOR_URLS.CALCULATOR_DAYS_DETAILS}>*/}
						{/*		Želite da izmenite za koliko osoba kuvate određenog dana?*/}
						{/*		<span className="cursor-pointer hover-green float-right">*/}
						{/*			Izmeni <i className="fa fa-edit icon-2x" />*/}
						{/*		</span>*/}
						{/*	</Link>}*/}
						{/*	{!userLoggedIn &&*/}
						{/*		<div onClick={activateLoginModal} className="hover-green">*/}
						{/*			Želite da izmenite za koliko osoba kuvate određenog dana?*/}
						{/*			<span className="cursor-pointer hover-green float-right">*/}
						{/*				<i className="fa fa-lock icon-2x" />*/}
						{/*			</span>*/}
						{/*		</div>*/}
						{/*	}*/}
						{/*</div>*/}
					</div>

					<div
						className="col-lg-6 cursor-pointer"
						id="za-koji-period-kuvate"
						style={{
							left: '800px',
							opacity: 0,
						}}
					>
						<div className="col-lg-12 p-0">
							<label className="col-lg-12" htmlFor="date">
								Za koji period kuvate?
							</label>
							<InputWrapper bootstrapClass="col-lg-12 mb-3">
								<TextInput
									name="daterange"
									placeholder="Vremenski period"
									set={() => { }}
									icon="fa-calendar"
									id="dateFrame"
								/>
							</InputWrapper>
						</div>

						{/*<div className="col-lg-12 pt-lg-15 pt-md-15">*/}
						{/*	{userLoggedIn && <Link to={CALCULATOR_URLS.CALCULATOR_DAYS_OFF}>*/}
						{/*		Ne želite da kuvate svaki dan u izabranom periodu?*/}
						{/*		<span className="cursor-pointer float-right">*/}
						{/*			Izmeni <i className="fa fa-edit icon-2x" />*/}
						{/*		</span>*/}
						{/*	</Link>}*/}
						{/*	{!userLoggedIn &&*/}
						{/*	<div onClick={activateLoginModal} className="hover-green">*/}
						{/*		Ne želite da kuvate svaki dan u izabranom periodu?*/}
						{/*		<span className="cursor-pointer hover-green float-right">*/}
						{/*			<i className="fa fa-lock icon-2x" />*/}
						{/*		</span>*/}
						{/*	</div>*/}
						{/*	}*/}
						{/*</div>*/}
					</div>

				</div>

				<div className="row">
					<div
						className="col-lg-6 mt-2"
						id="do-koliko-novca-trosite"
						style={{
							left: '800px',
							opacity: 0,
						}}
					>
						<label className="col-lg-12" htmlFor="money">
							Do koliko novca želite da potrošite?
						</label>
						<InputWrapper
							bootstrapClass="col-lg-12"
							className="p-0"
						>
							<TextInput
								name="money"
								placeholder="Unesite novcanu vrednost"
								set={actions.onMoneyChange}
								icon="fa-money-bill-wave"
								type="number"
								value={form.money}
							/>
						</InputWrapper>
					</div>

					<div
						className="col-lg-6 mt-2"
						id="kategorije-jela"
						style={{
							left: '800px',
							opacity: 0,
						}}
					>
						<label className="col-lg-12" htmlFor="kategorije-jela">
							Kategorije jela?
						</label>
						<InputWrapper
							bootstrapClass="col-lg-12"
							className="p-0"
						>
							<CategoryInput
								value={form.category}
								set={actions.onCategoryChange}
							/>
						</InputWrapper>
					</div>
				</div>

				{errorMessage && (
					<div className="text-center text-red mt-20">
						{errorMessage}
					</div>
				)}

				<div
					className="col-12 form-group mb-0 text-center mt-40"
					id="stomacko-magija-dugme"
					style={{
						left: '800px',
						opacity: 0,
					}}
				>
					<button type="submit" className="vs-btn style3 radius-10 custom-primary-button" onClick={actions.calculateResults}>
						Stomačko magija
					</button>
				</div>
			</div>
		</div>
	);
};

const CategoryInput = ({value, set}) => {
	const onPick = (value) => {
		set(value)
	}

	return (
		<div style={{
			display: 'flex'
		}}>
			<CategoryInputElement
				color="#9ECC3E"
				name="Posno"
				value="posno"
				borderRadius='10px 0 0 10px'
				isSelected={value?.['posno']}
				onPick={onPick}
			/>

			<CategoryInputElement
				color="#8E6C55"
				name="Meso"
				value="meso"
				borderRadius='0'
				isSelected={value?.['meso']}
				onPick={onPick}
			/>

			<CategoryInputElement
				color="#5D76AC"
				name="Riba"
				value="riba"
				borderRadius='0 10px 10px 0'
				isSelected={value?.['riba']}
				onPick={onPick}
			/>

			{/*<CategoryInputElement*/}
			{/*	color="#73C25F"*/}
			{/*	name="Prolećno"*/}
			{/*	value="prolece"*/}
			{/*	borderRadius='0 10px 10px 0'*/}
			{/*	isSelected={value?.['leto']}*/}
			{/*	onPick={onPick}*/}
			{/*/>*/}

			{/*<CategoryInputElement*/}
			{/*	color="#F88379"*/}
			{/*	name="Letnje"*/}
			{/*	value="leto"*/}
			{/*	borderRadius='0 10px 10px 0'*/}
			{/*	isSelected={value?.['leto']}*/}
			{/*	onPick={onPick}*/}
			{/*/>*/}

			{/*<CategoryInputElement*/}
			{/*	color="#FF69B4"*/}
			{/*	name="Pink recepti"*/}
			{/*	value="pink"*/}
			{/*	borderRadius='0 10px 10px 0'*/}
			{/*	isSelected={value?.['pink']}*/}
			{/*	onPick={onPick}*/}
			{/*/>*/}
		</div>
	)
}

const CategoryInputElement = ({color, name, value, borderRadius, isSelected, onPick}) => {
	return (
		<div
			style={{
				borderRadius: borderRadius,
				width: '33.3%',
				backgroundColor: isSelected ? color : 'white',
				color: isSelected ? 'white' : color,
				// border: isSelected ? `3px solid ${color}` : '3px solid #fafafa',
				// fontWeight: isSelected ? 'bold': 'normal',
				// color: color,
				padding: '17px 5px',
				textAlign: 'center',
			}}
			onClick={() => onPick(value)}
			className={
				'cursor-pointer custom-hover-text-effect-1 '
			}
			tabIndex="0"
		>
			<span>{name}</span>
		</div>
	)
}

const LoginModal = ({onRouteLink}) => {
	return (
		<div className="modal fade" id="login-modal" tabIndex="-1" role="dialog"
			 aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Korisnik nije ulogovan</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<p>Ova opcija je dozvoljena samo ulogovanim korisnicima.</p>
						<p>Da biste nastavili, potrebno je da se ulogujete, ili napravite nalog ukoliko ga nemate.</p>
					</div>
					<div className="modal-footer" style={{ display: 'flex' }}>
						<button type="button" className="btn btn-primary" onClick={onRouteLink(REGISTER_URLS.REGISTER)}>
							Napravi nalog
						</button>

						<button type="button" className="btn btn-success" onClick={onRouteLink(REGISTER_URLS.LOGIN)}>
							Uloguj se
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BasicFormMain;
