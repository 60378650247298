import React, { Component } from 'react';
import { connect } from 'react-redux';

import Menu from 'components/pages/Menu/Menu';
import Header from 'components/pages/General/Header';
import ShowResults from 'components/pages/Calculator/Results/ShowResults';
import WaitingResults from 'components/pages/Calculator/Results/WaitingResults';
import ScrollToTop from 'components/pages/Menu/ScrollToTop';
import Footer from 'components/pages/Menu/Footer';

import { CALCULATOR_URLS } from 'util/routes/urls';
import {Redirect} from "react-router";
import { Helmet } from 'react-helmet';

class CalculatorResults extends Component {
    constructor() {
        super();

        this.actions = {
            setSelectedDate: this.setSelectedDate,
        };

        this.state = {
            inProgress: true,
            activeList: [],
            selectedIndex: 0,
            totalCost: 0,
        };
    }

    setResultState = () => {
        if (
            this.props.calculatorResult.recipes &&
            this.props.calculatorResult.recipes.length > 0
        ) {
            const activeList = new Array(this.props.calculatorResult.recipes.length);

            this.setState({
                inProgress: false,
                recipes: this.props.calculatorResult.recipes,
                ingredients: this.props.calculatorResult.ingredients,
                dateRange: this.props.calculatorResult.dateRange,
                activeList: activeList.fill(true, 0, 1).fill(false, 1),
                disabledList: this.props.calculatorResult.recipes.map(
                    (recipe) => !recipe.prep
                ),
                totalCost: this.props.calculatorResult.recipes.reduce((accumulator, recipe) => {
                    return (recipe.cost * recipe.numberOfPeople) + accumulator;
                }, 0)
            });
        } else {
            this.setState({
                redirect: CALCULATOR_URLS.CALCULATOR,
            });
        }
    };

    componentDidMount() {
        this.setResultState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.calculatorResult !== this.props.calculatorResult) {
            this.setResultState();
        }
    }

    setSelectedDate = (day) => () => {
        const newActiveList = [];
        let newSelectedIndex;

        this.state.recipes.forEach((recipe, index) => {
            const date = recipe.date.split('.');

            const isActive =
                parseInt(date[0]) === day[0] && parseInt(date[1]) === day[1];
            newActiveList.push(isActive);
            if (isActive) {
                newSelectedIndex = index;
            }
        });

        this.setState({
            activeList: newActiveList,
            selectedIndex: newSelectedIndex,
        });
    };

    render() {
        const {
            inProgress,
            recipes,
            ingredients,
            dateRange,
            activeList,
            selectedIndex,
            disabledList,
            totalCost,
            redirect,
        } = this.state;

        if (redirect) {
            return <Redirect to={redirect} />;
        }

        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Stomačko rezultati</title>
                    <meta property='og:title' content={`Stomačko rezultati`} />
                </Helmet>

                <Menu />
                <Header
                    current={'Rezultati'}
                    listOfPrevious={[
                        {
                            text: 'Kalkulator',
                            link: CALCULATOR_URLS.CALCULATOR,
                        },
                    ]}
                    nomb={!inProgress}
                />
                {inProgress && <WaitingResults />}
                {!inProgress && (
                    <ShowResults
                        actions={this.actions}
                        recipes={recipes}
                        ingredients={ingredients}
                        dateRange={dateRange}
                        activeList={activeList}
                        selectedIndex={selectedIndex}
                        disabledList={disabledList}
                        totalCost={totalCost}
                    />
                )}
                <ScrollToTop />
                <Footer />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    calculatorResult: state.calculatorResult,
});

export default connect(mapStateToProps)(CalculatorResults);
