import React, { forwardRef, useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import {RECIPE_URLS, replaceWildcards} from "../../../util/routes/urls";
import {useLocation, useParams} from "react-router";
import Divider from "../General/DIvider";
import {getRecipeListAction} from "../../../store/recipes/recipeList";
import {useDispatch, useSelector} from "react-redux";
import { recipeDiff } from '../Recipe/Recipe';
import instance from "../../../main/Api";
import {SERVER_URLS} from "../../../util/routes/serverUrls";
import {capitalizeFirstLetter} from "../../../util/helpers/string";
import PickerInputMultiple from '../../general/form/PickerInputMultiple';
import PickerInputDifficulty from '../../general/form/PickerInputDifficulty';
import PickerInputTags from '../../general/form/PickerInputTags';
import { CLOUDFRONT_URL } from '../../../util/constants/global';
import CollapsableYoutubeVideo from '../../general/CollapsableYoutubeVideo';
import { MEAL_MENU_SLUGS } from '../../../util/constants/meals';

const tagToNameMap = (tag) => {
    if (tag === 'premier-čokolada') {
        return 'Premier čokolada';
    }

    return tag;
}

const categoryToNameMap = {
    [MEAL_MENU_SLUGS.all]: 'Stomačko jela',
    [MEAL_MENU_SLUGS.mainMeals]: 'Glavna Jela',
    [MEAL_MENU_SLUGS.desserts]: 'Dezerti',
    [MEAL_MENU_SLUGS.guests]: 'Za goste',
    [MEAL_MENU_SLUGS.pastries]: 'Hleb i kiflice',
    [MEAL_MENU_SLUGS.spring]: 'Prolećna jela'
};

const hasPinkTagFn = (recipe) => {
    let hasTag = false;
    if (recipe?.tags) {
        hasTag = recipe?.tags.find((tag) => tag === 'pink')
    }
    return hasTag;
};

export const RecipeListMain = forwardRef(({ recipeList, inProgress }, ref) => {
	const location = useLocation();
	const locationState = { prevPath: location.pathname };
    const params = useParams();

    const filters = useSelector((state) => state.recipeList.filters);
    const hasChosenFilters = filters?.days?.length > 0
        || filters?.difficulty?.length > 0
        || filters?.tags?.length > 0
        || filters?.ingredients?.length > 0;

    const isMobile = window.innerWidth < 1200;

    useEffect(() => {
        if (recipeList.length > 0 && recipeList.length < 13 && !hasChosenFilters) {
            window.$('#recipe-list-main').velocity('fadeIn', {duration: 500});
        }
    }, [recipeList]);

    const hasFilter = params.categoryId === MEAL_MENU_SLUGS.mainMeals || params.categoryId === MEAL_MENU_SLUGS.all;

    return (
        <section className="vs-food-box-wrapper food-box-layout3 position-relative link-inherit py-20 py-lg-20" style={{minHeight: '900px'}}>

            <FilterModal filters={filters} />

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-11 col-lg-9 col-xl-7">
                        <div className="section-title text-center mb-10">
                            <span className="sec-subtitle text-theme h3">Stomačko</span>
                            {params.categoryId && <h2 className="sec-title1 mb-1">{categoryToNameMap[params.categoryId]}</h2>}
                            {params.tagName && <h2 className="sec-title1 mb-1">{tagToNameMap(params.tagName)}</h2>}

                            <Divider />

                            {(hasFilter) && (
                            <div style={{marginTop: '-15px', paddingBottom: '5px'}}>
                                <CollapsableYoutubeVideo
                                    video={'https://www.youtube.com/embed/bmJ8XeVnZ5Y'}
                                    mobileLink={'https://www.youtube.com/watch?v=HKMZDFrE5K8'}
                                />
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/*{inProgress &&*/}
            {/*<div id="recipes-waiting-results">*/}
            {/*    <WaitingResults />*/}
            {/*</div>*/}
            {/*}*/}

            <div className="container overflow-hidden" id="recipe-list-main" >
                <InfoSection params={params} />
                {recipeList.length === 0
                    && hasFilter
                    && hasChosenFilters
                    && !inProgress
                    && (
                        <div className="text-center">
                            <h3 className="text-center mb-4">Nema jela za zadati filter</h3>
                            <div className="mb-4">
                                <img
                                    style={{
                                        maxWidth: '400px'
                                    }}
                                    src={CLOUDFRONT_URL + "assets/public/no-filter.jpeg"}
                                    alt={'Nema jela za zadati filter'}
                                />
                            </div>
                            <h5
                                className="text-center color-green mb-4"
                                onClick={() => {
                                    window.$('#filter-modal').modal('toggle');
                                }}
                            >
                                Pokušajte druge filtere
                            </h5>
                        </div>
                    )}
                <div className="row">
                    {recipeList.map((recipe, index) => {
                        const link = replaceWildcards(RECIPE_URLS.RECIPE, [
                            recipe.slug,
                        ]);

                        const hasPinkTag = hasPinkTagFn(recipe);

						return (
							<div className="col-md-6 col-lg-4 mb-30 radius-10" key={recipe._id} ref={recipeList.length === index + 1 ? ref : null}>
								<div className="vs-food-box h-100 mb-0 radius-10">
									<div className="food-image image-scale-hover radius-10" style={{ aspectRatio: '3 / 2'}}>
										<Link
                                            to={{ pathname: link, state: locationState }}>
											<img
												src={
                                                    isMobile ? CLOUDFRONT_URL + 'small/' + recipe._id + ' Small.jpeg' : CLOUDFRONT_URL + 'medium/' + recipe._id + ' Medium.jpeg'
												}
												alt='Food'
												className="w-100"
											/>
                                            {hasPinkTag && (
                                                <div
                                                    className="recipe-ribbon"
                                                    data-toggle="tooltip"
                                                    title="Ovaj recept osmišljen je od strane Stomačka u saradnji sa Evropa dona Srbijom - srpskim forumom protiv raka dojke. Povod je peta trka i šetnja za izlečenje žena obolelih od raka dojke Race for the cure koja se organizuje u subotu, 16. septembra 2023, u Beogradu na Adi Ciganliji. Molimo vas da recepte shvatite kao preporuke za način pripreme i konzumiranje određenih namirnica kao prevenciju od bolesti, ali i tokom i nakon lečenja.
                                                        Neophodno je da se u vezi sa svojom ishranom posavetujete sa svojim lekarom ili nutricionistom."
                                                >
                                                    <i
                                                        className="fa fa-ribbon"
                                                    />
                                                </div>
                                            )}
										</Link>
									</div>
									<div className="food-content">
										<span className="food-price">{recipe.cost.toFixed(2)}</span>
										<h3 className="food-title h4 mb-0">
											<Link
                                                to={link}>
												{recipe.name}
											</Link>
										</h3>
										<p className="food-text mb-0 text-xs">{recipe.hint}</p>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
});


const DAYS_OPTIONS = [
    1, 2, 3, 4, 5
];

const FilterModal = ({filters}) => {
    const [tagsOptions, setTagsOptions] = React.useState([]);
    const [ingredientsOptions, setIngredientsOptions] = React.useState([]);

    const [days, setDays] = useState(new Set(filters.days));
    const [difficulty, setDifficulty] = useState(new Set(filters.difficulty));
    const [tags, setTags] = useState(new Set(filters.tags));
    const [ingredients, setIngredients] = useState(new Set(filters.ingredients));

    const dispatch = useDispatch();
    const params = useParams();

    React.useEffect(() => {
        if (filters?.tags?.length > 0) {
            window.$('.collapse-tags').click();
        }

        if (filters?.ingredients?.length > 0) {
            window.$('.collapse-ingredients').click();
        }
    }, [])

    React.useEffect(() => {
        if (window.tags) {
            setTagsOptions(window.tags.map((tag) => {
                return {
                    label: capitalizeFirstLetter(tag.name),
                    value: tag.name,
                };
            }).filter(Boolean));
            return;
        }
        setTimeout(() => {
            instance.get(SERVER_URLS.GET_TAG_LIST).then((response) => {
                if (response && response.data) {
                    // TODO Move to redux
                    window.tags = response.data;
                    setTagsOptions(response.data.map((tag) => {
                        return {
                            label: capitalizeFirstLetter(tag.name),
                            value: tag.name,
                        };
                    }).filter(Boolean));
                }
            });
        }, 1000);
    }, []);

    React.useEffect(() => {
        if (window.ingredients) {
            setIngredientsOptions(window.ingredients.map((ingredient) => {
                return {
                    label: capitalizeFirstLetter(ingredient.name),
                    value: ingredient.id,
                };
            }).filter(Boolean));
            return;
        }
        setTimeout(() => {
            instance.get(SERVER_URLS.GET_INGREDIENT_LIST).then((response) => {
                if (response && response.data) {
                    // TODO Move to redux
                    window.ingredients = response.data;
                    setIngredientsOptions(response.data.map((ingredient) => {
                        return {
                            label: capitalizeFirstLetter(ingredient.name),
                            value: ingredient.id,
                        };
                    }).filter(Boolean));
                }
            });
        }, 1000);
    }, []);

    const onClear = () => {
        setDays(new Set());
        setDifficulty(new Set());
        setTags(new Set());
        setIngredients(new Set());
    };

    const onApply = () => {
        dispatch(
            getRecipeListAction(
            {
                categoryId: params.categoryId,
                limit: 12,
                offset: 0,
                days: days.size > 0 ? Array.from(days) : undefined,
                difficulty: difficulty.size > 0 ? Array.from(difficulty) : undefined,
                tags: tags.size > 0 ? Array.from(tags) : undefined,
                ingredients: ingredients.size > 0 ? Array.from(ingredients) : undefined,
            }
        ));
        window.$('.filter-button-close').click();
    };

    return (
        <div
            className="modal fade" id="filter-modal" tabIndex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true"
        >
            <div className="modal-dialog  modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Filter jela</h4>
                        <button type="button" className="close filter-button-close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body"
                         style={{
                             maxHeight: '70vh',
                             minHeight: '35vh',
                             overflowY: 'auto',
                             textAlign: 'center',
                         }}
                    >
                        <span className="cursor collapsed pointer text-md text-theme text-semibold cursor-pointer card-header-filter" data-toggle="collapse" data-target="#daysCollapse"
                              aria-expanded="false" aria-controls="daysCollapse">
                            <h4 className="mt-1">
                                Dana jestivo
                                <span className="accicon"><i className="fas fa-angle-down rotate-icon"></i></span>
                            </h4>
                        </span>
                        <div className="collapse" id="daysCollapse">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <PickerInputMultiple
                                    choicesArray={DAYS_OPTIONS}
                                    active={days}
                                    onChange={(value) => {

                                        if (days.has(value)) {
                                            setDays((days) => {
                                                const newSet = new Set(days);
                                                newSet.delete(value);
                                                return newSet;
                                            });
                                        } else {
                                            setDays((days) => {
                                                const newSet = new Set(days);
                                                newSet.add(value);
                                                return newSet;
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <span className="cursor collapsed pointer text-md text-theme text-semibold cursor-pointer card-header-filter" data-toggle="collapse" data-target="#diffCollapse"
                              aria-expanded="false" aria-controls="collapseExample">
                            <h4 className="mt-1">
                                Težina spremanja
                                <span className="accicon"><i className="fas fa-angle-down rotate-icon"></i></span>
                            </h4>
                        </span>
                        <div className="collapse" id="diffCollapse">
                            <PickerInputDifficulty
                                choicesArray={recipeDiff}
                                active={difficulty}
                                onChange={(value) => {
                                    if (difficulty.has(value)) {
                                        setDifficulty((difficulty) => {
                                            const newSet = new Set(difficulty);
                                            newSet.delete(value);
                                            return newSet;
                                        });
                                    } else {
                                        setDifficulty((difficulty) => {
                                            const newSet = new Set(difficulty);
                                            newSet.add(value);
                                            return newSet;
                                        });
                                    }
                                }}
                            />
                        </div>

                        <span className="cursor collapsed pointer text-md text-theme text-semibold cursor-pointer card-header-filter" data-toggle="collapse" data-target="#tagsFilter"
                              aria-expanded="false" aria-controls="collapseExample">
                            <h4 className="mt-1 collapse-tags">
                                Tagovi
                                <span className="accicon"><i className="fas fa-angle-down rotate-icon"></i></span>
                            </h4>
                        </span>
                        <div className="collapse" id="tagsFilter">
                            <div
                                style={{
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                }}
                            >
                                <PickerInputTags
                                    choicesArray={tagsOptions}
                                    active={tags}
                                    onChange={(value) => {
                                        if (tags.has(value)) {
                                            setTags(() => {
                                                const newSet = new Set(tags);
                                                newSet.delete(value);
                                                return newSet;
                                            });
                                        } else {
                                            setTags(() => {
                                                const newSet = new Set(tags);
                                                newSet.add(value);
                                                return newSet;
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <span className="cursor collapsed pointer text-md text-theme text-semibold cursor-pointer card-header-filter" data-toggle="collapse" data-target="#ingredientFilter"
                              aria-expanded="false" aria-controls="collapseExample">
                            <h4 className="mt-1 collapse-ingredients">
                                Sastojci
                                <span className="accicon"><i className="fas fa-angle-down rotate-icon"></i></span>
                            </h4>
                        </span>
                        <div className="collapse" id="ingredientFilter">
                            <div
                                style={{
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                }}
                            >
                                <PickerInputTags
                                    choicesArray={ingredientsOptions}
                                    active={ingredients}
                                    onChange={(value) => {
                                        if (ingredients.has(value)) {
                                            setIngredients(() => {
                                                const newSet = new Set(ingredients);
                                                newSet.delete(value);
                                                return newSet;
                                            });
                                        } else {
                                            setIngredients(() => {
                                                const newSet = new Set(ingredients);
                                                newSet.add(value);
                                                return newSet;
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer mt-3" style={{
                        display: 'flex',
                        justifyContent: 'space-between',

                    }}>
                        <button type="button" className="btn btn-primary" onClick={onClear}>
                            Očisti filter
                        </button>

                        <button type="button" className="btn btn-success" onClick={onApply}>
                            Primeni filter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const InfoSection = ({params}) => {
    const hasFilter = useSelector((state) => state.recipeList.hasFilterActive);
    const hasFilterCategory = params.categoryId === MEAL_MENU_SLUGS.mainMeals || params.categoryId === MEAL_MENU_SLUGS.all;

    const onFilterClick = () => {
        window.$('#filter-modal').modal('toggle');
    };

    return (
        <div className="container overflow-hidden mb-3">
            {hasFilterCategory && <div className={"mb-2 cursor-pointer float-lg-right float-md-right text-center text-md text-semibold" + (hasFilter ? " text-theme" : " hover-green")} onClick={onFilterClick}>
                {!hasFilter && <>Filter <i className="fa fa-filter" /></>}
                {hasFilter && <>Promeni Filter <i className="fa fa-filter" /></>}
            </div>}
            <span className="cursor pointer text-md text-theme text-semibold cursor-pointer" data-toggle="collapse" data-target="#helpUs"
                  aria-expanded="false" aria-controls="collapseExample">
                    Želite još ukusnih i pristupačnih ideja za klopu?
                </span>
            <div className="collapse" id="helpUs">
                <blockquote className="vs-block-quote-style1 my-15 my-lg-15 text-md text-semibold">
                    Pomozite nam!
                    Pošaljite vaše recepte na <u><a href="mailto:sara@stomacko.com">sara@stomacko.com</a></u>,
                    a ukoliko želite možemo vas navesti kao autora i postaviti link ka vašem sajtu ili profilu na društvenim mrežama!
                </blockquote>
            </div>

            <p className="food-text mb-0 text-xs text-bold">
                {params.categoryId === '1' ? (
                    "*Cene su okvirne i izražene u dinarima. Prikazuju iznos novca za pripremu jedne porcije."
                ) : ("*Cene su okvirne i izražene u dinarima. Prikazuju iznos novca za pripremu broja porcija koji su navedeni u okviru recepata.")
                }
            </p>
        </div>
    );
};
