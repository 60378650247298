import { createSelector } from "@reduxjs/toolkit";

// Ingredient section
const selectHomeDailyRecipe = createSelector(
    (state) => state.home,
    (home) => home.dailyRecipe.data
);

export const homeSelector = {
    selectHomeDailyRecipe,
};
