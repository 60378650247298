import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import { calculateApi, getResultApi, saveApi } from './api';
import { createDateOutofDMY } from '../../util/helpers/date';
import { roundDecimals } from '../../util/helpers/number';
import { baseUnitToUnitRatio } from '../../util/helpers/units';
import {pluralForUnits} from "../../components/pages/Calculator/Results/IngredientList";

// import {
//     cookingIngredientList,
//     dateRangeRecipes,
//     dateRange,
// } from '../recipeMock';

const initialState = {
	inProgress: false,
	recipes: [],
	ingredients: [],
	dateRange: { start: null, end: null },
	completed: false,
	lastParams: null,
	resultInProgress: false,
	result: null,
	errorMessage: '',
};

const calculateRecipes = createAsyncThunk(
	'recipe/calculate',
	async (params) => {
		const sendParams = {...params};
		if (process.env.REACT_APP_ENV === 'local') {
			sendParams.ignoreNotPublished = true;
		}
		const res = await calculateApi(sendParams);
		return res.data;
	}
);

const saveRecipesAction = createAsyncThunk(
	'recipe/save',
	async (params) => {
		const res = await saveApi(params);
		return res.data;
	}
);

const getSavedRecipesAction = createAsyncThunk(
	'result/get',
	async () => {
		const res = await getResultApi();
		return res.data;
	}
);

export const calculatorResult = createSlice({
	name: 'CALCULATOR:RESULT',
	initialState,
	reducers: {
		saveResults: (state, action) => {
			state.results = action.payload;
		},
		resetResults: (state) => {
			state.recipes = initialState.recipes;
			state.ingredients = initialState.ingredients;
			state.dateRange = initialState.dateRange;
			state.completed = initialState.completed;
		},
		resetCompleted: (state) => {
			state.completed = initialState.completed;
		}
	},
	extraReducers: {
		[calculateRecipes.pending]: (state, action) => {
			state.inProgress = true;
			state.completed = false;
			state.lastParams = action.meta.arg;
		},
		[calculateRecipes.fulfilled]: (state, action) => {
			// Handle server failed to calculate
			if (action.payload && action.payload.success === false) {
				state.inProgress = false;
				state.errorMessage = action.payload.reason === 'Budget too low!' ?
					'Uneli ste premali budžet za uneti broj ljudi i dana! Molimo vas promenite parametre i pokušajte ponovo.'
					: 'Došlo je do greške, molimo vas pokušajte ponovo';
				return;
			}

			const ingredientsObj = {};

			// const ingredients = action.payload.reduce((acc, recipe) => {
			//     recipe.ingredients.forEach((ingredient) => {
			//         acc.push(ingredient);
			//     });
			//     return acc;
			// }, []);

			action.payload.forEach((recipe) => {
				if (!recipe.ingredients) {
					return;
				}
				recipe.ingredients.forEach((ingredient) => {
					if (!ingredientsObj[ingredient.name]) {
						ingredientsObj[ingredient.name] = {...ingredient};
						ingredientsObj[ingredient.name].amount = 0;
						ingredientsObj[ingredient.name].displayUnit = ingredient.baseUnit;
					}

					const unitRatio = baseUnitToUnitRatio(
						ingredient.amountUnit,
						ingredient.unitForPrice ?? ingredient.unit,
						ingredient.baseUnit
					);

					// Calculate in base amount
					ingredientsObj[ingredient.name].amount +=
						parseInt(ingredient.amount) * parseInt(recipe.numberOfPeople) * unitRatio;
				});
			});

			let ingredients = Object.entries(ingredientsObj).map((value) => {
				return value[1];
			});

			ingredients = ingredients.map((ingredient) => {
				let totalUsage, amountToBuy, wastage;
				let packages, totalPrice, amountNeeded, unitNeeded;

				// const unitRatio = baseUnitToUnitRatio(
				// 	ingredient.amountUnit,
				// 	ingredient.unit,
				// 	ingredient.baseUnit
				// );

				// If unit is single units (ie. Eggs)
				if (ingredient.baseUnit === 'units') {
					totalUsage = ingredient.amount / ingredient.minBuyUnit;
					amountToBuy = roundDecimals(ingredient.amount);

					packages = Math.ceil(amountToBuy / ingredient.minBuyUnit);
					totalPrice = packages * ingredient.costPerUnit;

					amountNeeded = ingredient.amount;
					unitNeeded = ' ' + pluralForUnits(ingredient.name, amountNeeded);
				}

				if (ingredient.baseUnit === 'l') {
					totalUsage = ingredient.amount / ingredient.minBuyUnit;

					amountToBuy = roundDecimals(
						Math.ceil(totalUsage)
					);

					wastage = (totalUsage - amountToBuy) * ingredient.minBuyUnit;
					packages = amountToBuy;

					totalPrice = packages * ingredient.costPerUnit;

					if (ingredient.unit === 'l') {
						if (ingredient.amount < 1) {
							amountNeeded = ingredient.amount * 1000;
							unitNeeded = 'ml';
						} else {
							amountNeeded = ingredient.amount;
							unitNeeded = 'l';
						}
					}
					if (ingredient.unit === 'dl') {
						if (ingredient.amount > 10) {
							amountNeeded = ingredient.amount / 10;
							unitNeeded = 'l';
						} else if (ingredient.amount < 1 || amountToBuy % 1 !== 0) {
							amountNeeded = ingredient.amount * 100;
							unitNeeded = 'ml';
						} else {
							amountNeeded = ingredient.amount;
							unitNeeded = 'dl';
						}
					}

					if (ingredient.unit === 'ml') {
						if (ingredient.amount > 1000) {
							amountNeeded = ingredient.amount / 1000;
							unitNeeded = 'l';
						} else if (ingredient.amount > 100 && ingredient.amount % 100 === 0) {
							amountNeeded = ingredient.amount / 1000;
							unitNeeded = 'dl';
						} else {
							amountNeeded = ingredient.amount;
							unitNeeded = 'ml';
						}
					}
				}

				// If units is kg based on measure
				if (ingredient.baseUnit === 'kg-measure') {
					amountToBuy = roundDecimals(ingredient.amount);

					packages = amountToBuy;

					totalPrice = packages * ingredient.costPerUnit;

					if (ingredient.unit === 'g') {
						if (ingredient.amount > 1000) {
							amountNeeded = ingredient.amount / 1000;
							unitNeeded = 'kg';
						} else {
							amountNeeded = ingredient.amount;
							unitNeeded = 'g';
						}
					}
					if (ingredient.unit === 'kg') {
						if (ingredient.amount > 1) {
							amountNeeded = ingredient.amount;
							unitNeeded = 'kg';
						} else {
							amountNeeded = ingredient.amount * 1000;
							unitNeeded = 'g';
						}
					}
				}

				// If unit is kg based in packages
				if (ingredient.baseUnit === 'kg-package') {
					totalUsage = ingredient.amount / ingredient.minBuyUnit;

					amountToBuy = roundDecimals(totalUsage);

					wastage = (totalUsage - amountToBuy) * ingredient.minBuyUnit;
					packages = Math.ceil(amountToBuy);

					totalPrice = packages * ingredient.costPerUnit;

					if (ingredient.unit === 'g') {
						if (ingredient.amount >= 1000) {
							amountNeeded = ingredient.amount / 1000;
							unitNeeded = 'kg';
						} else {
							amountNeeded = ingredient.amount;
							unitNeeded = 'g';
						}
					}
					if (ingredient.unit === 'kg') {
						if (ingredient.amount > 1) {
							amountNeeded = ingredient.amount;
							unitNeeded = 'kg';
						} else {
							amountNeeded = ingredient.amount * 1000;
							unitNeeded = 'g';
						}
					}
				}

				amountNeeded = roundDecimals(amountNeeded);

				return { ...ingredient, wastage, amountToBuy, totalPrice, amountNeeded, unitNeeded, packages };
			});

			state.recipes = action.payload;
			state.ingredients = ingredients;
			state.dateRange = {
				start: createDateOutofDMY(action.payload[0].date),
				end: createDateOutofDMY(
					action.payload[action.payload.length - 1].date
				),
			};
			state.inProgress = false;
			state.completed = true;
		},
		[calculateRecipes.rejected]: (state) => {
			state.inProgress = false;
		},

		[saveRecipesAction.pending]: (state) => {
			state.resultInProgress = true;
			state.hasResults = false;
		},
		[saveRecipesAction.fulfilled]: (state, action) => {
			if (action?.payload?.hasResults) {
				state.hasResults = true;
			} else {
				state.savedResults = {
					recipes: state.recipes,
					ingredients: state.ingredients,
					isActive: true,
				};
				state.gotResults = true;
			}
			state.resultInProgress = false;
		},
		[saveRecipesAction.rejected]: (state) => {
			state.resultInProgress = false;
		},

		[getSavedRecipesAction.fulfilled]: (state, action) => {
			state.savedResults = action.payload;
		},

		[getSavedRecipesAction.rejected]: (state) => {
			state.savedResults = {code: 'NO_LOGIN'};
		},
	},
});

export const createLastParamSelector = createSelector(
	(state) => state.calculatorResult,
	(state) => state.lastParams
);

const selectSavedCalculatorResults = createSelector(
	(state) => state.calculatorResult,
	(calculatorResult) => calculatorResult.savedResults
);

export const resultSelector = {
	selectSavedCalculatorResults
};

// Action creators are generated for each case reducer function
export const { saveResults, resetResults, resetCompleted } = calculatorResult.actions;
export { calculateRecipes, saveRecipesAction, getSavedRecipesAction };

export default calculatorResult.reducer;
