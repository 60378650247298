export const MEALS = [
    'Doručak',
    'Ručak',
    'Dezert',
    'Večera',
];

export const MEAL_ICONS = [
    'flaticon-bakery',
    'flaticon-restaurant',
    'flaticon-fruit-1',
    'flaticon-serving-dish',
];

export const MEAL_MENU_SLUGS = {
    mainMeals: 'glavna-jela',
    desserts: 'dezerti',
    pastries: 'hleb-i-kiflice',
    guests: 'za-goste',
    spring: 'prolecni-jelovnik',
    summer: 'letnji-jelovnik',
    christmas: 'praznici',
    valentine: 'romanticni',
    all: 'sve',
};
export const MEAL_MENU = [
    MEAL_MENU_SLUGS.mainMeals,
    MEAL_MENU_SLUGS.desserts,
    MEAL_MENU_SLUGS.pastries,
    MEAL_MENU_SLUGS.guests,
    MEAL_MENU_SLUGS.spring,
    MEAL_MENU_SLUGS.summer,
    MEAL_MENU_SLUGS.christmas,
    MEAL_MENU_SLUGS.valentine
];

export const MEAL_MENU_SLUG_TO_CATEGORY = {
    [MEAL_MENU_SLUGS.mainMeals]: 1,
    [MEAL_MENU_SLUGS.desserts]: 2,
    [MEAL_MENU_SLUGS.pastries]: 4,
    [MEAL_MENU_SLUGS.guests]: 3,
    [MEAL_MENU_SLUGS.spring]: 6,
    [MEAL_MENU_SLUGS.summer]: 7,
    [MEAL_MENU_SLUGS.christmas]: 8,
    [MEAL_MENU_SLUGS.valentine]: 9,
    [MEAL_MENU_SLUGS.all]: -1,
}

export const MEAL_MENU_SLUG_TO_TITLE = {
    [MEAL_MENU_SLUGS.mainMeals]: 'Glavna jela',
    [MEAL_MENU_SLUGS.desserts]: 'Dezerti',
    [MEAL_MENU_SLUGS.pastries]: 'Hleb i kiflice',
    [MEAL_MENU_SLUGS.guests]: 'Za goste',
    [MEAL_MENU_SLUGS.spring]: 'Prolećni jelovnik',
    [MEAL_MENU_SLUGS.summer]: 'Letnji jelovnik',
    [MEAL_MENU_SLUGS.christmas]: 'Praznični jelovnik',
    [MEAL_MENU_SLUGS.valentine]: 'Romantični jelovnik',
    [MEAL_MENU_SLUGS.all]: 'Sva jela',
}