import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { weeklyApi } from './api';

const initialState = {
	day: null,
	week: null,
	hasToday: null,
	inProgress: false,
};

const getWeeklyDayAction = createAsyncThunk('week/get-day', async () => {
	const res = await weeklyApi.getToday();
	return { data: res.data };
});

const getWeeklyWeekAction = createAsyncThunk('week/get-week', async () => {
	const res = await weeklyApi.getWeekly();
	return { data: res.data };
});

export const weekListReducer = createSlice({
	name: 'week_LIST',
	initialState,
	reducers: {
	},
	extraReducers: {
		[getWeeklyDayAction.pending]: (state) => {
			state.inProgress = true;
		},
		[getWeeklyDayAction.fulfilled]: (state, args) => {
			const {payload} = args;

			if (payload.data) {
				state.day = payload.data;
				state.hasToday = true;
			} else {
				state.hasToday = false;
			}

			state.inProgress = false;
		},
		[getWeeklyDayAction.rejected]: (state) => {
			state.inProgress = false;
		},

		[getWeeklyWeekAction.pending]: (state) => {
			state.inProgress = true;
		},
		[getWeeklyWeekAction.fulfilled]: (state, args) => {
			const {payload} = args;

			if (payload.data) {
				state.week = payload.data;
				state.hasToday = true;
			} else {
				state.hasToday = false;
			}

			state.inProgress = false;
		},
		[getWeeklyWeekAction.rejected]: (state) => {
			state.inProgress = false;
		},
	},
});

// Action creators are generated for each case reducer function
export { getWeeklyDayAction, getWeeklyWeekAction };

export default weekListReducer.reducer;
