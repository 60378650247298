export const replaceWildcards = (url, valueArray) => {
	return url.replace(/\/:[a-zA-Z]+\??/g, () => {
		const shiftedValue = valueArray.shift();
		return shiftedValue ? '/' + shiftedValue : '';
	});
};

// TODO Kill en
export const MAIN_URLS = {
	// TODO Remove translation attempt?
	en: {
		HOME: '/home',
		CALCULATOR: '/calculator',
		CALCULATOR2: '/calculator/details',
		RECIPE: '/recipe',
	},
	sr: {
		HOME: '/pocetna',
		CALCULATOR: '/kalkulator',
		RECIPE: '/recept'
	},

	HOME: '/pocetna',
	HOME2: '/pocetna2',
	CALCULATOR: '/kalkulator',
	RECIPE: '/recept',
	RECIPE_LIST: '/recepti/:categoryId?',
	RECIPE_LIST_TAG: '/recepti/tag/:tagName',
	FRIDGE: '/frizider',
};

export const CALCULATOR_URLS = {
	CALCULATOR: '/kalkulator',
	CALCULATOR_DAYS_OFF: '/kalkulator/dani-za-kuvanje',
	CALCULATOR_DAYS_DETAILS: '/kalkulator/detalji-po-danima',
	CALCULATOR_RESULTS: '/kalkulator/rezultati',
	CALCULATOR_SAVED: '/sacuvani-rezultati',
};

export const RECIPE_URLS = {
	RECIPE: '/recept/:id/:number?',
};

export const REGISTER_URLS = {
	REGISTER: '/registracija',
	LOGIN: '/logovanje',
	LOGOUT: '/logout',
	FORGOT_PASSWORD: '/nova-lozinka',
	CHANGE_PASSWORD: '/promena-lozinke',
	USER_PROFILE: '/user',
	FORGOT_PASSWORD_CODE: '/nova-lozinka-potvrda/:code',
	CONTACT_FORM: '/kontakt',
	NEWSLETTER: '/newsletter',
};

export const BLOG_URLS = {
	BLOG_LIST: '/blog',
	BLOG: '/blog/:slug'
}

export const WEEKLY_URLS = {
	RECIPE: '/nedeljni-recepti',
};

export const DISCLAIMER_URLS = {
	COOKIES: '/kolacici',
	TERMS_AND_CONDTIONS: '/uslovi-koriscenja',
	PRIVACY_POLICY: '/politika-privatnosti',
};

export const GLOBAL_URLS = {
	RACE_FOR_THE_CURE: '/pink-recepti'
}

export const FALLBACK_URLS = {
	en: {
		'404': '/404',
	},
	sr: {
		'404': '/404'
	}
};
