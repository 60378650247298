export const monthNamesSr = [
	'Januar',
	'Februar',
	'Mart',
	'April',
	'Maj',
	'Jun',
	'Jul',
	'Avgust',
	'Septembar',
	'Oktobar',
	'Novembar',
	'Decembar',
];

export const dayOfWeekShort = ['Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub', 'Ned'];
export const dayOfWeekLong = ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'];

export const createDateOutofDMY = (date) => {
	if (typeof date === 'string') {
		date = date.split('/');
	}
	return new Date([date[1], date[0], date[2]].join('/'));
};

// Get array of dates between two dates
export const getDaysArray = (start, end) => {
	const arr = [];
	for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
		arr.push(new Date(dt));
	}
	return arr;
};

export const getDaysArrayDMY = (start, end) => {
	const arr = [];
	for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
		arr.push(getDateDMY(dt));
	}
	return arr;
};

// Get array with months containing array of dates
export const getDaysArrayByMonth = (dateRangeArray) => {
	const months = [];
	let arr = [];

	dateRangeArray.forEach((date) => {
		const month = date.getMonth();
		if (arr.length > 0 && arr[0].getMonth() === month) {
			arr.push(date);
		} else {
			if (arr.length > 0) {
				months.push([...arr]);
			}
			arr = [];
			arr.push(date);
		}
	});

	months.push(arr);

	return months;
};

export const formatFullLocaleDate = (date, locale = 'sr-latn') => {
	return createDateOutofDMY(date).toLocaleDateString(locale, { dateStyle: 'full' });
};

export const formatDate = (date, seperator = '.') => {
	if (typeof date === 'object' && date.length > 2) {
		return date.join(seperator);
	}
	const [day, month, year] = getDateDMY(date);

	return [day, month, year].join(seperator);
};

export const getDateDMY = (date) => {
	const d = new Date(date);
	let month = (d.getMonth() + 1);
	let day = d.getDate();
	const year = d.getFullYear();

	if (month.length < 2) {
		month = '0' + month;
	}
	if (day.length < 2) {
		day = '0' + day;
	}

	return [day, month, year];
};
