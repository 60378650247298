import React from 'react';
import TopRightSvg from './header/top-right-bckg.svg';
import HelixSvg from './header/helix.svg';

const HeaderV3 = () => {
    return (
        <div>
            <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
            }}>
                <img src={TopRightSvg} alt={'top right background svg'} />
            </div>

            <div style={{
                width: '15%',
                height: '800px',
                position: 'absolute',
                top: 0,
                right: '-20px',
            }}>
                <img src={HelixSvg} alt={'green helix svg'} />
            </div>
            
        </div>
    );
};

export default HeaderV3;