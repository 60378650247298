import React from 'react';
import { Helmet } from 'react-helmet';
import Menu from '../components/pages/Menu/Menu';
import ScrollToTop from '../components/pages/Menu/ScrollToTop';
import Footer from '../components/pages/Menu/Footer';
import Rftc from '../components/pages/RFTC/RFTC';
import { CLOUDFRONT_URL } from '../util/constants/global';
import Api from '../main/Api';
import { SERVER_URLS } from '../util/routes/serverUrls';

const RaceForTheCurePage = () => {
    const isMobile = window.innerWidth < 1200;
    const [active, setActive] = React.useState(1);

    React.useEffect(() => {
        Api.get(SERVER_URLS.PINK_LOG_DATA);
    }, []);

    return (
        <div >
            <RaceForTheCureHelmet />

            <Menu />
            <HeaderDe
                current=""
                active={active}
            />
            <Rftc
                active={active}
                setActive={setActive}
            />
            <ScrollToTop />
            <Footer footerImage={isMobile ? `${CLOUDFRONT_URL}assets/pink/footer%20Medium.jpeg` : `${CLOUDFRONT_URL}assets/pink/footer%20Large.jpeg`} />
        </div>
    );
};

const image1 = `${CLOUDFRONT_URL}assets/pink/stomacko-pink%20Medium.jpeg`;
const image2 = `${CLOUDFRONT_URL}assets/pink/ribbon%20Medium.jpeg`;
const image3 = `${CLOUDFRONT_URL}assets/pink/race%20Medium.jpeg`;

const imageObject = {
    1: image1,
    2: image2,
    3: image3,
};

const imageLarge1 = `${CLOUDFRONT_URL}assets/pink/stomacko-pink%20Large.jpeg`;
const imageLarge2 = `${CLOUDFRONT_URL}assets/pink/ribbon%20Large.jpeg`;
const imageLarge3 = `${CLOUDFRONT_URL}assets/pink/race%20Large.jpeg`;

const imageObjectLarge = {
    1: imageLarge1,
    2: imageLarge2,
    3: imageLarge3,
};

const HeaderDe = ({ current, listOfPrevious, nomb = false, ImageBackground = null, active }) => {
    const isMobile = window.innerWidth < 991;

    const topDivClass = nomb ? '' : ' mb-30';
    return (
        <div
            className={"breadcumb-wrapper breadcumb-layout1 background-image link-inherit " + topDivClass}
            data-overlay="black"
            data-opacity="1"
            style={{
                height: !isMobile ? '500px' : '200px',
                backgroundImage: `url('${isMobile ? imageObject[active] : imageObjectLarge[active]}')`,
                transition: 'background 1s'
            }}
        >
            <div style={{
                width: '100%',
                height: '100%',
                background: 'linear-gradient(135deg, rgba(0,148,50,0.6) 28%, rgba(255,192,203,0.4) 53%)',
            }}></div>
            <div className="container z-index-common">
                <div className="breadcumb-content text-center pt-65 pt-lg-105 pb-95 pb-lg-100">
                    <h1 className="breadcumb-title sec-title1 text-white my-0">
                        &nbsp;
                    </h1>
                </div>
            </div>
        </div>
    );
};

const RaceForTheCureHelmet = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta property="og:site_name" content = "Stomačko" />
            <meta property="og:type" content='website'/>

            <title>Stomačko - Nedeljni recepti</title>

            <meta property='title' content="Stomačko - Nedeljni recepti" />
            <meta property='twitter:title' content="Stomačko - Nedeljni recepti" />
            <meta property='og:title' content="Stomačko - Nedeljni recepti" />

            <meta property='description' content={`Stomačko nedeljni recepti`} />
            <meta property='og:description' content={`Stomačko nedeljni recepti`} />

            <meta property="og:image" content="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/assets/public/og-image.png" />
            <meta property='twitter:image' content="https://stomacko-cloud.s3.eu-central-1.amazonaws.com/assets/public/og-image.png" />

            <meta property="og:image:width" content="378" />
            <meta property="og:image:height" content="156" />

            <meta property='og:url' content={`https://stomacko.com/nedeljni-recepti`} />
            <meta property='twitter:url' content={`https://stomacko.com/nedeljni-recepti`} />

            <meta name='keywords' content="Nedeljni recepti, nedeljne preporuke" />
        </Helmet>
    );

}


export default RaceForTheCurePage;